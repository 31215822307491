import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Alert, Button, Loader, Modal, Uploader } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import { apiPostWithAuth } from '../../misc/config';
import { useModalState } from '../../misc/custom-hooks';

const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/pjpeg'];

const isValidFile = file => acceptedFileTypes.includes(file.type);

const getBlob = canvas => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('File Process Error'));
      }
    });
  });
};

const EditPhoto = () => {
  const { open, isOpen, close } = useModalState();
  const [isLoading, setIsLoading] = useState(false);
  const { profile, updateProfile } = useProfile(null);
  const [img, setImg] = useState(null);
  const avatarEditorRef = useRef();

  const onFileInputChange = e => {
    const file = e.at(-1).blobFile;

    if (isValidFile(file)) {
      setImg(file);
      open();
    } else {
      Alert.warning(`Wrong file type ${file.type}`, 4000);
    }
  };

  const onUploadClick = async () => {
    const canvas = avatarEditorRef.current.getImageScaledToCanvas();
    setIsLoading(true);

    try {
      const blob = await getBlob(canvas);

      const formData = new FormData();

      formData.append('profile_picture_name', blob, `${Date.now()}.jpg`);

      const updated = await apiPostWithAuth('users/update-me', formData);
      updateProfile({ ...updated.data.data });

      setIsLoading(false);
      Alert.info('Avatar has been uploaded', 4000);
      close();
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
    }
  };

  return (
    <>
      <Uploader
        className="pp"
        fileListVisible={false}
        listType="picture"
        action=""
        accept=".png, .jpg, .jpeg"
        onChange={onFileInputChange}
      >
        <button
          type="button"
          style={{
            width: 130,
            height: 130,
            border: 'none',
            borderRadius: '50%',
          }}
        >
          {isLoading && <Loader backdrop center />}
          {!isLoading && (
            <img
              src={profile.job_seeker.profile_picture_url}
              className="img-wrapper"
              style={{ borderRadius: '50%' }}
              width="100%"
              height="100%"
              alt=""
            />
          )}
        </button>
      </Uploader>
      <Modal show={isOpen} onHide={close} size="xs">
        <Modal.Header>
          <Modal.Title>Adjust and upload new Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center h-100">
            {img && (
              <AvatarEditor
                ref={avatarEditorRef}
                image={img}
                width={200}
                height={200}
                border={5}
                borderRadius={100}
                rotate={0}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            appearance="ghost"
            onClick={onUploadClick}
            disabled={isLoading}
          >
            Upload new photo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPhoto;
