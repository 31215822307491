import React from 'react';
import { Icon } from 'rsuite';
import './btnedit.style.scss';

const ButtonEdit = ({ icon, own, text, ...props }) => {
  return (
    <button className="btn-edit" type="button" {...props}>
      {icon && <Icon icon={icon} />}
      {own && <img src={own} alt="" />}
      <span>{text}</span>
    </button>
  );
};

export default ButtonEdit;
