/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Alert, Carousel, Col, Grid, Row } from 'rsuite';
import CardEvent from '../../components/card/CardEvent';
import './event.style.scss';

import book from '../../assets/icon/book.svg';
import creditCard from '../../assets/icon/credit-card.svg';
import { apiGet, apiGetWithURL } from '../../misc/config';
import SkeletonEvent from '../../components/loader-skeleton/SkeletonEvent';
import SkeletonDataEvent from '../../components/loader-skeleton/SkeletonDataEvent';

const EventPage = () => {
  const [banner, setBanner] = useState([]);
  const [kategori, setKategori] = useState([]);
  const [data, setData] = useState([]);
  const [next, setNext] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    document.title = 'Event | puskarir';
    setIsLoading(true);
    apiGet('ss').then(r => {
      setBanner(r.data.data.data);
      setIsLoading(false);
    });
    apiGet('event-categories').then(r => {
      setKategori(r.data.data.data);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsDataLoading(true);
    apiGet(
      `events?limit=6&is_published=1${
        filter ? `&event_category_id=${filter}` : ''
      }`
    )
      .then(r => {
        setData(r.data.data.data);
        setNext(r.data.data.next_page_url);
        setIsDataLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsDataLoading(false);
      });
  }, [filter]);

  const handleNext = async e => {
    e.preventDefault();
    if (isDataLoading) {
      return;
    }
    setIsNextLoading(true);
    try {
      const r = await apiGetWithURL(next);
      setIsNextLoading(false);
      setData([...data, ...r.data.data.data]);
      setNext(r.data.data.next_page_url);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsNextLoading(false);
    }
  };

  return (
    <div className="container event">
      {isLoading && <SkeletonEvent />}
      {!isLoading && (
        <Grid>
          <Row>
            <Col>
              <Carousel shape="bar">
                {banner.length &&
                  banner.map((d, i) => (
                    <img key={i} src={d.ss_url} alt="img" />
                  ))}
              </Carousel>
            </Col>
          </Row>
          <Row className="content">
            <Col md={8}>
              <div className="filter-side">
                <h2 className="title">Kategori</h2>
                <hr />
                <ul>
                  <li
                    className={!filter ? 'active' : ''}
                    onClick={() => {
                      if (isDataLoading) return;
                      setFilter(null);
                    }}
                  >
                    <img src={book} alt="" width="24" height="24" />
                    Semua Kategori
                  </li>
                  {kategori.length > 0 &&
                    kategori.map(d => (
                      <li
                        key={d.id}
                        onClick={() => {
                          if (isDataLoading) return;
                          setFilter(d.id);
                        }}
                        className={filter === d.id ? 'active' : ''}
                      >
                        <img src={creditCard} alt="" width="24" height="24" />
                        {d.name}
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
            <Col md={16}>
              <div className="cards-event-container">
                {isDataLoading && <SkeletonDataEvent />}
                {!isDataLoading &&
                  data &&
                  data.map(d => <CardEvent key={d.id} data={d} />)}

                {isNextLoading ? (
                  <SkeletonDataEvent />
                ) : (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    {next ? (
                      <a onClick={handleNext} href="https://arzky.me">
                        Lihat Event Lainnya
                      </a>
                    ) : (
                      'tidak ada lagi data'
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default EventPage;
