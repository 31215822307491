import React, { useEffect, useState } from 'react';
import { Alert, Button, Icon, Modal, Uploader } from 'rsuite';

import { apiPostWithAuth } from '../../misc/config';
import { useMediaQuery } from '../../misc/custom-hooks';

const INIT = {
  cv: null,
  portfolio: null,
};

const EditCV = ({ profile, updateProfile, mdState }) => {
  const [changeCV, setChangeCV] = useState(false);
  const [changePortfolio, setChangePortfolio] = useState(false);
  const [formData, setFormData] = useState(INIT);
  const [isLoading, setIsLoading] = useState(false);
  const mobile = useMediaQuery('(max-width: 992px)');

  const onUploadClick = async () => {
    if (!formData.cv && !formData.portfolio) {
      mdState.close();
      return;
    }

    setIsLoading(true);
    const fd = new FormData();
    if (formData.cv) {
      fd.append('cv_name', formData.cv.blobFile);
    }
    if (formData.portfolio) {
      fd.append('portfolio_name', formData.portfolio.blobFile);
    }

    try {
      const updated = await apiPostWithAuth('users/update-me', fd);
      updateProfile({ ...updated.data.data });

      Alert.success('berhasil');
      setFormData(INIT);

      setIsLoading(false);
    } catch (err) {
      Alert.error('gagal');
      setFormData(INIT);

      setIsLoading(false);
      // setPpLoading(false);
    }
  };

  useEffect(() => {
    if (!profile.job_seeker.cv_url) {
      setChangeCV(true);
    } else {
      setChangeCV(false);
    }
    if (!profile.job_seeker.portfolio_url) {
      setChangePortfolio(true);
    } else {
      setChangePortfolio(false);
    }
  }, [profile.job_seeker]);

  return (
    <Modal
      show={mdState.isOpen}
      onHide={() => {
        setFormData(INIT);
        mdState.close();
      }}
      size={mobile ? 'xs' : 'sm'}
    >
      <Modal.Header>
        <Modal.Title>CV dan Portfolio</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>Upload CV anda</h6>
        {profile.job_seeker.cv_url && !formData.cv && !changeCV && (
          <>
            <a href={profile.job_seeker.cv_url} target="blank">
              {profile.job_seeker.cv_name}
            </a>
            <a
              href="/"
              style={{ color: 'red', marginBottom: 30, display: 'block' }}
              onClick={e => {
                e.preventDefault();
                setChangeCV(!changeCV);
              }}
            >
              <Icon icon="trash" style={{ color: 'red', marginRight: 5 }} />
              hapus
            </a>
          </>
        )}
        {!formData.cv && changeCV && (
          <>
            {profile.job_seeker.cv_url && (
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  setChangeCV(!changeCV);
                }}
              >
                Reset
              </a>
            )}
            <Uploader
              action=""
              accept="application/pdf"
              draggable
              multiple={false}
              fileListVisible={false}
              onChange={e => {
                setFormData({ ...formData, cv: e[0] });
              }}
            >
              <div style={{ lineHeight: '50px' }}>
                Click or Drag files to this area to upload
              </div>
            </Uploader>
          </>
        )}
        {formData.cv && (
          <>
            <span>{formData.cv.name}</span>
            <a
              href="/"
              style={{ color: 'red', marginBottom: 30, display: 'block' }}
              onClick={e => {
                e.preventDefault();
                setFormData({ ...formData, cv: null });
                setChangeCV(true);
              }}
            >
              <Icon icon="reload" style={{ color: 'red', marginRight: 5 }} />
              reset
            </a>
          </>
        )}
        <h6>Upload Portfolio anda</h6>
        {profile.job_seeker.portfolio_url &&
          !formData.portfolio &&
          !changePortfolio && (
            <>
              <a href={profile.job_seeker.portfolio_url} target="blank">
                {profile.job_seeker.portfolio_name}
              </a>
              <a
                href="/"
                style={{ color: 'red', marginBottom: 30, display: 'block' }}
                onClick={e => {
                  e.preventDefault();
                  setChangePortfolio(!changePortfolio);
                }}
              >
                <Icon icon="trash" style={{ color: 'red', marginRight: 5 }} />
                hapus
              </a>
            </>
          )}
        {!formData.portfolio && changePortfolio && (
          <>
            {profile.job_seeker.portfolio_url && (
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  setChangePortfolio(!changePortfolio);
                }}
              >
                Reset
              </a>
            )}
            <Uploader
              action=""
              accept="application/pdf"
              draggable
              multiple={false}
              fileListVisible={false}
              onChange={e => {
                setFormData({ ...formData, portfolio: e[0] });
              }}
            >
              <div style={{ lineHeight: '50px' }}>
                Click or Drag files to this area to upload
              </div>
            </Uploader>
          </>
        )}
        {formData.portfolio && (
          <>
            <span>{formData.portfolio.name}</span>
            <a
              href="/"
              style={{ color: 'red', marginBottom: 30, display: 'block' }}
              onClick={e => {
                e.preventDefault();
                setFormData({ ...formData, portfolio: null });
                setChangePortfolio(true);
              }}
            >
              <Icon icon="reload" style={{ color: 'red', marginRight: 5 }} />
              reset
            </a>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          onClick={onUploadClick}
          loading={isLoading}
          disabled={isLoading}
          // disabled={isLoading}
        >
          Simpan
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCV;
