import React from 'react';

import './card-event.style.scss';
import { Link } from 'react-router-dom';
import moment from 'moment';

import mapPin from '../../assets/icon/map-pin.svg';
import dollarSign from '../../assets/icon/dollar-sign-gray.svg';
import calendar from '../../assets/icon/calendar.svg';
import clock from '../../assets/icon/clock-gray.svg';

const CardEvent = ({ data, ...props }) => {
  return (
    <Link
      to={`/event/${data.alias_url}`}
      style={{ textDecoration: 'none' }}
      className="card-event"
      {...props}
    >
      <div className="image">
        <img src={data.image_url} alt="" />
        <div className="badge">
          {data.event_category ? data.event_category.name : ''}
        </div>
      </div>
      <div className="card-event-title">{data.title}</div>
      <div className="detail">
        <div className="satu">
          <div className="lokasi">
            <img src={mapPin} alt="" />
            <span>{data.address_type}</span>
          </div>
          <div className="harga">
            <img src={dollarSign} alt="" />
            <span>{data.price === 0 ? 'free' : `Rp. ${data.price}`}</span>
          </div>
        </div>
        <div className="dua">
          <div className="kalender">
            <img src={calendar} alt="" />
            <span>{moment(data.date).format('dddd, DD MMMM YYYY')}</span>
          </div>
          <div className="waktu">
            <img src={clock} alt="" />
            <span>{data.time} WIB</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardEvent;
