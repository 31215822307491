import React from 'react';
import ContentLoader from 'react-content-loader';
import { Col, Row } from 'rsuite';

const SkeletonEvent = props => {
  return (
    <>
      <Col md={24}>
        <ContentLoader
          speed={2}
          width="100%"
          height="400"
          // viewBox="0 0 100% 600"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="18" y="4" rx="0" ry="0" width="100%" height="300" />
        </ContentLoader>
      </Col>
      <Row>
        <Col md={8} mdOffset={8}>
          <ContentLoader
            speed={2}
            width="100%"
            height={160}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="50%" height="150" />
            <rect x="55%" y="4" rx="0" ry="0" width="20%" height="30" />
            <rect x="55%" y="52" rx="0" ry="0" width="45%" height="20" />
            <rect x="55%" y="98" rx="0" ry="0" width="45%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={160}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="50%" height="150" />
            <rect x="55%" y="4" rx="0" ry="0" width="20%" height="30" />
            <rect x="55%" y="52" rx="0" ry="0" width="45%" height="20" />
            <rect x="55%" y="98" rx="0" ry="0" width="45%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={160}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="50%" height="150" />
            <rect x="55%" y="4" rx="0" ry="0" width="20%" height="30" />
            <rect x="55%" y="52" rx="0" ry="0" width="45%" height="20" />
            <rect x="55%" y="98" rx="0" ry="0" width="45%" height="20" />
          </ContentLoader>
        </Col>
        <Col md={8}>
          <ContentLoader
            speed={2}
            width="100%"
            height={160}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="50%" height="150" />
            <rect x="55%" y="4" rx="0" ry="0" width="20%" height="30" />
            <rect x="55%" y="52" rx="0" ry="0" width="45%" height="20" />
            <rect x="55%" y="98" rx="0" ry="0" width="45%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={160}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="50%" height="150" />
            <rect x="55%" y="4" rx="0" ry="0" width="20%" height="30" />
            <rect x="55%" y="52" rx="0" ry="0" width="45%" height="20" />
            <rect x="55%" y="98" rx="0" ry="0" width="45%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={160}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="50%" height="150" />
            <rect x="55%" y="4" rx="0" ry="0" width="20%" height="30" />
            <rect x="55%" y="52" rx="0" ry="0" width="45%" height="20" />
            <rect x="55%" y="98" rx="0" ry="0" width="45%" height="20" />
          </ContentLoader>
        </Col>
      </Row>
    </>
  );
};

export default SkeletonEvent;
