import React, { useState, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Alert } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import { useMediaQuery } from '../../misc/custom-hooks';
import LinkAlt from '../link-alt/LinkAlt';
// import { Button } from 'rsuite';
import './topNavBar.style.scss';
import logo from '../../assets/img/logo-puskarir.svg';

const TopPerusahaanNav = () => {
  const { profile, isLoading, removeToken } = useProfile();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const mobile = useMediaQuery('(max-width: 992px)');

  React.useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const onLogout = () => {
    removeToken();
    Alert.info('berhasil logout', 4000);
  };

  // if (token.logout && !profile) {
  //   Alert.info('berhasil logout', 4000);
  // }
  return (
    <div className={`nav ${menuOpen ? 'collapsed' : ''}`}>
      <Link className="logo fm-roboto" to="/">
        <img src={logo} alt="" />
      </Link>
      <div
        className="hamburger"
        onClick={() => setMenuOpen(!menuOpen)}
        onKeyDown={() => setMenuOpen(!menuOpen)}
        role="presentation"
      >
        <span className="line1" />
        <span className="line2" />
        <span className="line3" />
      </div>
      <div className={`collapse ${menuOpen ? 'active' : ''}`}>
        <div className="left">
          <ul>
            <li>
              <Link
                to="/company/kelola-lowongan"
                className={`link-nav ${
                  location.pathname === '/company/kelola-lowongan'
                    ? 'active'
                    : ''
                }`}
              >
                Kelola Lowongan
              </Link>
            </li>
            <li>
              <Link
                to="/company/kelola-pelamar"
                className={`link-nav ${
                  location.pathname.startsWith('/company/kelola-pelamar')
                    ? 'active'
                    : ''
                }`}
              >
                Kelola Pelamar
              </Link>
            </li>
            <li>
              <Link
                to="/company/paket-berlangganan"
                className={`link-nav ${
                  location.pathname.startsWith('/company/paket-berlangganan')
                    ? 'active'
                    : ''
                }`}
              >
                Paket Berlangganan
              </Link>
            </li>
          </ul>
        </div>

        <div className="right">
          <Link
            to="/company/kelola-profile"
            className="img-profile"
            style={{
              backgroundImage: `url(${profile.company.profile_picture_url})`,
            }}
          />
          {profile && (
            <Dropdown
              loading={isLoading}
              title={
                profile.fullname
                  ? profile.fullname.split(' ')[0].toUpperCase()
                  : 'null'
              }
              trigger={mobile ? ['click', 'hover'] : 'click'}
              placement="bottomEnd"
            >
              <Dropdown.Item
                componentClass={LinkAlt}
                to="/company/kelola-lowongan"
                text="Kelola Lowongan"
                // active={location.pathname === '/me'}
                className="link-drop"
              />
              <Dropdown.Item
                componentClass={LinkAlt}
                to="/company/kelola-pelamar"
                text="Kelola Pelamar"
                className="link-drop"
              />
              <Dropdown.Item
                componentClass={LinkAlt}
                to="/company/kelola-profile"
                text="Kelola Profil Perusahaan"
                className="link-drop"
              />
              <Dropdown.Item
                componentClass={LinkAlt}
                to="/company/kelola-akun"
                text="Kelola Profil Akun"
                className="link-drop"
              />
              <Dropdown.Item onClick={onLogout}>
                <button type="button" className="btn-logout">
                  Keluar
                </button>
              </Dropdown.Item>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TopPerusahaanNav);
