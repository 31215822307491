import React from 'react';

import kosong from '../../assets/img/kosong.png';

const PencarianKosong = () => {
  return (
    <div style={{ maxWidth: 385, textAlign: 'center', margin: '40px auto' }}>
      <img src={kosong} alt="" />
      <h3 style={{ fontWeight: 800, fontSize: 18 }}>tidak ada data event</h3>
    </div>
  );
};

export default PencarianKosong;
