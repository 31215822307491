/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Alert, SelectPicker, Loader } from 'rsuite';
import { useLocation } from 'react-router-dom';

import './kelolapelamar.style.scss';
import CustomColumnTable from '../../../components/table/CustomColumnTable';
import { apiGetWithAuth, apiPutWithAuth } from '../../../misc/config';

const KelolaPelamar = () => {
  const location = useLocation();
  const [dataDropdown, setDataDropdown] = useState([]);
  const [selectedLowongan, setSelectedLowongan] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [dataPelamar, setDataPelamar] = useState([]);

  const [dataIsLoading, setDataIsLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState(null);

  const [status, setStatus] = useState({
    value: 'Sedang Diulas',
    next: 'Diterima',
  });

  const onVacanciesChange = async d => {
    setSelectedLowongan(d);
    try {
      setDataIsLoading(true);
      const result = await apiGetWithAuth(
        `users-vacancies/my-vacancies?vacancy_id=${d}&status=${status.value}`
      );
      setDataIsLoading(false);
      setDataPelamar(
        result.data.data.data.map(dt => {
          return {
            created_at: dt.created_at,
            institution: dt.job_seeker_user.job_seeker.institution,
            phone: dt.job_seeker_user.job_seeker.phone,
            fullname: dt.job_seeker_user.fullname.toLowerCase(),
            email: dt.job_seeker_user.email,
            uuid: dt.job_seeker_user.uuid,
            id: dt.id,
          };
        })
      );
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setDataIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    apiGetWithAuth('vacancies/my-vacancies')
      .then(r => {
        setDataDropdown(
          r.data.data.data.map(e => {
            return { label: e.title, value: e.id };
          })
        );
        let d = r.data.data.data[0].id;
        if (location.state && location.state.idlowongan) {
          d = location.state.idlowongan;
        }
        setSelectedLowongan(d);
        setDataIsLoading(true);
        apiGetWithAuth(
          `users-vacancies/my-vacancies?vacancy_id=${d}&status=Sedang Diulas`
        )
          .then(result => {
            setDataIsLoading(false);
            setDataPelamar(
              result.data.data.data.map(dt => {
                return {
                  created_at: dt.created_at,
                  institution: dt.job_seeker_user.job_seeker.institution,
                  phone: dt.job_seeker_user.job_seeker.phone,
                  fullname: dt.job_seeker_user.fullname.toLowerCase(),
                  email: dt.job_seeker_user.email,
                  uuid: dt.job_seeker_user.uuid,
                  id: dt.id,
                };
              })
            );
          })
          .catch(err => {
            Alert.error(err.response ? err.response.data.message : err.name);
            setDataIsLoading(false);
          });

        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [location.state]);

  // if (sortColumn && sortType) {

  // }

  const onStatusChange = async d => {
    if (dataIsLoading) {
      return;
    }
    setStatus(d);
    try {
      setDataIsLoading(true);
      const result = await apiGetWithAuth(
        `users-vacancies/my-vacancies?vacancy_id=${selectedLowongan}&status=${d.value}`
      );
      setDataIsLoading(false);
      setDataPelamar(
        result.data.data.data.map(dt => {
          return {
            created_at: dt.created_at,
            institution: dt.job_seeker_user.job_seeker.institution,
            phone: dt.job_seeker_user.job_seeker.phone,
            fullname: dt.job_seeker_user.fullname.toLowerCase(),
            email: dt.job_seeker_user.email,
            uuid: dt.job_seeker_user.uuid,
            id: dt.id,
          };
        })
      );
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setDataIsLoading(false);
    }
  };

  const handleAcc = async ids => {
    try {
      await apiPutWithAuth('users-vacancies/update-many-my-vacancies', {
        ids,
        status: status.next,
      });
      Alert.success('berhasil');
      onStatusChange({
        value: status.value,
        next: status.next,
      });
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
    }
  };

  const handleTolak = async ids => {
    try {
      await apiPutWithAuth('users-vacancies/update-many-my-vacancies', {
        ids,
        status: 'Ditolak',
      });
      Alert.success('berhasil');
      await onStatusChange({
        value: status.value,
        next: status.next,
      });
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
    }
  };

  const handleSort = (sc, st) => {
    // setIsLoading(true)

    const s = dataPelamar.sort((a, b) => {
      const x = a[sc];
      const y = b[sc];

      if (st === 'asc') {
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      } else {
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
      }
      return 0;
    });

    setDataPelamar(s);

    setSortColumn(sc);
    setSortType(st);
  };

  return (
    <div className="container kelola-pelamar">
      {isLoading ? (
        <div style={{ textAlign: 'center', lineHeight: '70vh' }} key={0}>
          <Loader content="Loading" speed="slow" size="md" />
        </div>
      ) : (
        <Grid>
          <Row className="atas">
            <Col md={5}>
              <h5>Kelola Pelamar:</h5>
            </Col>
            <Col md={19}>
              <SelectPicker
                size="lg"
                data={dataDropdown}
                value={selectedLowongan}
                className="white"
                cleanable={false}
                onChange={onVacanciesChange}
                placeholder="Pilih Lowongan"
                disabled={dataIsLoading}
              />
            </Col>
          </Row>
          <Row className="bawah">
            <Col md={5}>
              <div className="filter-side" style={{ padding: '0 10px 0 0' }}>
                <ul>
                  <li
                    className={status.value === 'Sedang Diulas' ? 'active' : ''}
                    onClick={() => {
                      onStatusChange({
                        value: 'Sedang Diulas',
                        next: 'Diterima',
                      });
                    }}
                  >
                    Sedang Diulas
                  </li>
                  <li
                    className={status.value === 'Diterima' ? 'active' : ''}
                    onClick={() => {
                      onStatusChange({ value: 'Diterima', next: 'Wawancara' });
                    }}
                  >
                    Diterima
                  </li>
                  <li
                    className={status.value === 'Wawancara' ? 'active' : ''}
                    onClick={() => {
                      onStatusChange({
                        value: 'Wawancara',
                        next: 'Ditawarkan',
                      });
                    }}
                  >
                    Wawancara
                  </li>
                  <li
                    className={status.value === 'Ditawarkan' ? 'active' : ''}
                    onClick={() => {
                      onStatusChange({
                        value: 'Ditawarkan',
                        next: 'Dipekerjakan',
                      });
                    }}
                  >
                    Ditawarkan
                  </li>
                  <li
                    className={status.value === 'Dipekerjakan' ? 'active' : ''}
                    onClick={() => {
                      onStatusChange({
                        value: 'Dipekerjakan',
                        next: null,
                      });
                    }}
                  >
                    Dipekerjakan
                  </li>
                  <li
                    className={status.value === 'Ditolak' ? 'active' : ''}
                    onClick={() => {
                      onStatusChange({
                        value: 'Ditolak',
                        next: null,
                      });
                    }}
                  >
                    Ditolak
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={19}>
              <div className="content">
                <CustomColumnTable
                  handleAcc={handleAcc}
                  next={status.next}
                  data={dataPelamar}
                  isLoading={dataIsLoading}
                  handleTolak={handleTolak}
                  setIsLoading={setDataIsLoading}
                  sortColumn={sortColumn}
                  setSortColumn={setSortColumn}
                  sortType={sortType}
                  handleSort={handleSort}
                  setSortType={setSortType}
                  setData={setDataPelamar}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default KelolaPelamar;
