/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Col, Grid, Row } from 'rsuite';

import checkCircle from '../../../assets/icon/check-circle.svg';
import list from '../../../assets/icon/list.svg';
import clock from '../../../assets/icon/clock.svg';

import './kelolaakun.style.scss';
import InformasiDasar from './InformasiDasar';
import KelolaEmail from './KelolaEmail';
import KelolaPassword from './KelolaPassword';

const KeloalAkun = () => {
  const [tampil, setTampil] = useState(1);

  return (
    <div className="container kelola-akun">
      <Grid>
        <Row>
          <Col md={6}>
            <div className="filter-side mb-3">
              <ul>
                <li
                  className={tampil === 1 ? 'active' : ''}
                  onClick={() => {
                    setTampil(1);
                  }}
                >
                  <img src={list} alt="" width="24" height="24" />
                  Informasi Dasar
                </li>
                <li
                  className={tampil === 2 ? 'active' : ''}
                  onClick={() => {
                    setTampil(2);
                  }}
                >
                  <img src={checkCircle} alt="" width="24" height="24" />
                  Verifikasi Email
                </li>
                <li
                  className={tampil === 3 ? 'active' : ''}
                  onClick={() => {
                    setTampil(3);
                  }}
                >
                  <img src={clock} alt="" width="24" height="24" />
                  Ubah Password
                </li>
              </ul>
            </div>
          </Col>
          <Col md={18}>
            <div className="main">
              {tampil === 1 && <InformasiDasar />}
              {tampil === 2 && <KelolaEmail />}
              {tampil === 3 && <KelolaPassword />}
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default KeloalAkun;
