import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  InputPicker,
  Row,
  Schema,
} from 'rsuite';
import './tracer.style.scss';
import { useHistory, useLocation } from 'react-router-dom';

import tracerImage from '../../assets/img/tracer.png';
import { apiGet, apiPost } from '../../misc/config';
import { useProfile } from '../../context/profile.context';

const { StringType, NumberType } = Schema.Types;

const model = Schema.Model({
  nim: StringType().isRequired('nim harus diiisi'),
  pin: StringType().isRequired('nim harus diiisi'),
  questioner_category_id: NumberType().isRequired('kategori harus diisi'),
});

const TracerStudy = () => {
  const [dataCategory, setDataCategory] = useState([]);
  // const [category, setCategory] = useState(null);
  const { updateAlumni } = useProfile(null);
  const history = useHistory();
  const location = useLocation();

  const [dataLoading, setDataLoading] = useState(false);

  const [formValue, setFormValue] = useState({
    nim: '',
    pin: '',
    questioner_category_id: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef();

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      const result = await apiPost('alumnies/check', formValue);
      const { data } = result.data;
      updateAlumni(data.alumni);
      history.push(`/tracer-study/${data.questioner_category.alias_url}`);

      Alert.info(`selamat datang`, 4000);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // Alert.error(err.message);
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
    }
  };
  useEffect(() => {
    let mounted = true;
    setDataLoading(true);
    apiGet('questioner-categories?is_published=1')
      .then(r => {
        if (mounted) {
          setDataCategory(
            r.data.data.data.map(d => {
              return { label: d.name, value: d.id };
            })
          );
          if (location.state && location.state.alias) {
            const result = r.data.data.data.filter(obj => {
              return obj.alias_url === location.state.alias;
            });
            setFormValue({
              nim: '',
              pin: '',
              questioner_category_id: result[0].id,
            });
          }
          setDataLoading(false);
        }
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.messag : err.name);
        setDataLoading(false);
      });

    return function cleanup() {
      mounted = false;
    };
  }, [location.state]);

  return (
    <div className="container tracer-study">
      <Grid>
        <Row className="cont">
          <Col>
            <h1 className="title">Selamat Datang di Tracer Studi</h1>
            <p>
              Temukan pekerjaan impian kamu sekarang! dengan fitur unggulan
              Sistem Informasi Pusar Karier
            </p>
            <div className="form-tracer">
              <Form
                fluid
                onChange={onFormChange}
                formValue={formValue}
                model={model}
                ref={formRef}
                onSubmit={onSubmit}
                className="form-submit"
              >
                <FormGroup style={{ width: 180, marginRight: 20 }}>
                  <ControlLabel htmlFor="nim">NIM</ControlLabel>
                  <FormControl
                    id="nim"
                    placeholder="NIM"
                    name="nim"
                    type="text"
                  />
                </FormGroup>
                <FormGroup style={{ width: 135, marginRight: 20 }}>
                  <ControlLabel htmlFor="pin">Kode Unik</ControlLabel>
                  <FormControl
                    id="pin"
                    placeholder="Kode"
                    name="pin"
                    type="text"
                  />
                </FormGroup>
                <FormGroup style={{ marginRight: 20 }}>
                  <ControlLabel htmlFor="questioner_category_id">
                    Kategori
                  </ControlLabel>
                  <FormControl
                    id="questioner_category_id"
                    accepter={InputPicker}
                    loading={dataLoading}
                    name="questioner_category_id"
                    data={dataCategory}
                    cleanable={false}
                  />
                </FormGroup>
                <FormGroup>
                  <ButtonToolbar>
                    <Button
                      appearance="primary"
                      style={{ padding: '14px 50px' }}
                      type="submit"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </div>
            <img src={tracerImage} alt="" />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default TracerStudy;
