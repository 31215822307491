import React from 'react';
import ContentLoader from 'react-content-loader';
import { Col } from 'rsuite';

const SkeletonEventDetail = props => {
  return (
    <>
      <Col md={24}>
        <ContentLoader
          speed={2}
          width="100%"
          height="600"
          // viewBox="0 0 100% 600"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="18" y="4" rx="0" ry="0" width="100%" height="300" />
          <rect x="19" y="330 " rx="0" ry="0" width="100" height="30" />
          <rect x="17" y="370" rx="0" ry="0" width="100%" height="20" />
          <rect x="17" y="400" rx="0" ry="0" width="100%" height="20" />
          <rect x="17" y="600" rx="0" ry="0" width="100%" height="20" />
        </ContentLoader>
      </Col>
    </>
  );
};

export default SkeletonEventDetail;
