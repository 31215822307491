import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Col,
  Grid,
  Icon,
  Input,
  InputGroup,
  Loader,
  Row,
} from 'rsuite';
import { useHistory } from 'react-router';
import { useProfile } from '../../../context/profile.context';

import { apiGetWithAuth } from '../../../misc/config';

import CardLowongan from '../../../components/card/CardLowongan';
import './kelolalowongan.style.scss';
import UpdateLowongan from './UpdateLowongan';

const KelolaLowongan = () => {
  const [action, setAction] = useState('get');
  const [filter, setFilter] = useState({ is_active: null });
  const [data, setData] = useState([]);
  const [di, setDi] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [length, setLength] = useState({ all: 0, active: 0, inactive: 0 });
  const [trigger, setTrigger] = useState(false);
  const { profile } = useProfile();
  const history = useHistory();

  const handleButtonEdit = id => {
    setAction('edit');
    const a = data.filter(obj => {
      return obj.id === id;
    });
    setDi(a[0]);
  };

  useEffect(() => {
    setIsLoading(true);
    let mounted = true;
    apiGetWithAuth(`vacancies/my-vacancies`)
      .then(r => {
        if (mounted) {
          setData(r.data.data.data);
          const all = r.data.data.data.length;
          const active = r.data.data.data.filter(d => d.is_active).length;
          const inactive = r.data.data.data.filter(d => !d.is_active).length;
          setLength({ all, active, inactive });
          setIsLoading(false);
        }
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.namee);
        setIsLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [trigger]);

  const renderCard = () => {
    if (data.length === 0) {
      return <div className="text-center">tidak ada data</div>;
    }
    let dataFix = data;
    if (filter.is_active === 1) dataFix = data.filter(f => f.is_active);
    if (filter.is_active === 0) dataFix = data.filter(f => !f.is_active);

    return (
      <>
        {dataFix.map((d, i) => (
          <CardLowongan
            key={i}
            d={d}
            onEdit={handleButtonEdit}
            refresh={() => {
              setTrigger(!trigger);
            }}
          />
        ))}
      </>
    );
  };

  const addLowongan = () => {
    if (!profile.company.is_accepted) {
      Alert.warning(
        'tidak bisa membuat lowongan, harap lengkapi profile dan menunggu terverifikasi admin',
        5000
      );
      return;
    }
    history.push('/company/kelola-lowongan/add');
  };

  return (
    <div className="container kelola-lowongan">
      <Grid>
        <Row>
          <Col md={6} className="kiri">
            <h3 className="title">List Lowongan Pekerjaan</h3>
            <div className="filter-side">
              <ul>
                {action === 'get' && (
                  <>
                    <Button
                      componentClass="li"
                      classPrefix
                      className={filter.is_active === null ? 'active' : ''}
                      onClick={() => {
                        setAction('get');
                        setFilter({ is_active: null });
                      }}
                      type="button"
                    >
                      All ({length.all})
                    </Button>
                    <Button
                      componentClass="li"
                      classPrefix
                      className={filter.is_active === 1 ? 'active' : ''}
                      onClick={() => {
                        setAction('get');
                        setFilter({ is_active: 1 });
                      }}
                      type="button"
                    >
                      Aktif ({length.active})
                    </Button>
                    <Button
                      componentClass="li"
                      classPrefix
                      className={filter.is_active === 0 ? 'active' : ''}
                      onClick={() => {
                        setAction('get');
                        setFilter({ is_active: 0 });
                      }}
                      type="button"
                    >
                      Tidak Aktif ({length.inactive})
                    </Button>
                  </>
                )}
                {action !== 'get' && (
                  <Button
                    componentClass="li"
                    classPrefix
                    className="active"
                    onClick={() => {
                      setAction('get');
                      setFilter({ is_active: null });
                    }}
                    type="button"
                  >
                    Back
                  </Button>
                )}
              </ul>
            </div>
          </Col>

          <Col md={18} className="kanan">
            <div className="top">
              <InputGroup inside className="input-search">
                <InputGroup.Addon>
                  <Icon icon="search" size="lg" style={{ color: '#737B7D' }} />
                </InputGroup.Addon>
                <Input placeholder="Cari Lowongan" />
              </InputGroup>
              {/* <Link to="/company/kelola-lowongan/add"> */}
              <Button
                appearance="primary"
                className="btn-tambah"
                disabled={isLoading}
                onClick={addLowongan}
              >
                <Icon icon="plus" style={{ color: 'white', marginRight: 7 }} />
                Tambah Lowongan
              </Button>
              {/* </Link> */}
            </div>
            {isLoading && (
              <div className="text-center">
                <div
                  style={{ textAlign: 'center', lineHeight: '50vh' }}
                  key={0}
                >
                  <Loader content="Loading" speed="slow" size="md" />
                </div>
              </div>
            )}
            {!isLoading && (
              <>
                {action === 'get' && (
                  <>
                    <div className="content">{renderCard()}</div>
                  </>
                )}
                {action === 'edit' && (
                  <UpdateLowongan
                    di={di}
                    setAction={setAction}
                    refresh={() => {
                      setTrigger(!trigger);
                    }}
                  />
                )}
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default KelolaLowongan;
