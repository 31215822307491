import React from 'react';
import { Button, Modal } from 'rsuite';
import './modalalert.style.scss';
import lamarImg from '../../assets/img/lamar.svg';

const ConfirmModal = ({ isOpen, close, onClick, isLoading }) => {
  return (
    <Modal className="modalalert" show={isOpen} onHide={close} clo size="xs">
      <Modal.Body className="body">
        <img src={lamarImg} alt="" />
        <h4>Apakah Anda yakin bahwa data yang dikirim benar?</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} appearance="subtle">
          Batal
        </Button>
        <Button
          onClick={onClick}
          appearance="primary"
          className="btn-primary2"
          loading={isLoading}
          disabled={isLoading}
        >
          Ya
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
