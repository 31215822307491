import React from 'react';
import { Icon } from 'rsuite';
import './span.style.scss';

const SpanLabelIcon = ({ name, icon, own, className, color, ...props }) => {
  return (
    <span className={`span-label ${className}`} {...props}>
      {icon && <Icon icon={icon} />}
      {own && <img src={own} alt="" />}
      <span>{name}</span>
    </span>
  );
};

export default SpanLabelIcon;
