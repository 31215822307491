import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import './cardlamaranku.style.scss';

const CardLamaranku = ({ data }) => {
  let color = 'bg-blue';
  if (data.status === 'Sedang Diulas') {
    color = 'bg-yellow';
  }
  if (data.status === 'Ditolak') {
    color = 'bg-red';
  }
  if (data.status === 'Dipekerjakan') {
    color = 'bg-green';
  }

  return (
    <>
      <div className="card-lamaranku">
        <div className="kiri">
          <img
            src={data.vacancy.user.company.profile_picture_url}
            alt=""
            width="50"
          />
          <div className="kirii">
            <Link to={`lowongan-kerja/${data.vacancy.id}`}>
              <h3>{data.vacancy.title}</h3>
            </Link>
            <h4>{data.vacancy.user.company.company_name}</h4>
            <h5>
              Dikirim pada{' '}
              {moment(data.created_at).format('Do MMMM YYYY, hh:mm a')}
            </h5>
          </div>
        </div>
        <div className={`badge ${color}`}>{data.status}</div>
      </div>
      <hr />
    </>
  );
};

export default CardLamaranku;
