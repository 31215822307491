import React, { useCallback, useRef, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Schema,
} from 'rsuite';
import moment from 'moment';

import ButtonEdit from '../../components/button-edit/ButtonEdit';
import plusCircle from '../../assets/icon/plus-circle.svg';

import { useMediaQuery, useModalState } from '../../misc/custom-hooks';
import { apiPostWithAuth } from '../../misc/config';

const INIT = {
  title: '',
  sub_title: '',
  date_in: null,
  date_out: null,
};
const { StringType, DateType } = Schema.Types;

const model = Schema.Model({
  title: StringType().isRequired('required'),
  sub_title: StringType().isRequired('required'),
  date_in: DateType().isRequired('required'),
  date_out: DateType().isRequired('required'),
});

const TambahPengalaman = ({ updateProfile, mainTitle, data }) => {
  const { isOpen, open, close } = useModalState();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(INIT);
  const mobile = useMediaQuery('(max-width : 992px)');

  const formRef = useRef();

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    const x = moment(new Date(formValue.date_in)).diff(
      moment(new Date(formValue.date_out)),
      'month',
      true
    );

    try {
      setIsLoading(true);

      const updated = await apiPostWithAuth('users/update-me', {
        experiences: [
          {
            type: data.type,
            title: formValue.title,
            sub_title: formValue.sub_title,
            date_in: moment(formValue.date_in).format('YYYY-MM-DD'),
            date_out: moment(formValue.date_out).format('YYYY-MM-DD'),
            total_months: Math.abs(Math.round(x)) + 1,
          },
        ],
      });
      updateProfile({ ...updated.data.data });

      Alert.success('berhasil');
      setFormValue(INIT);
      close();
      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
    }
  };

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  return (
    <>
      <div className="title">
        {mainTitle}
        <ButtonEdit
          own={plusCircle}
          text={`Tambah ${mainTitle}`}
          onClick={open}
        />
      </div>
      <Modal
        show={isOpen}
        onHide={() => {
          setFormValue(INIT);
          close();
        }}
        size={mobile ? 'xs' : 'sm'}
      >
        <Form
          fluid
          onChange={onFormChange}
          formValue={formValue}
          model={model}
          ref={formRef}
          onSubmit={onSubmit}
          style={{ paddingRight: 10 }}
        >
          <Modal.Header>
            <Modal.Title>Tambah {mainTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel htmlFor="title">{data.title}</ControlLabel>
              <FormControl
                id="title"
                placeholder={data.p_title}
                name="title"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="sub_title">{data.sub_title}</ControlLabel>
              <FormControl
                id="sub_title"
                placeholder={data.p_subtitle}
                name="sub_title"
                type="text"
              />
            </FormGroup>
            <div style={{ display: 'flex' }}>
              <FormGroup style={{ marginRight: 20 }}>
                <ControlLabel htmlFor="date_in">{data.date_in}</ControlLabel>
                <FormControl
                  id="date_in"
                  name="date_in"
                  accepter={DatePicker}
                  format="MMMM-YYYY"
                  placement="auto"
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel htmlFor="date_out">{data.date_out}</ControlLabel>
                <FormControl
                  id="date_out"
                  name="date_out"
                  accepter={DatePicker}
                  format="MMMM-YYYY"
                  placement="auto"
                />
              </FormGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              block
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default TambahPengalaman;
