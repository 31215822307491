import React, { useCallback, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  HelpBlock,
  Icon,
  InputGroup,
  Radio,
  RadioGroup,
  Row,
  Schema,
} from 'rsuite';
import { Link, useLocation } from 'react-router-dom';
import LinkAlt from '../../components/link-alt/LinkAlt';
import { useProfile } from '../../context/profile.context';
import { apiPost } from '../../misc/config';

import './signup.style.scss';

import registImage from '../../assets/img/regist-img.png';

const { StringType } = Schema.Types;

const model = Schema.Model({
  fullname: StringType().isRequired('nama harus diiisi'),
  email: StringType()
    .isRequired('email harus diiisi')
    .isEmail('harap masukkan email yang valid'),
  password: StringType()
    .isRequired('Password harus diisi')
    .minLength(8, 'min 8 karakter'),
  password_confirmation: StringType()
    .addRule((val, data) => {
      if (val !== data.password) {
        return false;
      }
      return true;
    }, 'Password tidak sama')
    .isRequired('password konfirmasi harus diiisi'),
});

const INITIAL_FORM = {
  fullname: '',
  email: '',
  password: '',
  password_confirmation: '',
};

const SignUp = () => {
  const [formValue, setFormValue] = useState(INITIAL_FORM);
  const [peran, setPeran] = useState('Pencari Kerja');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPass, setIsShowPass] = useState(false);
  const { setLocalStorage } = useProfile();

  const { search } = useLocation();

  const onEyeClick = useCallback(() => {
    setIsShowPass(p => !p);
  }, []);

  const formRef = useRef();

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await apiPost('register', {
        fullname: formValue.fullname,
        role: peran,
        email: formValue.email,
        password: formValue.password,
        password_confirmation: formValue.password_confirmation,
      });
      const { data } = result.data;
      Alert.success(`berhasil registrasi ${data.user.email}`);
      setIsLoading(false);
      setLocalStorage(data.token, data.user);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response.data.message, 4000);
    }
  };
  return (
    <div className="container signup">
      <Grid>
        <Row>
          <Col className="cont">
            <div className="left">
              <img src={registImage} alt="rectangle" />
            </div>
            <div className="right">
              <h2 className="fm-inter fw-900">Registrasi sebagai</h2>
              <Form
                fluid
                onChange={onFormChange}
                formValue={formValue}
                model={model}
                ref={formRef}
                onSubmit={onSubmit}
              >
                <FormGroup>
                  <RadioGroup
                    name="peran"
                    inline
                    appearance="picker"
                    defaultValue="Pencari Kerja"
                    value={peran}
                    onChange={value => setPeran(value)}
                  >
                    <Radio value="Pencari Kerja">Pencari Kerja</Radio>
                    <Radio value="Perusahaan">Perusahaan</Radio>
                  </RadioGroup>
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="fullname">Nama Lengkap</ControlLabel>
                  <FormControl
                    id="fullname"
                    placeholder={`Nama Lengkap ${
                      peran === 'Perusahaan'
                        ? 'Penanggung Jawab Perusahaan'
                        : ''
                    }`}
                    name="fullname"
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="email">E-mail</ControlLabel>
                  <InputGroup inside>
                    <InputGroup.Addon>
                      <Icon icon="envelope-o" />
                    </InputGroup.Addon>
                    <FormControl
                      id="email"
                      placeholder="email@uinjkt.ac.id"
                      name="email"
                      type="text"
                    />
                  </InputGroup>
                  <HelpBlock>gunakan email uin jika ada</HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="password">Password</ControlLabel>
                  <InputGroup inside>
                    <InputGroup.Addon>
                      <Icon icon="lock" className="lock" />
                    </InputGroup.Addon>
                    <FormControl
                      placeholder="min. 8 karakter"
                      name="password"
                      type={isShowPass ? 'text' : 'password'}
                      id="password"
                    />
                    <InputGroup.Addon className="pw" onClick={onEyeClick}>
                      <Icon icon={isShowPass ? 'eye' : 'eye-slash'} />
                    </InputGroup.Addon>
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <ControlLabel htmlFor="password_confirmation">
                    Konfirmasi Password
                  </ControlLabel>
                  <InputGroup inside>
                    <InputGroup.Addon>
                      <Icon icon="lock" className="lock" />
                    </InputGroup.Addon>
                    <FormControl
                      placeholder="min. 8 karakter"
                      name="password_confirmation"
                      type={isShowPass ? 'text' : 'password'}
                      id="password_confirmation"
                    />
                    <InputGroup.Addon className="pw" onClick={onEyeClick}>
                      <Icon icon={isShowPass ? 'eye' : 'eye-slash'} />
                    </InputGroup.Addon>
                  </InputGroup>
                </FormGroup>
                <HelpBlock className="mb-3">
                  Dengan mendaftar, saya setuju dengan{' '}
                  <Link to="/tentang/terms" target="_blank">
                    Ketentuan Layanan
                  </Link>
                  {/* <a
                    href="https://glints.com/id/about/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ketentuan Layanan
                  </a> */}
                </HelpBlock>
                <FormGroup>
                  <Button
                    loading={isLoading}
                    block
                    appearance="primary"
                    type="submit"
                    className="wide"
                  >
                    Registrasi
                  </Button>
                </FormGroup>
                <h4 className="fw-600" style={{ marginBottom: '43px' }}>
                  Sudah Memiliki Akun ?&nbsp;
                  <LinkAlt
                    className="fw-700 link-alt"
                    text="Masuk"
                    to={`/signin${search}`}
                  />
                </h4>
              </Form>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default SignUp;
