import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Redirect, Route } from 'react-router';
import { Container, Loader } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import TopPerusahaanNav from '../topNavBar/TopPerusahaanNav';
import FooterAlt from '../footer/FooterAlt';

const PerusahaanRoute = ({ children, ...routeProps }) => {
  const { profile, isLoading } = useProfile();

  if (isLoading && !profile) {
    return (
      <Container>
        <Loader
          center
          inverse
          vertical
          size="md"
          content="loading"
          speed="slow"
        />
      </Container>
    );
  }
  if (!profile) {
    return <Redirect to="/" />;
  }
  if (profile && !isLoading) {
    if (profile.role !== 'Perusahaan') {
      return <Redirect to="/" />;
    }
  }
  return (
    <Route {...routeProps}>
      <TopPerusahaanNav />
      {children}
      <FooterAlt />
    </Route>
  );
};

export default PerusahaanRoute;
