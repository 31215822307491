import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Container, Loader } from 'rsuite';
import { apiGetWithURL } from '../../misc/config';
import image from '../../assets/img/email.png';

const Verify = () => {
  const { search } = useLocation();
  const name = new URLSearchParams(search).get('query');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    apiGetWithURL(name)
      .then(() => {
        Alert.success('berhasil verifikasi email');
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [name]);

  return (
    <Container>
      {isLoading ? (
        <Loader
          center
          inverse
          vertical
          size="md"
          content="loading"
          speed="slow"
        />
      ) : (
        <div className="text-center" style={{ marginTop: 100 }}>
          <img src={image} alt="" />
          <br />
          <a href="/me">back to menu</a>
        </div>
      )}
    </Container>
  );
};

export default Verify;
