import React, { useState } from 'react';
import { Alert, Button, Modal } from 'rsuite';
import alertImg from '../../assets/img/deleteIcon.png';
import { useProfile } from '../../context/profile.context';
import { apiDeleteWithAuth, apiGetWithAuth } from '../../misc/config';

const ModalDelete = ({ del, i }) => {
  const { updateProfile } = useProfile();
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    try {
      setIsLoading(true);
      await apiDeleteWithAuth(`experiences/${i}`);
      const result = await apiGetWithAuth('getMe');

      updateProfile(result.data.data);
      Alert.success('berhasil', 4000);
      setIsLoading(false);
      del.close();
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        className="modalalert"
        backdrop="static"
        show={del.isOpen}
        onHide={del.lose}
        size="xs"
      >
        <Modal.Body className="body">
          <img src={alertImg} alt="" />
          <h4>Apakah Anda yakin ingin menghapus?</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={del.close} appearance="subtle">
            Batal
          </Button>
          <Button
            onClick={submit}
            appearance="primary"
            className="btn-primary2"
            loading={isLoading}
          >
            Ya
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDelete;
