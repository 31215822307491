import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { Alert, Col, Grid, Icon, Input, InputGroup, Row } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import { apiGetWithAuth } from '../../misc/config';
import Card from '../card/Card';
import './lowonganku.style.scss';

const MyLoader = props => (
  <ContentLoader
    speed={2}
    width={300}
    height={300}
    viewBox="0 0 400 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="100" y="8" rx="3" ry="3" width="200" height="16" />
    <rect x="100" y="45" rx="3" ry="3" width="300" height="12" />
    <rect x="0" y="100" rx="3" ry="3" width="410" height="10" />
    <rect x="0" y="140" rx="3" ry="3" width="380" height="10" />
    <rect x="0" y="180" rx="3" ry="3" width="178" height="10" />
    <circle cx="40" cy="40" r="40" />
  </ContentLoader>
);

const Lowonganku = () => {
  const [tagged, setTagged] = useState([]);
  const [lowongan, setLowongan] = useState(null);
  const { profile } = useProfile(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (profile) {
      if (profile.role === 'Pencari Kerja') {
        apiGetWithAuth('job-tagged/my-job-tagged')
          .then(r => {
            setTagged(r.data.data.data.map(d => d.vacancy_id));
          })
          .catch(err => {
            Alert.error(err.response ? err.response.data.message : err.name);
          });
      }
    }

    setIsLoading(true);

    apiGetWithAuth('job-tagged/my-job-tagged')
      .then(r => {
        setLowongan(r.data.data.data);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [profile]);

  const handleAddTag = d => {
    setTagged([...tagged, d]);
  };
  const handleRemoveTag = d => {
    setTagged(tagged.filter(f => f !== d));
  };
  return (
    <div className="container lowonganku">
      <Grid>
        <Row>
          <Col className="top">
            <h2 className="title">Lowongan yang kamu simpan</h2>
            <InputGroup inside>
              <InputGroup.Addon>
                <Icon icon="search" style={{ color: '#737B7D' }} />
              </InputGroup.Addon>
              <Input placeholder="cari lowongan" />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="cards-container"
              style={{
                justifyContent: !lowongan ? 'space-between' : 'flex-start',
              }}
            >
              {isLoading && (
                <>
                  <MyLoader />
                  <MyLoader />
                  <MyLoader />
                </>
              )}
              {!isLoading &&
                lowongan &&
                lowongan.map(d => (
                  <Card
                    key={d.vacancy.id}
                    tagged={tagged}
                    handleAddTag={handleAddTag}
                    handleRemoveTag={handleRemoveTag}
                    id={d.vacancy.id}
                    title={d.vacancy.title}
                    logoImg={d.vacancy.user.company.profile_picture_url}
                    minSalary={d.vacancy.min_salary}
                    maxSalary={d.vacancy.max_salary}
                    slot={d.vacancy.slot}
                    company={d.vacancy.user.company.company_name}
                    statusEndDate={d.vacancy.status_end_date}
                    totalApplier={d.vacancy.total_applier}
                  />
                ))}
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Lowonganku;
