import React from 'react';
import { Route } from 'react-router';
import { Container, Loader } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import FooterAlt from '../footer/FooterAlt';
import TopNavBar from '../topNavBar/TopNavBar';

const BothRoute = ({ children, ...routeProps }) => {
  const { profile, isLoading } = useProfile();
  // authMe();

  if (isLoading && !profile) {
    return (
      <Container>
        <Loader
          center
          inverse
          vertical
          size="md"
          content="loading"
          speed="slow"
        />
      </Container>
    );
  }

  return (
    <Route {...routeProps}>
      <TopNavBar />
      {children}
      <FooterAlt />
    </Route>
  );
};

export default BothRoute;
