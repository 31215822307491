import React, { useState } from 'react';
import { Button, Checkbox, Table } from 'rsuite';

import './custom.style.scss';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useModalState } from '../../misc/custom-hooks';
import ModalDetailPelamar from '../../pages/_company/kelolaPelamar/ModalDetailPelamar';

const { Column, HeaderCell, Cell } = Table;

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <Checkbox
      value={rowData[dataKey]}
      onChange={onChange}
      checked={checkedKeys.some(item => item === rowData[dataKey])}
    />
  </Cell>
);

const CustomColumnTable = ({
  data,
  isLoading,
  next,
  handleAcc,
  handleTolak,
  sortColumn,
  sortType,
  handleSort,
}) => {
  // const [data, setData] = useState(null);
  const { open, isOpen, close } = useModalState();

  const [id, setId] = useState(false);

  const [isAccLoading, setIsAccLoading] = useState(false);
  const [isTolakLoading, setisTolakLoading] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);

  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }

  const handleCheckAll = (value, checkedd) => {
    const checkedKeyss = checkedd ? data.map(item => item.id) : [];
    setCheckedKeys(checkedKeyss);
  };
  const handleCheck = (value, checkedd) => {
    const nextCheckedKeys = checkedd
      ? [...checkedKeys, value]
      : checkedKeys.filter(item => item !== value);

    setCheckedKeys(nextCheckedKeys);
  };

  return (
    <>
      <div className="custom-table">
        <Table
          height={420}
          data={data}
          id="table"
          loading={isLoading}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSort}
          onDataUpdated={() => {
            setCheckedKeys([]);
          }}
          // onRowClick={rowData => {
          //   history.push(`kelola-pelamar/${rowData.id}`, { valid: true });
          // }}
          // bodyRef={ref => {
          //   tableBody = ref;
          // }}
        >
          <Column width={50} align="center" fixed>
            <HeaderCell style={{ padding: 0 }}>
              <Checkbox
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={handleCheck}
            />
          </Column>

          <Column width={70} align="center">
            <HeaderCell>No</HeaderCell>
            <Cell>
              {(rowData, i) => {
                return i + 1;
              }}
            </Cell>
          </Column>
          <Column width={200} sortable>
            <HeaderCell>Nama Lengkap</HeaderCell>
            <Cell dataKey="fullname">
              {rowData => {
                return (
                  <Link
                    onClick={e => {
                      e.preventDefault();
                      setId(rowData.uuid);
                      open();
                    }}
                    to="/"
                  >
                    {rowData.fullname}
                  </Link>
                );
              }}
            </Cell>
          </Column>
          <Column width={250} sortable>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email">
              {rowData => (
                <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
              )}
            </Cell>
          </Column>
          <Column width={150} sortable>
            <HeaderCell>Telepon</HeaderCell>
            <Cell dataKey="phone">{rowData => rowData.phone}</Cell>
          </Column>
          <Column width={300} sortable>
            <HeaderCell>Institusi Pendidikan</HeaderCell>
            <Cell dataKey="institution">{rowData => rowData.institution}</Cell>
          </Column>
          <Column width={150} sortable>
            <HeaderCell>tanggal</HeaderCell>
            <Cell dataKey="created_at">
              {rowData => moment(rowData.created_at).format('Do MMM YYYY')}
            </Cell>
          </Column>
        </Table>
      </div>
      <div className="bot">
        <h4>Pilih aksi untuk ({checkedKeys.length}) Pelamar Terpilih</h4>
        <div className="btns">
          {next && (
            <>
              <Button
                className="green"
                onClick={async () => {
                  if (checkedKeys.length === 0) return;
                  setIsAccLoading(true);
                  await handleAcc(checkedKeys);
                  setIsAccLoading(false);
                }}
                loading={isAccLoading}
                disabled={isAccLoading || isTolakLoading}
              >
                {next}
              </Button>
              <Button
                className="red"
                onClick={async () => {
                  if (checkedKeys.length === 0) return;
                  setisTolakLoading(true);
                  await handleTolak(checkedKeys);
                  setisTolakLoading(false);
                }}
                loading={isTolakLoading}
                disabled={isAccLoading || isTolakLoading}
              >
                Tolak
              </Button>
            </>
          )}
          <Button className="blue" disabled={isAccLoading}>
            Unduh CV .pdf
          </Button>
          <Button className="blue" disabled={isAccLoading}>
            Unduh Data .xls
          </Button>
        </div>
      </div>
      <ModalDetailPelamar isOpen={isOpen} close={close} id={id} />
    </>
  );
};

export default CustomColumnTable;
