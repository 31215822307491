import React, { useState, memo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button, Dropdown, Alert } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import LinkAlt from '../link-alt/LinkAlt';
// import { Button } from 'rsuite';
import './topNavBar.style.scss';
import logo from '../../assets/img/logo-puskarir.svg';

const TopNavBar = () => {
  const { profile, isLoading, removeToken } = useProfile();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();

  React.useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const onLogout = () => {
    removeToken();
    history.push('/signin');
    Alert.info('berhasil logout', 4000);
  };

  // if (token.logout && !profile) {
  //   Alert.info('berhasil logout', 4000);
  // }
  return (
    <div className={`nav ${menuOpen ? 'collapsed' : ''}`}>
      <Link className="logo fm-roboto" to="/">
        <img src={logo} alt="" />
      </Link>
      <div
        className="hamburger"
        onClick={() => setMenuOpen(!menuOpen)}
        onKeyDown={() => setMenuOpen(!menuOpen)}
        role="presentation"
      >
        <span className="line1" />
        <span className="line2" />
        <span className="line3" />
      </div>
      <div className={`collapse ${menuOpen ? 'active' : ''}`}>
        <div className="left">
          <ul>
            <li>
              <Link
                to="/"
                className={`link-nav ${
                  location.pathname === '/' ? 'active' : ''
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/lowongan-kerja"
                className={`link-nav ${
                  location.pathname.startsWith('/lowongan-kerja') ||
                  location.pathname.startsWith('/perusahaan')
                    ? 'active'
                    : ''
                }`}
              >
                Lowongan Kerja
              </Link>
            </li>
            <li>
              <Link
                to="/artikel"
                className={`link-nav ${
                  location.pathname.startsWith('/artikel') ? 'active' : ''
                }`}
              >
                Artikel
              </Link>
            </li>
            <li>
              <Link
                to="/event"
                className={`link-nav ${
                  location.pathname.startsWith('/event') ? 'active' : ''
                }`}
              >
                Event
              </Link>
            </li>
            <li>
              <Link
                to="/tracer-study"
                className={`link-nav ${
                  location.pathname.startsWith('/tracer-study') ? 'active' : ''
                }`}
              >
                Tracer Study
              </Link>
            </li>
            <li>
              <Link
                to="/tentang"
                className={`link-nav ${
                  location.pathname === '/tentang' ? 'active' : ''
                }`}
              >
                Tentang Kami
              </Link>
            </li>
          </ul>
        </div>
        {!profile && (
          <div className="right">
            <span className="rightlink">
              <Link
                to="/signup"
                className={`link-nav ${
                  location.pathname === '/signup' ? 'active' : ''
                }`}
              >
                Daftar
              </Link>
            </span>
            <Link
              to="/signin"
              className={`link-nav ${
                location.pathname === '/signin' ? 'active2' : ''
              }`}
            >
              <Button className="masuk">Masuk</Button>
            </Link>
          </div>
        )}
        {profile && (
          <div className="right">
            {profile.role === 'Pencari Kerja' && (
              <Link
                to="/me"
                className="img-profile"
                style={{
                  backgroundImage: `url(${
                    profile.job_seeker
                      ? profile.job_seeker.profile_picture_url
                      : ''
                  })`,
                }}
              />
            )}
            {profile.role === 'Perusahaan' && (
              <Link
                to="/company"
                className="img-profile"
                style={{
                  backgroundImage: `url(${profile.company.profile_picture_url})`,
                }}
              />
            )}
            <Dropdown
              loading={isLoading}
              title={
                profile.fullname
                  ? profile.fullname.split(' ')[0].toUpperCase()
                  : 'null'
              }
              trigger="click"
              placement="bottomEnd"
            >
              {profile.role === 'Pencari Kerja' && (
                <>
                  <Dropdown.Item
                    componentClass={LinkAlt}
                    to="/me"
                    text="Profil Saya"
                    // active={location.pathname === '/me'}
                    className="link-drop"
                  />
                  <Dropdown.Item
                    componentClass={LinkAlt}
                    to="/lamaranku"
                    text="Lamaran Saya"
                    className="link-drop"
                  />
                  <Dropdown.Item
                    componentClass={LinkAlt}
                    to="/lowonganku"
                    text="Kelola Lowongan Ditandai"
                    className="link-drop"
                  />
                  <Dropdown.Item
                    componentClass={LinkAlt}
                    to="/eventku"
                    text="Kelola Event"
                    className="link-drop"
                  />
                  <Dropdown.Item
                    componentClass={LinkAlt}
                    to="/assesment"
                    text="Assessment"
                    className="link-drop"
                  />
                </>
              )}
              {profile.role === 'Perusahaan' && (
                <>
                  <Dropdown.Item
                    componentClass={LinkAlt}
                    to="/company"
                    text="Dashboard"
                    className="link-drop"
                  />
                </>
              )}
              <Dropdown.Item onClick={onLogout}>
                <button type="button" className="btn-logout">
                  Keluar
                </button>
              </Dropdown.Item>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TopNavBar);
