import React from 'react';
import { Redirect, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Container, Loader } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import FooterAlt from '../footer/FooterAlt';
import TopNavBar from '../topNavBar/TopNavBar';

const PublicRoute = ({ children, ...routeProps }) => {
  const { profile, isLoading } = useProfile();
  const lastLocation = useLocation();
  const redirectTo = new URLSearchParams(lastLocation.search).get('redirectTo');

  if (isLoading && !profile) {
    return (
      <Container>
        <Loader
          center
          inverse
          vertical
          size="md"
          content="loading"
          speed="slow"
        />
      </Container>
    );
  }

  if (profile && !isLoading && redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  if (profile && profile.role === 'Perusahaan') {
    return <Redirect to="/company" />;
  }

  if (profile && !isLoading) {
    return <Redirect to="/" />;
  }

  return (
    <Route {...routeProps}>
      <TopNavBar />
      {children}
      <FooterAlt />
    </Route>
  );
};

export default PublicRoute;
