import React from 'react';
import './label-icon.scss';

const LabelWithIcon = ({ link, name, className, ...props }) => {
  return (
    <div className={`label-icon ${className}`} {...props}>
      <img src={link} alt="" />
      {name}
    </div>
  );
};

export default LabelWithIcon;
