import React, { useEffect } from 'react';
import { Redirect, Switch, withRouter, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import Artikel from './pages/artikel/Artikel';
import Home from './pages/home/Home';
import LowonganKerja from './pages/lowongan-kerja/LowonganKerja';
import LowonganKerjaDetail from './pages/lowongan-kerja/LowonganKerjaDetail';
import Me from './pages/me/Me';
import PerusahaanDetail from './pages/perusahaan/PerusahaanDetail';
import SignIn from './pages/signin/SignIn';
import SignUp from './pages/signup/SignUp';
import './styles/main.scss';
import { ProfileProvider } from './context/profile.context';
import PublicRoute from './components/Routing/PublicRoute';
import PrivateRoute from './components/Routing/PrivateRoute';
import BothRoute from './components/Routing/BothRoute';
import Lamaranku from './pages/lamaranku/Lamaranku';
import ArtikelDetail from './pages/artikel/ArtikelDetail';
import TracerStudy from './pages/tracer-study/TracerStudy';
import EventPage from './pages/event/EventPage';
import PerusahaanRoute from './components/Routing/PerusahaanRoute';
import KelolaLowongan from './pages/_company/KelolaLowongan/KelolaLowongan';
import KelolaPelamar from './pages/_company/kelolaPelamar/KelolaPelamar';
import EventDetail from './pages/event/EventDetail';
import Lowonganku from './components/lowonganku/Lowonganku';
import Eventku from './pages/event/Eventku';
import KelolaProfilePerusahaan from './pages/_company/kelolaprofileperusahaan/KelolaProfilePerusahaan';
import KelolaPelamarDetail from './pages/_company/kelolaPelamar/KelolaPelamarDetail';
import TracerStudySurvey from './pages/tracer-study/TracerStudySurvey';
import TentangKami from './pages/tentang-kami/TentangKami';
import Verify from './pages/verify/Verify';
import KeloalAkun from './pages/_company/kelolaAkun/KeloalAkun';
import CreateLowongan from './pages/_company/KelolaLowongan/CreateLowongan';
import Terms from './pages/terms/Terms';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ProfileProvider>
      <Switch>
        <PublicRoute path="/signin" exact>
          <SignIn />
        </PublicRoute>
        <PublicRoute path="/signup" exact>
          <SignUp />
        </PublicRoute>

        {/* Route umum */}
        <BothRoute path="/" exact>
          <Home />
        </BothRoute>
        <BothRoute path="/lowongan-kerja" exact>
          <LowonganKerja />
        </BothRoute>
        <BothRoute path="/lowongan-kerja/:lowonganId" exact>
          <LowonganKerjaDetail />
        </BothRoute>
        <BothRoute path="/perusahaan/:id" exact>
          <PerusahaanDetail />
        </BothRoute>
        <BothRoute path="/artikel" exact>
          <Artikel />
        </BothRoute>
        <BothRoute path="/event" exact>
          <EventPage />
        </BothRoute>
        <BothRoute path="/event/:alias" exact>
          <EventDetail />
        </BothRoute>
        <BothRoute path="/tracer-study" exact>
          <TracerStudy />
        </BothRoute>
        <BothRoute path="/tracer-study/:alias" exact>
          <TracerStudySurvey />
        </BothRoute>
        <BothRoute path="/artikel/:alias" exact>
          <ArtikelDetail />
        </BothRoute>
        <BothRoute path="/tentang" exact>
          <TentangKami />
        </BothRoute>
        <BothRoute path="/tentang/terms" exact>
          <Terms />
        </BothRoute>
        {/* <BothRoute path="/verify" exact>
          <Verify />
        </BothRoute> */}

        <PrivateRoute path="/me" exact>
          <Me />
        </PrivateRoute>
        <PrivateRoute path="/lamaranku" exact>
          <Lamaranku />
        </PrivateRoute>
        <PrivateRoute path="/eventku" exact>
          <Eventku />
        </PrivateRoute>
        <PrivateRoute path="/lowonganku" exact>
          <Lowonganku />
        </PrivateRoute>

        <PerusahaanRoute path="/company/kelola-lowongan" exact>
          <KelolaLowongan />
        </PerusahaanRoute>
        <PerusahaanRoute path="/company/kelola-lowongan/add" exact>
          <CreateLowongan />
        </PerusahaanRoute>
        <PerusahaanRoute path="/company/kelola-pelamar" exact>
          <KelolaPelamar />
        </PerusahaanRoute>
        <PerusahaanRoute path="/company/kelola-pelamar/:id" exact>
          <KelolaPelamarDetail />
        </PerusahaanRoute>
        <PerusahaanRoute path="/company/kelola-profile" exact>
          <KelolaProfilePerusahaan />
        </PerusahaanRoute>
        <PerusahaanRoute path="/company/kelola-akun" exact>
          <KeloalAkun />
        </PerusahaanRoute>
        <PerusahaanRoute path="/company">
          <Redirect to="/company/kelola-lowongan" />
        </PerusahaanRoute>

        <Route path="/verify" exact>
          <Verify />
        </Route>

        <BothRoute path="*">
          <div className="container">404 NOT FOUND</div>
        </BothRoute>
      </Switch>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <a
        href="https://api.whatsapp.com/send/?phone=62895320298734&text=I%27m+interested+for+Counseling&type=phone_number&app_absent=0"
        className="float"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp" style={{ color: 'white' }} />
      </a>
    </ProfileProvider>
  );
}

export default withRouter(App);
