import React from 'react';
import { AutoComplete, Button, Drawer, Icon, Input, InputGroup } from 'rsuite';
import { useMediaQuery, useModalState } from '../../misc/custom-hooks';

const Search = ({ formAtas, setFormAtas, searchHandler, location }) => {
  const { isOpen, open, close } = useModalState();
  const mobile = useMediaQuery('(max-width : 992px)');
  const miniLaptop = useMediaQuery('(max-width : 1200px)');

  return (
    <>
      {!mobile && (
        <div className="search-input">
          <Input
            className="posisi"
            placeholder="Posisi Lowongan/Perusahaan"
            value={formAtas.title_company}
            onChange={e => {
              setFormAtas({
                ...formAtas,
                title_company: e,
              });
            }}
            onPressEnter={searchHandler}
          />
          <AutoComplete
            className="daerah"
            placeholder="Daerah/Kota"
            data={location}
            value={formAtas.city}
            onChange={e => {
              setFormAtas({
                ...formAtas,
                city: e,
              });
            }}
            onPressEnter={searchHandler}
          />
          <Button className="btn-cari" onClick={searchHandler}>
            Cari
          </Button>
        </div>
      )}
      {mobile && (
        <InputGroup className="mb-3" inside onClick={open}>
          <Input
            placeholder={
              formAtas.title_company === '' ? 'Search' : formAtas.title_company
            }
            disabled
          />
          <InputGroup.Addon>
            <Icon icon="search" />
          </InputGroup.Addon>
        </InputGroup>
      )}
      {miniLaptop && (
        <Drawer full style={{ width: '100%' }} show={isOpen} onHide={close}>
          <Drawer.Header>
            <Drawer.Title>Cari Lowongan</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <Input
              style={{ boxShadow: 'none' }}
              className="posisi mb-3"
              placeholder="Posisi Lowongan/Perusahaan"
              value={formAtas.title_company}
              onChange={e => {
                setFormAtas({
                  ...formAtas,
                  title_company: e,
                });
              }}
              onPressEnter={() => {
                close();
                searchHandler();
              }}
            />
            <AutoComplete
              style={{ boxShadow: 'none' }}
              className="daerah mb-3"
              placeholder="Daerah/Kota"
              data={location}
              value={formAtas.city}
              onChange={e => {
                setFormAtas({
                  ...formAtas,
                  city: e,
                });
              }}
              onPressEnter={() => {
                close();
                searchHandler();
              }}
            />
            <Button
              block
              className="btn-cari"
              onClick={() => {
                close();
                searchHandler();
              }}
            >
              Cari
            </Button>
          </Drawer.Body>
        </Drawer>
      )}
    </>
  );
};

export default Search;
