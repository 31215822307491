import React, { useState } from 'react';
import { Alert, Button, Icon } from 'rsuite';
import { Link } from 'react-router-dom';
import { useModalState } from '../../misc/custom-hooks';
import SpanLabelIcon from '../label-with-icon/SpanLabelIcon';
import ModalAlert from '../modal-alert/ModalAlert';
import './cardlowongan.style.scss';

import watch from '../../assets/icon/watch.svg';
import userCheck from '../../assets/icon/user-check.svg';
import clock from '../../assets/icon/clock.svg';
import { apiDeleteWithAuth } from '../../misc/config';

const CardLowongan = ({ onEdit, d, refresh }) => {
  const { isOpen, open, close } = useModalState();

  const [isLoading, setIsLoading] = useState(false);

  const createdAt = new Date(d.created_at);
  const updatedAt = new Date(d.updated_at);
  const endDate = new Date(d.status_end_date);

  const deleteHandler = async () => {
    try {
      setIsLoading(true);
      await apiDeleteWithAuth(`vacancies/${d.id}`);
      refresh();
      Alert.success('berhasil menghapus');
      setIsLoading(false);
      close();
    } catch (err) {
      Alert.success(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
      close();
    }
  };

  return (
    <div className="card-lowongan">
      <div className={`status ${d.is_active === 1 ? 'aktif' : 'tidak'}`}>{`${
        d.is_active === 1 ? 'Aktif' : 'Tidak Aktif'
      }`}</div>
      <h2 className="title">{d.title}</h2>
      <div className="list1">
        <SpanLabelIcon name={d.vacancy_type} own={watch} />
        <SpanLabelIcon name={d.job_industry.name} icon="gear2" />
        <SpanLabelIcon name={d.job_role.name} icon="suitcase" />
        <SpanLabelIcon name={`${d.total_applier} Pelamar`} own={userCheck} />
        <SpanLabelIcon
          name={`${createdAt.toLocaleDateString(
            'id-ID',
            {}
          )} - ${endDate.toLocaleDateString('id-ID')}`}
          own={clock}
        />
      </div>
      <div className="list3">
        <span className="updated">
          Dibuat {createdAt.toLocaleDateString('id-ID')}{' '}
          &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp; Diupdate{' '}
          {updatedAt.toLocaleDateString('id-ID')}
        </span>
        <div className="crud">
          <button className="btn-transparan btn-a" type="button" onClick={open}>
            <Icon icon="trash2" />
            Delete
          </button>
          <button
            className="btn-transparan btn-a"
            type="button"
            onClick={() => {
              onEdit(d.id);
            }}
          >
            <Icon icon="edit" />
            Edit
          </button>
          <Link
            to={{
              pathname: '/company/kelola-pelamar',
              state: { idlowongan: d.id },
            }}
          >
            <Button appearance="primary" className="btn-crud">
              Kelola Pelamar
            </Button>
          </Link>
        </div>
      </div>
      <ModalAlert
        isOpen={isOpen}
        close={close}
        onClick={deleteHandler}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CardLowongan;
