import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Icon,
} from 'rsuite';
import ConfirmModal from '../../components/modal/ConfirmModal';
import { useProfile } from '../../context/profile.context';
import { apiPostWithAuth } from '../../misc/config';
import { useModalState } from '../../misc/custom-hooks';
import EditCV from '../me/EditCV';

const Lamar = ({ lowonganId }) => {
  const mdState = useModalState();
  const confirmModal = useModalState();

  const { profile, updateProfile } = useProfile();
  const lastLocation = useLocation();
  const [phone, setPhone] = useState('');
  const history = useHistory();
  const [isPhone, setIsPhone] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [applicationLetter, setApplicationLetter] = useState('');

  useEffect(() => {
    if (profile && profile.job_seeker && profile.job_seeker.phone) {
      setPhone(profile.job_seeker.phone);
    }
  }, [profile]);

  const onSubmit = async () => {
    if (!profile.job_seeker.cv_name) {
      Alert.error('cv wajib di upload');
      return;
    }
    try {
      setIsLoading(true);
      const d = {
        vacancy_id: lowonganId,
        application_letter: applicationLetter,
      };
      if (!isPhone) {
        d.phone = phone;
      }
      await apiPostWithAuth('users-vacancies/apply', d);
      Alert.info('berhasil melamar');

      if (!isPhone) {
        updateProfile({ job_seeker: { ...profile.job_seeker, phone } });
      }

      setIsLoading(false);
      history.push('/lamaranku');
    } catch (err) {
      Alert.error(err.response.data.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="upload-field">
        {!profile && (
          <Link
            style={{ textDecoration: 'none' }}
            to={`/signin?redirectTo=${lastLocation.pathname}`}
          >
            <Button block>Login & Lamar</Button>
          </Link>
        )}
        {profile && profile.job_seeker && (
          <Form fluid>
            <EditCV
              profile={profile}
              updateProfile={updateProfile}
              mdState={mdState}
            />
            <FormGroup>
              <ControlLabel>Upload CV*</ControlLabel>
              {profile.job_seeker.cv_name ? (
                <a
                  href={profile.job_seeker.cv_url}
                  target="blank"
                  className="file"
                >
                  <Icon icon="file-text-o" style={{ marginRight: 5 }} />
                  {profile.job_seeker.cv_name}
                </a>
              ) : (
                'Belum upload'
              )}
              <a
                href="/"
                style={{ display: 'block' }}
                onClick={e => {
                  e.preventDefault();
                  mdState.open();
                }}
              >
                Edit
              </a>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Upload Portfolio</ControlLabel>

              {profile.job_seeker.portfolio_name ? (
                <a
                  href={profile.job_seeker.portfolio_url}
                  target="blank"
                  className="file"
                >
                  <Icon icon="file-text-o" style={{ marginRight: 5 }} />
                  {profile.job_seeker.portfolio_name}
                </a>
              ) : (
                'Belum upload'
              )}
              <a
                href="/"
                style={{ display: 'block' }}
                onClick={e => {
                  e.preventDefault();
                  mdState.open();
                }}
              >
                Edit
              </a>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Nomor Telepon</ControlLabel>
              <FormControl
                disabled={isPhone}
                type="text"
                value={phone}
                onChange={e => {
                  setPhone(e);
                }}
              />
              <HelpBlock>
                <Link
                  to="/"
                  onClick={e => {
                    e.preventDefault();
                    setPhone(
                      profile.job_seeker.phone ? profile.job_seeker.phone : ''
                    );
                    setIsPhone(!isPhone);
                  }}
                >
                  {isPhone ? 'Edit' : 'Reset'}
                </Link>
              </HelpBlock>
            </FormGroup>

            <FormGroup>
              <ControlLabel>Surat Lamaran</ControlLabel>
              <FormControl
                rows={5}
                value={applicationLetter}
                onChange={e => {
                  setApplicationLetter(e);
                }}
                name="application_letter"
                componentClass="textarea"
              />
            </FormGroup>
            <FormGroup>
              <ButtonToolbar>
                <Button
                  appearance="default"
                  disabled={isLoading}
                  loading={isLoading}
                  type="submit"
                  onClick={confirmModal.open}
                >
                  Lamar
                </Button>
              </ButtonToolbar>
            </FormGroup>
          </Form>
        )}
      </div>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        close={confirmModal.close}
        onClick={onSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export default Lamar;
