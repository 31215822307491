import React from 'react';
import { Redirect, Route } from 'react-router';
import { Container, Loader } from 'rsuite';
import { useProfile } from '../../context/profile.context';
import FooterAlt from '../footer/FooterAlt';
import TopNavBar from '../topNavBar/TopNavBar';

const PrivateRoute = ({ children, ...routeProps }) => {
  // useProfile mereturn context dan context mereturn apa yang ada di props value
  const { profile, isLoading } = useProfile();

  if (isLoading && !profile) {
    return (
      <Container>
        <Loader
          center
          inverse
          vertical
          size="md"
          content="loading"
          speed="slow"
        />
      </Container>
    );
  }
  if (!profile && !isLoading) {
    return <Redirect to="/signin" />;
  }
  if (profile) {
    if (profile.role !== 'Pencari Kerja') {
      return <Redirect to="/company" />;
    }
  }

  return (
    <Route {...routeProps}>
      <TopNavBar />
      {children}
      <FooterAlt />
    </Route>
  );
};

export default PrivateRoute;
