import React, { useState } from 'react';
import { Grid, Row, Col, Alert, Loader, Icon } from 'rsuite';
import moment from 'moment';
import 'moment/locale/id';
import './me.style.scss';
import ButtonEdit from '../../components/button-edit/ButtonEdit';
import edit2 from '../../assets/icon/edit-gray.svg';
import download from '../../assets/icon/download.svg';
import alertRed from '../../assets/icon/alert-circle.svg';
import CardExp from '../../components/card-exp/CardExp';
import EditInfoDasar from './EditInfoDasar';
import { useProfile } from '../../context/profile.context';
import EditTentangSaya from './EditTentangSaya';
import EditCV from './EditCV';
import edit from '../../assets/icon/edit.svg';

import { apiPostWithAuth } from '../../misc/config';
import { useModalState } from '../../misc/custom-hooks';
import EditSkills from './EditSkills';
import TambahPengalaman from './TambahPengalaman';
import TambahPengalaman2 from './TambahPengalaman2';
import EditPhoto from './EditPhoto';
import GantiPassword from './GantiPassword';

const getAge = dateString => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const Me = () => {
  const { profile, updateProfile } = useProfile();
  const [emailLoading, setEmailLoading] = useState(false);
  moment.locale('id');
  const mdState = useModalState();
  const gp = useModalState();

  return (
    <div className="container me">
      <Grid>
        <Row>
          <Col md={16} className="left">
            <div className="profile">
              <EditPhoto />
              <div className="second">
                <h6 className="title">
                  {profile.fullname ? profile.fullname.toUpperCase() : ''}
                </h6>
                <p>
                  {profile.job_seeker.job
                    ? profile.job_seeker.job.toUpperCase()
                    : 'JOB SEEKER'}
                </p>
                <h6>Nomor telepon</h6>
                <p>{profile.job_seeker.phone || '-'}</p>
                <h6>Email</h6>
                <p>{profile.email}</p>
                <h6>Alamat domisili</h6>
                <p>{profile.job_seeker.city || '-'}</p>
              </div>
              <div className="third">
                <EditInfoDasar
                  profile={profile}
                  updateProfile={updateProfile}
                />
                <h6>Usia</h6>
                <p>{getAge(profile.job_seeker.date_of_birth)} tahun</p>
                <h6>Jenis Kelamin</h6>
                <p>{profile.job_seeker.is_male ? 'Laki-laki' : 'Perempuan'}</p>
                <h6>Institusi Pendidikan</h6>
                <p>{profile.job_seeker.institution || '-'}</p>
              </div>
            </div>
            <div className="detail">
              <div className="card-detail">
                <EditTentangSaya
                  profile={profile}
                  updateProfile={updateProfile}
                />
                <p className="aboutme">{profile.job_seeker.about}</p>
              </div>
              <div className="card-detail">
                <TambahPengalaman
                  data={{
                    type: 'Pendidikan',
                    title: 'Nama Institusi',
                    sub_title: 'Bidang Studi - gelar',
                    date_in: 'Tanggal Mulai',
                    date_out: 'Tanggal Berakhir',
                    p_title: 'Cth: UIN Syarif Hidayatullah Jakarta',
                    p_subtitle: 'Cth: Teknik Informatika - S1',
                  }}
                  mainTitle="Pendidikan "
                  updateProfile={updateProfile}
                />
                {profile.experiences
                  .filter(obj => {
                    return obj.type === 'Pendidikan';
                  })
                  .map((dpk, i) => (
                    <CardExp
                      key={i}
                      exp={dpk}
                      updateProfile={updateProfile}
                      one={dpk.title}
                      two={dpk.sub_title}
                      three={`${moment(dpk.date_in).format(
                        'MMMM YYYY'
                      )} - ${moment(dpk.date_out).format('MMMM YYYY')} (${(
                        dpk.total_months / 12
                      ).toFixed(1)} tahun)`}
                      data={{
                        title: 'Nama Institusi',
                        sub_title: 'Bidang Studi - gelar',
                        date_in: 'Tanggal Mulai',
                        date_out: 'Tanggal Berakhir',
                      }}
                      className="mb-4"
                    />
                  ))}
              </div>
              <div className="card-detail">
                <TambahPengalaman
                  data={{
                    type: 'Pengalaman Kerja',
                    title: 'Posisi - Status',
                    sub_title: 'Perusahaan',
                    date_in: 'Tanggal Mulai',
                    date_out: 'Tanggal Berakhir',
                    p_title: 'Cth: Data Analyst - Internship',
                    p_subtitle: 'Nama Perusahaan',
                  }}
                  mainTitle="Pengalaman Kerja "
                  updateProfile={updateProfile}
                />
                {profile.experiences
                  .filter(obj => {
                    return obj.type === 'Pengalaman Kerja';
                  })
                  .map((dpk, i) => (
                    <CardExp
                      exp={dpk}
                      key={i}
                      updateProfile={updateProfile}
                      one={dpk.title}
                      two={dpk.sub_title}
                      three={`${moment(dpk.date_in).format(
                        'MMMM YYYY'
                      )} - ${moment(dpk.date_out).format('MMMM YYYY')} (${
                        dpk.total_months
                      } bulan)`}
                      data={{
                        title: 'Posisi - Status',
                        sub_title: 'Perusahaan',
                        date_in: 'Tanggal Mulai',
                        date_out: 'Tanggal Berakhir',
                      }}
                      className="mb-4"
                    />
                  ))}
              </div>

              <div className="card-detail">
                <EditSkills profile={profile} updateProfile={updateProfile} />
                {profile.job_seeker.skills
                  ? profile.job_seeker.skills.map((d, i) => (
                      <div className="badge" key={i}>
                        {d}
                      </div>
                    ))
                  : ''}
              </div>
              <div className="card-detail">
                <h2 className="title">
                  CV dan Portfolio{' '}
                  <ButtonEdit own={edit} text="Edit" onClick={mdState.open} />
                </h2>
                <EditCV
                  profile={profile}
                  updateProfile={updateProfile}
                  mdState={mdState}
                  // openByOutSide={openByOutSide}
                />
                <div className="cv">
                  <span>CV</span>
                  {profile.job_seeker.cv_url ? (
                    <a
                      href={profile.job_seeker.cv_url}
                      style={{ fontSize: 13 }}
                      target="blank"
                    >
                      <Icon
                        icon="file-text-o"
                        size="lg"
                        style={{ color: 'blue', marginRight: 10 }}
                      />
                      {profile.job_seeker.cv_name}
                    </a>
                  ) : (
                    'Tidak ada'
                  )}
                </div>
                <div className="cv">
                  <span>Portfolio</span>
                  {profile.job_seeker.portfolio_url ? (
                    <a
                      href={profile.job_seeker.portfolio_url}
                      target="blank"
                      style={{ fontSize: 13 }}
                    >
                      <Icon
                        icon="file-text-o"
                        size="lg"
                        style={{ color: 'blue', marginRight: 10 }}
                      />
                      {profile.job_seeker.portfolio_name}
                    </a>
                  ) : (
                    'Tidak ada'
                  )}
                </div>
              </div>
              <div className="card-detail">
                <TambahPengalaman
                  data={{
                    type: 'Pengalaman Organisasi',
                    title: 'Nama Organisasi / Relawan',
                    sub_title: 'Jabatan Organisasi',
                    date_in: 'Tanggal Mulai',
                    date_out: 'Tanggal Berakhir',
                    p_title: 'Nama organisasi anda',
                    p_subtitle: 'Posisi dalam organisasi',
                  }}
                  mainTitle="Pengalaman Organisasi"
                  updateProfile={updateProfile}
                />
                {profile.experiences
                  .filter(obj => {
                    return obj.type === 'Pengalaman Organisasi';
                  })
                  .map((dpk, i) => (
                    <CardExp
                      key={i}
                      exp={dpk}
                      updateProfile={updateProfile}
                      one={dpk.title}
                      two={dpk.sub_title}
                      three={`${moment(dpk.date_in).format(
                        'MMMM YYYY'
                      )} - ${moment(dpk.date_out).format('MMMM YYYY')} (${
                        dpk.total_months
                      } bulan)`}
                      data={{
                        title: 'Nama Organisasi / Relawan',
                        sub_title: 'Jabatan Organisasi',
                        date_in: 'Tanggal Mulai',
                        date_out: 'Tanggal Berakhir',
                      }}
                      className="mb-4"
                    />
                  ))}
              </div>
              <div className="card-detail">
                <TambahPengalaman2
                  data={{
                    type: 'Prestasi',
                    title: 'Nama Penghargaan',
                    sub_title: 'Penyelenggara',
                    date_in: 'Tahun',
                    p_title: 'Cth: Juara 1 lomba jurnal',
                    p_subtitle: 'Nama Penyelenggara',
                  }}
                  mainTitle="Penghargaan & Prestasi"
                  updateProfile={updateProfile}
                />
                {profile.experiences
                  .filter(obj => {
                    return obj.type === 'Prestasi';
                  })
                  .map((dpk, i) => (
                    <CardExp
                      key={i}
                      exp={dpk}
                      updateProfile={updateProfile}
                      one={dpk.title}
                      two={dpk.sub_title}
                      three={`${moment(dpk.date_in).format('YYYY')}`}
                      data={{
                        title: 'Nama Penghargaan',
                        sub_title: 'Penyelenggara',
                        date_in: 'Tahun',
                      }}
                      className="mb-4"
                    />
                  ))}
              </div>
              <div className="card-detail">
                <TambahPengalaman2
                  data={{
                    type: 'Pengalaman Pelatihan',
                    title: 'Nama Pelatihan',
                    sub_title: 'Penyelenggara',
                    date_in: 'Tahun',
                    p_title: 'Nama pelatihan',
                    p_subtitle: 'Nama penyelenggara',
                  }}
                  mainTitle="Pengalaman Pelatihan"
                  updateProfile={updateProfile}
                />
                {profile.experiences
                  .filter(obj => {
                    return obj.type === 'Pengalaman Pelatihan';
                  })
                  .map((dpk, i) => (
                    <CardExp
                      key={i}
                      exp={dpk}
                      updateProfile={updateProfile}
                      one={dpk.title}
                      two={dpk.sub_title}
                      three={`${moment(dpk.date_in).format('YYYY')}`}
                      data={{
                        title: 'Nama Pelatihan',
                        sub_title: 'Penyelenggara',
                        date_in: 'Tahun',
                      }}
                      className="mb-4"
                    />
                  ))}
              </div>
            </div>
          </Col>
          <Col md={8} className="right">
            <div className="status">
              <h2>Ganti Password</h2>
              <button
                type="button"
                className="btn-pusk btn-ganti"
                onClick={gp.open}
              >
                Ganti Password
                <img src={edit2} alt="" />
              </button>
              <h2>Export CV</h2>
              <button
                type="button"
                onClick={() => {
                  mdState.open();
                }}
                className={`btn-pusk btn-cv ${
                  profile.job_seeker.cv_url ? 'btn-scs' : 'btn-err'
                }`}
              >
                <img
                  src={profile.job_seeker.cv_url ? download : alertRed}
                  alt=""
                />
                {profile.job_seeker.cv_url ? 'Export CV' : 'Belum Export CV'}
              </button>
              <h2>Assessment</h2>
              <button type="button" className="btn-pusk btn-ass btn-err">
                <img src={alertRed} alt="" />
                Belum melakukan assesment
              </button>
              <h2>Verifikasi Email</h2>
              {profile.email_verified_at ? (
                <button type="button" className="btn-pusk btn-email btn-scs">
                  <Icon
                    icon="check-square-o"
                    style={{ marginRight: 10, color: '#33AC30' }}
                    size="2x"
                  />
                  Email Telah diverifikasi
                </button>
              ) : (
                <>
                  <button type="button" className="btn-pusk btn-email btn-err">
                    <img src={alertRed} alt="" />
                    Email anda belum diverifikasi
                  </button>
                  <a
                    href="/"
                    onClick={async e => {
                      e.preventDefault();
                      setEmailLoading(true);
                      try {
                        await apiPostWithAuth(
                          'email/verification-notification'
                        );
                        Alert.success('email verifikasi terkirim', 4000);
                        setEmailLoading(false);
                      } catch (err) {
                        Alert.error(
                          err.response ? err.response.data.message : err.name
                        );
                        setEmailLoading(false);
                      }
                    }}
                  >
                    {emailLoading ? (
                      <Loader style={{ marginTop: 5 }} content="mengirim..." />
                    ) : (
                      'verifikasi sekarang'
                    )}
                  </a>
                </>
              )}
              <GantiPassword gp={gp} />
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Me;
