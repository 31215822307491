import React from 'react';
import { Grid, Row } from 'rsuite';

const Terms = () => {
  return (
    <div className="container tentang-kami">
      <Grid>
        <Row>
          <div className="content">
            <p className="judul">Terms and Condition</p>
            <p className="subjudul">PusKarir UIN Syarif Hidayatullah Jakarta</p>

            {/* <div
                className="card-tentang"
                dangerouslySetInnerHTML={{ __html: data.desc }}
              /> */}
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default Terms;
