/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ContentLoader from 'react-content-loader';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Grid, Row } from 'rsuite';
import './lamaranku.style.scss';
import clock from '../../assets/icon/clock.svg';
import checkSquare from '../../assets/icon/check-square.svg';
import users from '../../assets/icon/users2.svg';
import userMinus from '../../assets/icon/user-minus.svg';
import userCheck from '../../assets/icon/user-check.svg';
import userX from '../../assets/icon/user-x.svg';
import list from '../../assets/icon/list.svg';
import CardLamaranku from '../../components/card-lamaranku/CardLamaranku';
import { apiGetWithAuth } from '../../misc/config';
import { useMediaQuery } from '../../misc/custom-hooks';
import PencarianKosong from './PencarianKosong';

const MyLoader = props => {
  const mobile = useMediaQuery('(max-width : 992px)');

  return (
    <ContentLoader
      height={mobile ? 600 : 1200}
      width="100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="103" y="12" rx="3" ry="3" width="123" height="7" />
      <rect x="102" y="152" rx="3" ry="3" width="171" height="6" />
      <circle cx="44" cy="42" r="38" />
      <circle cx="44" cy="147" r="38" />
      <circle cx="44" cy="251" r="38" />
      <rect x="105" y="117" rx="3" ry="3" width="123" height="7" />
      <rect x="104" y="222" rx="3" ry="3" width="123" height="7" />
      <rect x="105" y="48" rx="3" ry="3" width="171" height="6" />
      <rect x="104" y="257" rx="3" ry="3" width="171" height="6" />
    </ContentLoader>
  );
};
const Lamaranku = () => {
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [status, setStatus] = useState('All');

  useEffect(() => {
    setStatus('All');
    setIsLoading(true);
    apiGetWithAuth('users-vacancies/my-applications')
      .then(r => {
        setData(r.data.data.data);
        setDataFilter(r.data.data.data);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [mounted]);

  const onClickFilter = s => {
    if (isLoading) return;
    setStatus(s);
    if (s === 'All') {
      setMounted(!mounted);
      return;
    }
    setDataFilter(data.filter(f => f.status === s));
  };

  return (
    <div className="container lamaranku">
      <Grid>
        <Row>
          <Col md={6}>
            <div className="filter-side">
              <ul>
                <li
                  className={status === 'All' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('All');
                  }}
                >
                  <img src={list} alt="" width="24" height="24" />
                  All
                </li>
                <li
                  className={status === 'Sedang Diulas' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Sedang Diulas');
                  }}
                >
                  <img src={clock} alt="" width="24" height="24" />
                  Sedang Diulas
                </li>
                <li
                  className={status === 'Diterima' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Diterima');
                  }}
                >
                  <img src={checkSquare} alt="" width="24" height="24" />
                  Diterima
                </li>
                <li
                  className={status === 'Wawancara' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Wawancara');
                  }}
                >
                  <img src={users} alt="" width="24" height="24" />
                  Wawancara
                </li>
                <li
                  className={status === 'Ditawarkan' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Ditawarkan');
                  }}
                >
                  <img src={userMinus} alt="" width="24" height="24" />
                  Ditawarkan
                </li>
                <li
                  className={status === 'Dipekerjakan' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Dipekerjakan');
                  }}
                >
                  <img src={userCheck} alt="" width="24" height="24" />
                  Dipekerjakan
                </li>
                <li
                  className={status === 'Ditolak' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Ditolak');
                  }}
                >
                  <img src={userX} alt="" width="24" height="24" />
                  Ditolak
                </li>
              </ul>
            </div>
          </Col>
          <Col md={18} className="kanan">
            {isLoading && <MyLoader />}

            {!isLoading && dataFilter.length > 0 && (
              <div className="list-lamaranku">
                {dataFilter.map(d => (
                  <CardLamaranku data={d} key={d.id} />
                ))}
              </div>
            )}
            {!isLoading && dataFilter.length === 0 && <PencarianKosong />}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Lamaranku;
