/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Grid, Row } from 'rsuite';
import { useParams, useHistory, Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import SpanLabelIcon from '../../components/label-with-icon/SpanLabelIcon';

import './eventdetail.style.scss';

import mapPin from '../../assets/icon/map-pin-dark.svg';
import calendar from '../../assets/icon/calendar2.svg';
import clock from '../../assets/icon/clock2.svg';
import dollar from '../../assets/icon/dollar-sign2.svg';
import users from '../../assets/icon/users.svg';
import share2 from '../../assets/icon/share-2.svg';
import { apiGet, apiPostWithAuth } from '../../misc/config';
import SkeletonEventDetail from '../../components/loader-skeleton/SkeletonEventDetail';
import { useModalState } from '../../misc/custom-hooks';
import ModalDaftar from './ModalDaftar';
import { useProfile } from '../../context/profile.context';

const EventDetail = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { open, close, isOpen } = useModalState();
  const [isYesLoading, setIsYesLoading] = useState(false);

  const { alias } = useParams();
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    apiGet(`events/${alias}/alias-url`)
      .then(r => {
        setData(r.data.data);
        document.title = `${r.data.data.title} | Puskarir UIN Jakarta`;
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
        history.push('/event');
      });
  }, [alias, history]);

  const handleYes = async () => {
    setIsYesLoading(true);

    try {
      await apiPostWithAuth('users-events/join-event', { event_id: data.id });

      Alert.success('berhasil');
      setIsYesLoading(false);
      close();
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsYesLoading(false);
    }
  };

  const { profile } = useProfile();
  const lastLocation = useLocation();

  return (
    <div className="container eventdetail">
      <Grid>
        {isLoading && <SkeletonEventDetail />}
        {!isLoading && data && (
          <Row>
            <Col md={17}>
              <div className="content">
                <img src={data.image_url} alt="" className="img-cover" />

                <h2 className="title-event">{data.title}</h2>
                <hr />

                <h3 className="subtitle">Deskripsi</h3>
                <div className="main-content">
                  <div dangerouslySetInnerHTML={{ __html: data.desc }} />
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className="kanan">
                <h4>Detail informasi</h4>
                <SpanLabelIcon
                  own={mapPin}
                  name={data.address_type}
                  className="label"
                />
                <SpanLabelIcon
                  own={calendar}
                  name={moment(data.date).format('dddd, DD MMMM YYYY')}
                  className="label"
                />
                <SpanLabelIcon own={clock} name={data.time} className="label" />
                {/* <SpanLabelIcon own={userCheck} name={data.time} className="label" /> */}
                <SpanLabelIcon
                  own={dollar}
                  name={data.price || 'Free'}
                  className="label"
                />
                <div className="daftar">
                  {profile && profile.role === 'Pencari Kerja' && (
                    <>
                      {moment().isAfter(data.date) ? (
                        <span className="label-danger">acara sudah lewat</span>
                      ) : (
                        <Button onClick={open}>Daftar Sekarang</Button>
                      )}
                    </>
                  )}

                  {!profile && (
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/signin?redirectTo=${lastLocation.pathname}`}
                    >
                      <Button block>Login & Daftar</Button>
                    </Link>
                  )}
                  <img
                    src={share2}
                    alt=""
                    style={{ cursor: 'pointer' }}
                    onClick={e => {
                      e.preventDefault();
                      navigator.clipboard.writeText(window.location.href);
                      Alert.success('copied');
                    }}
                  />
                </div>
                <div className="footer-card">
                  <img src={users} alt="" className="users-svg" />
                  <span>
                    {data.participants || 0} orang Mengikuti Event ini
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Grid>
      <ModalDaftar
        close={close}
        isOpen={isOpen}
        isLoading={isYesLoading}
        onClick={handleYes}
      />
    </div>
  );
};

export default EventDetail;
