import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  TagPicker,
  Modal,
} from 'rsuite';

import ButtonEdit from '../../components/button-edit/ButtonEdit';
import edit from '../../assets/icon/edit.svg';
import { useModalState } from '../../misc/custom-hooks';
import { apiPostWithAuth } from '../../misc/config';

const EditSkills = ({ profile, updateProfile }) => {
  const { isOpen, open, close } = useModalState();
  const [skills, setSkils] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSkills, setDataSkills] = useState([]);

  useEffect(() => {
    if (profile.job_seeker.skills) {
      setSkils(profile.job_seeker.skills);
      setDataSkills(
        profile.job_seeker.skills.map(d => {
          return { label: d, value: d };
        })
      );
    }
  }, [profile.job_seeker.skills]);

  const onUploadClick = async () => {
    try {
      setIsLoading(true);

      const updated = await apiPostWithAuth('users/update-me', {
        job_seeker: {
          skills,
        },
      });
      updateProfile({ ...updated.data.data });

      Alert.success('berhasil');

      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
    }
  };

  return (
    <>
      <h2 className="title">
        Skills <ButtonEdit own={edit} text="Edit" onClick={open} />
      </h2>
      <Modal
        show={isOpen}
        onHide={() => {
          setSkils(profile.job_seeker.skills);
          close();
        }}
        size="md"
      >
        <Modal.Header>
          <Modal.Title>Edit Skill saya</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            // onChange={onFormChange}
            // formValue={formValue}
            // model={model}
            // ref={formRef}
            // onSubmit={onSubmit}
            style={{ paddingRight: 10 }}
          >
            <FormGroup>
              <ControlLabel htmlFor="skills">Skill</ControlLabel>
              <FormControl
                creatable
                name="skills"
                accepter={TagPicker}
                data={dataSkills}
                block
                value={skills}
                onChange={d => {
                  setSkils(d);
                }}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            onClick={onUploadClick}
            disabled={isLoading}
            loading={isLoading}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditSkills;
