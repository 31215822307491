import React, { useCallback, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Icon,
  InputGroup,
  Row,
  Schema,
} from 'rsuite';
import { useLocation } from 'react-router-dom';
import LinkAlt from '../../components/link-alt/LinkAlt';
import { useProfile } from '../../context/profile.context';
import { apiPost } from '../../misc/config';
import ForgotPassword from './ForgotPassword';
import './signin.style.scss';

import loginImg from '../../assets/img/login-img.png';

const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType()
    .isRequired('email harus diiisi')
    .isEmail('harap masukkan email yang valid'),
  password: StringType()
    .isRequired('Password harus diisi')
    .minLength(8, 'min 8 karakter'),
});

const INITIAL_FORM = {
  email: '',
  password: '',
};

const SignIn = () => {
  const [formValue, setFormValue] = useState(INITIAL_FORM);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPass, setIsShowPass] = useState(false);
  const { setLocalStorage } = useProfile();

  const { search } = useLocation();

  const onEyeClick = useCallback(() => {
    setIsShowPass(p => !p);
  }, []);

  const formRef = useRef();

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await apiPost('login', {
        email: formValue.email,
        password: formValue.password,
      });
      const { data } = result.data;
      Alert.success(`berhasil login ${data.user.email}`);
      setIsLoading(false);
      setLocalStorage(data.token, data.user);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
    }
  };

  return (
    <div className="container signin">
      <Grid>
        <Row>
          <Col className="cont">
            <div className="left">
              <img src={loginImg} alt="rectangle" />
            </div>
            <div className="right">
              <h2 className="fm-inter fw-900">Masuk</h2>

              <Form
                fluid
                onChange={onFormChange}
                formValue={formValue}
                model={model}
                ref={formRef}
                onSubmit={onSubmit}
              >
                <FormGroup>
                  <ControlLabel htmlFor="email">E-mail</ControlLabel>
                  <InputGroup inside>
                    <InputGroup.Addon>
                      <Icon icon="envelope-o" />
                    </InputGroup.Addon>
                    <FormControl
                      id="email"
                      placeholder="email@uinjkt.ac.id"
                      name="email"
                      type="text"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="password">Password</ControlLabel>
                  <InputGroup inside>
                    <InputGroup.Addon>
                      <Icon icon="lock" />
                    </InputGroup.Addon>
                    <FormControl
                      placeholder="min. 8 karakter"
                      name="password"
                      type={isShowPass ? 'text' : 'password'}
                      id="password"
                    />
                    <InputGroup.Addon className="pw" onClick={onEyeClick}>
                      <Icon icon={isShowPass ? 'eye' : 'eye-slash'} />
                    </InputGroup.Addon>
                  </InputGroup>
                </FormGroup>
                <Row className="mb-3">
                  <Col md={12}>
                    <FormGroup>
                      <Checkbox value="remember">
                        <span className="fw-600">Remember me</span>
                      </Checkbox>
                    </FormGroup>
                  </Col>
                  <Col md={12} className="text-right">
                    <ForgotPassword />
                  </Col>
                </Row>
                <FormGroup>
                  <Button
                    loading={isLoading}
                    block
                    appearance="primary"
                    type="submit"
                    className="wide"
                  >
                    Masuk
                  </Button>
                </FormGroup>
              </Form>
              <h4 className="fw-600 mt-3" style={{ marginBottom: '43px' }}>
                Belum menjadi member ?&nbsp;
                <LinkAlt
                  className="fw-700 link-alt"
                  text="Sign Up"
                  to={`/signup${search}`}
                />
              </h4>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default SignIn;
