import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'rsuite';
import { apiDeleteWithAuth } from '../../misc/config';
import { useModalState } from '../../misc/custom-hooks';
import ModalEvent from '../modal-event.js/ModalEvent';

import './cardeventku.style.scss';

const CardEventku = ({ data, onClickFilter }) => {
  let color = 'bg-blue';

  const { open, isOpen, close } = useModalState();
  const [isLoading, setIsLoading] = useState(false);

  if (data.status === 'Menunggu Persetujuan') {
    color = 'bg-yellow';
  }
  if (data.status === 'Ditolak') {
    color = 'bg-red';
  }
  if (data.status === 'Dibatalkan') {
    color = 'bg-red';
  }
  if (data.status === 'Diterima') {
    color = 'bg-green';
  }

  const batalkanHandler = async () => {
    try {
      setIsLoading(true);
      // console.log(data);
      await apiDeleteWithAuth('users-events/unjoin-event', {
        ids: [data.event_id],
      });
      Alert.success('berhasil membatalkan event', 4000);
      setIsLoading(false);
      onClickFilter('All');
      close();
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
      close();
    }
  };

  return (
    <>
      <div className="card-eventku">
        <div className="kiri">
          <img src={data.event.image_url} alt="" width="50" />
          <div className="kirii">
            <Link to={`event/${data.event.alias_url}`}>
              <h3>{data.event.title}</h3>
            </Link>
            <h4>{`${data.event.address_type} ( ${data.event.address} )`}</h4>
            {data.event.is_cancel ? (
              <h5 style={{ color: 'red' }}>acara dibatalkan penyelenggara</h5>
            ) : (
              <h5>
                Diselenggarakan pada{' '}
                {moment(data.event.date).format('Do MMMM YYYY')}
                {', '}
                {data.event.time}
              </h5>
            )}
            {data.status === 'Menunggu Persetujuan' && !data.event.is_cancel && (
              <button type="button" className="btn-danger" onClick={open}>
                batalkan
              </button>
            )}
          </div>
        </div>
        {!data.event.is_cancel && (
          <div className={`badge ${color}`}>{data.status}</div>
        )}
      </div>
      <hr />
      <ModalEvent
        isOpen={isOpen}
        close={close}
        onClick={batalkanHandler}
        isLoading={isLoading}
      />
    </>
  );
};

export default CardEventku;
