import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  TagPicker,
  InputPicker,
  Radio,
  RadioGroup,
  Schema,
  Alert,
  AutoComplete,
  Col,
  Grid,
  Row,
} from 'rsuite';

import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';

import { apiGet, apiGetWithURL, apiPostWithAuth } from '../../../misc/config';

const data = [
  {
    label: 'Kurang dari setahun',
    value: 'Kurang dari setahun',
  },
  {
    label: '1-3 tahun',
    value: '1-3 tahun',
  },
  {
    label: '3-5 tahun',
    value: '3-5 tahun',
  },
  {
    label: '5-9 tahun',
    value: '5-9 tahun',
  },
  {
    label: 'Lebih dari 10 tahun',
    value: 'Lebih dari 10 tahun',
  },
];

const dataEdu = [
  {
    value: 'SMA Sederajat',
    label: 'SMA Sederajat',
  },
  {
    value: 'S1',
    label: 'S1',
  },
  {
    value: 'S2',
    label: 'S2',
  },
  {
    value: 'S3',
    label: 'S3',
  },
];

const dataSkill = [];

const vacType = [
  {
    value: 'Fulltime',
    label: 'Fulltime',
  },
  {
    value: 'Parttime',
    label: 'Parttime',
  },
  {
    value: 'Internship',
    label: 'Internship',
  },
  {
    value: 'Freelance',
    label: 'Freelance',
  },
];

const { StringType, NumberType, DateType, ArrayType } = Schema.Types;

const model = Schema.Model({
  title: StringType().isRequired('required'),
  lokasi: StringType().isRequired('required'),
  vacancy_type: StringType().isRequired('required'),
  job_industry_id: NumberType().isRequired('required'),
  job_role_id: NumberType().isRequired('required'),
  status_end_date: DateType().isRequired('required'),
  slot: NumberType().isRequired('required'),
  work_experience: StringType().isRequired('required'),
  latest_education: StringType().isRequired('required'),
  primary_skills: ArrayType().isRequired('required'),
  benefits: ArrayType().isRequired('required'),
  min_salary: NumberType().isRequired('required'),
  max_salary: NumberType().isRequired('required'),
  is_remote: NumberType().isRequired('required'),
  desc: StringType().isRequired('required'),
  is_active: NumberType().isRequired('required'),
});

const INITIAL_FORM = {
  title: '',
  vacancy_type: '',
  job_industry_id: '',
  job_role_id: '',
  status_end_date: null,
  primary_skills: [],
  benefits: [],
  slot: '',
  work_experience: '',
  latest_education: '',
  lokasi: '',
  min_salary: '',
  max_salary: '',
  desc: '',
  is_remote: 0,
  is_active: 1,
};
const CreateLowongan = () => {
  const [formValue, setFormValue] = useState(INITIAL_FORM);
  const [industries, setIndustries] = useState([]);
  const [role, setRole] = useState([]);
  const [loadRole, setLoadRole] = useState(false);
  const [location, setLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);

  const history = useHistory();

  const formRef = useRef();
  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  useEffect(() => {
    setRoleLoading(true);
    apiGet('job-industries').then(r => {
      setIndustries(
        r.data.data.data.map(e => {
          return { label: e.name, value: e.id };
        })
      );
    });
    const tempLocation = [];
    apiGetWithURL(
      'https://jabbar-hafizh.github.io/api-wilayah-indonesia/api/provinces.json'
    ).then(result => {
      const provinsi = result.data;

      provinsi.forEach(p => {
        apiGetWithURL(
          `https://jabbar-hafizh.github.io/api-wilayah-indonesia/api/regencies/${p.id}.json`
        ).then(r => {
          r.data.forEach(kota => {
            tempLocation.push(`${kota.name}, ${p.name}`);
          });
        });
      });
      setLocation(tempLocation);
    });
    setRoleLoading(false);
  }, []);

  const onIndustriChange = async e => {
    setLoadRole(true);
    try {
      const r = await apiGet(`job-roles?job_industry_id=${e}`);
      setRole(
        r.data.data.data.map(m => {
          return { label: m.name, value: m.id };
        })
      );
      setLoadRole(false);
    } catch (err) {
      Alert.error(err.mesage);
      setLoadRole(false);
    }
  };

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    const city = formValue.lokasi.split(',')[0];
    const province = formValue.lokasi.split(',')[1];

    const d = {
      ...formValue,
      city: city ? city.trim() : '',
      province: province ? province.trim() : '',
      country: 'Indonesia',
    };
    delete d.lokasi;
    d.status_end_date = moment(d.status_end_date).format('YYYY-MM-DD');
    try {
      await apiPostWithAuth('vacancies/my-vacancies', d);
      Alert.success('data berhasil ditambahkan', 4000);
      setFormValue(INITIAL_FORM);
      history.push('/company/kelola-lowongan');
      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
      setIsLoading(false);
    }
  };

  return (
    <div className="container kelola-lowongan">
      <Grid>
        <Row>
          <Col md={6} className="kiri">
            <div className="filter-side">
              <Link to="/company/kelola-lowongan">
                <Button
                  componentClass="li"
                  classPrefix
                  className="active"
                  type="button"
                >
                  Back
                </Button>
              </Link>
            </div>
          </Col>

          <Col md={18} className="kanan">
            <div className="create-lowongan">
              {roleLoading && <span>loading...</span>}
              <h2 className="title">Form Tambah Lowongan</h2>
              <Form
                fluid
                onChange={onFormChange}
                formValue={formValue}
                model={model}
                ref={formRef}
                onSubmit={onSubmit}
              >
                <FormGroup>
                  <ControlLabel htmlFor="title">Nama Pekerjaan</ControlLabel>
                  <FormControl
                    id="title"
                    placeholder="Nama Pekerjaan"
                    name="title"
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="jenis">Jenis Lowongan</ControlLabel>
                  <FormControl
                    id="jenis"
                    block
                    placeholder="Pilih Jenis Lowongan"
                    name="vacancy_type"
                    accepter={InputPicker}
                    data={vacType}
                    cleanable={false}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="job_industry_id">
                    Industri Pekerjaan
                  </ControlLabel>
                  <FormControl
                    block
                    placeholder="Pilih Industri Pekerjaan"
                    name="job_industry_id"
                    accepter={InputPicker}
                    data={industries}
                    cleanable={false}
                    onChange={onIndustriChange}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="job_role_id">
                    Peran Pekerjaan
                  </ControlLabel>
                  <FormControl
                    block
                    loading={loadRole}
                    placeholder="Pilih Peran Pekerjaan"
                    id="job_role_id"
                    name="job_role_id"
                    accepter={InputPicker}
                    data={role}
                    cleanable={false}
                    disabled={loadRole}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="status_end_date">
                    Tanggal Tutup
                  </ControlLabel>
                  <FormControl
                    block
                    id="status_end_date"
                    name="status_end_date"
                    accepter={DatePicker}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="slot">Batas Pelamar</ControlLabel>
                  <FormControl
                    id="slot"
                    name="slot"
                    type="number"
                    placeholder="Batas Pelamar"
                    max="100000"
                    min="0"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Minimal Pengalaman Kerja</ControlLabel>
                  <FormControl
                    block
                    placeholder="Pilih minimal pengalaman kerja"
                    name="work_experience"
                    accepter={InputPicker}
                    data={data}
                    cleanable={false}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Pendidikan Terakhir</ControlLabel>
                  <FormControl
                    block
                    placeholder="Pilih pendidikan terakhir"
                    name="latest_education"
                    accepter={InputPicker}
                    data={dataEdu}
                    cleanable={false}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Skill yang dibutuhkan</ControlLabel>
                  <FormControl
                    creatable
                    name="primary_skills"
                    accepter={TagPicker}
                    data={dataSkill}
                    block
                    // disabled={disabled}
                    // readOnly={readOnly}
                    // plaintext={plaintext}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Benefits</ControlLabel>
                  <FormControl
                    creatable
                    name="benefits"
                    accepter={TagPicker}
                    block
                    // disabled={disabled}
                    // readOnly={readOnly}
                    // plaintext={plaintext}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="min_salary">Minimum Gaji</ControlLabel>
                  <InputGroup>
                    <InputGroup.Addon>RP </InputGroup.Addon>
                    <FormControl
                      id="min_salary"
                      placeholder="masukkan gaji"
                      name="min_salary"
                      min={0}
                      step={100000}
                      type="number"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="max_salary">
                    Maksimum Gaji
                  </ControlLabel>
                  <InputGroup>
                    <InputGroup.Addon>RP </InputGroup.Addon>
                    <FormControl
                      id="max_salary"
                      placeholder="masukkan gaji"
                      step={100000}
                      name="max_salary"
                      type="number"
                      min={0}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <ControlLabel htmlFor="lokasi">Lokasi Lowongan</ControlLabel>
                  <InputGroup>
                    <FormControl
                      id="lokasi"
                      accepter={AutoComplete}
                      data={location}
                      placeholder="kota, provinsi"
                      name="lokasi"
                      type="text"
                      autoComplete="off"
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Pekerjaan Remote</ControlLabel>
                  <FormControl
                    name="is_remote"
                    accepter={RadioGroup}
                    inline
                    // disabled={disabled}
                    // readOnly={readOnly}
                    // plaintext={plaintext}
                  >
                    <Radio value={0}>Tidak</Radio>
                    <Radio value={1}>Ya, Remote</Radio>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Deskripsi</ControlLabel>
                  <FormControl rows={5} name="desc" componentClass="textarea" />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Aktif/Non Aktif Publikasi</ControlLabel>
                  <FormControl name="is_active" accepter={RadioGroup} inline>
                    <Radio value={1}>Aktif</Radio>
                    <Radio value={0}>Tidak Aktif</Radio>
                  </FormControl>
                </FormGroup>
                <FormGroup>
                  <Button
                    block
                    type="submit"
                    loading={isLoading}
                    style={{ height: 45, fontSize: 16, fontWeight: 'bold' }}
                  >
                    {!isLoading && 'Simpan'}
                  </Button>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default CreateLowongan;
