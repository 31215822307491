import React from 'react';
import ContentLoader from 'react-content-loader';
import { Col, Row } from 'rsuite';

const SkeletonDataEvent = props => {
  return (
    <>
      <Row>
        <Col md={12}>
          <ContentLoader
            speed={2}
            width="100%"
            height={250}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="100%" height="150" />
            <rect x="10" y="170" rx="0" ry="0" width="100%" height="20" />
            <rect x="10" y="200" rx="0" ry="0" width="100%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={250}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="100%" height="150" />
            <rect x="10" y="170" rx="0" ry="0" width="100%" height="20" />
            <rect x="10" y="200" rx="0" ry="0" width="100%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={250}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="100%" height="150" />
            <rect x="10" y="170" rx="0" ry="0" width="100%" height="20" />
            <rect x="10" y="200" rx="0" ry="0" width="100%" height="20" />
          </ContentLoader>
        </Col>
        <Col md={12}>
          <ContentLoader
            speed={2}
            width="100%"
            height={250}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="100%" height="150" />
            <rect x="10" y="170" rx="0" ry="0" width="100%" height="20" />
            <rect x="10" y="200" rx="0" ry="0" width="100%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={250}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="100%" height="150" />
            <rect x="10" y="170" rx="0" ry="0" width="100%" height="20" />
            <rect x="10" y="200" rx="0" ry="0" width="100%" height="20" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width="100%"
            height={250}
            // viewBox="0 0 100% 160"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="10" y="4" rx="0" ry="0" width="100%" height="150" />
            <rect x="10" y="170" rx="0" ry="0" width="100%" height="20" />
            <rect x="10" y="200" rx="0" ry="0" width="100%" height="20" />
          </ContentLoader>
        </Col>
      </Row>
    </>
  );
};

export default SkeletonDataEvent;
