import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Alert, Button, ControlLabel, FormGroup, Modal } from 'rsuite';
import { useProfile } from '../../../context/profile.context';
import { apiPostWithAuth } from '../../../misc/config';
import { useModalState } from '../../../misc/custom-hooks';

const acceptedFileTypes = ['image/png', 'image/jpeg', 'image/pjpeg'];

const isValidFile = file => acceptedFileTypes.includes(file.type);

const getBlob = canvas => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        resolve(blob);
      } else {
        reject(new Error('File Process Error'));
      }
    });
  });
};

const UploadFoto = () => {
  const { isOpen, open, close } = useModalState();

  const { profile, updateProfile } = useProfile();
  const avatarEditorRef = useRef();
  const [img, setImg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFileInputChange = e => {
    const currentFiles = e.target.files;

    if (currentFiles.length === 1) {
      const file = currentFiles[0];

      if (isValidFile(file)) {
        setImg(file);
        open();
      } else {
        Alert.warning(`Wrong file type ${file.type}`, 4000);
      }
    }
  };

  const onUploadClick = async () => {
    const canvas = avatarEditorRef.current.getImageScaledToCanvas();
    setIsLoading(true);

    try {
      const blob = await getBlob(canvas);
      const formData = new FormData();
      formData.append('profile_picture_name', blob, 'test.jpg');

      const result = await apiPostWithAuth(`users/update-me`, formData);

      setIsLoading(false);
      updateProfile(result.data.data);
      Alert.info('Avatar has been uploaded', 4000);
      close();
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
    }
  };

  const inputRef = useRef();

  return (
    <FormGroup>
      <ControlLabel>Foto Perusahaan</ControlLabel>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={profile.company.profile_picture_url}
          alt=""
          width={100}
          style={{ borderRadius: '50%' }}
        />
        <div className="ml-3">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          {/* <label htmlFor="profile-upload" style={{ color: 'white' }}> */}
          <Button
            appearance="primary"
            className="btn-upload-banner"
            onClick={() => {
              inputRef.current.click();
            }}
          >
            Browse
          </Button>
          {/* </label> */}
          <input
            type="file"
            ref={inputRef}
            id="profile-upload"
            className="d-none"
            accept=".png, .jpg, .jpeg"
            onChange={onFileInputChange}
          />
          <p className="text-gray">
            Acceptable formats: .jpg, .jpeg, .png <br /> Recommended size: 120px
            x 120px
          </p>
        </div>
      </div>
      <Modal show={isOpen} onHide={close} size="xs">
        <Modal.Header>
          <Modal.Title>Adjust and upload new Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center h-100">
            {img && (
              <AvatarEditor
                ref={avatarEditorRef}
                image={img}
                width={200}
                height={200}
                border={5}
                borderRadius={100}
                rotate={0}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            appearance="ghost"
            onClick={onUploadClick}
            disabled={isLoading}
          >
            Upload new photo
          </Button>
        </Modal.Footer>
      </Modal>
    </FormGroup>
  );
};

export default UploadFoto;
