import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  AutoComplete,
  Button,
  ButtonToolbar,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Grid,
  Row,
  Schema,
} from 'rsuite';

import { Element, Link } from 'react-scroll';
import './kelola-profile-perusahaan.scss';
import { useProfile } from '../../../context/profile.context';

import users from '../../../assets/icon/users2.svg';
import map from '../../../assets/icon/map.svg';
import link from '../../../assets/icon/link.svg';
import fileText from '../../../assets/icon/file-text.svg';
import { apiGetWithURL, apiPostWithAuth } from '../../../misc/config';
import UploadBanner from './UploadBanner';
import UploadFoto from './UploadFoto';
import UploadLegality from './UploadLegality';

const { StringType } = Schema.Types;

const model = Schema.Model({
  company_name: StringType().isRequired('nama perusahaan harus diiisi'),
  company_industry: StringType().isRequired('Industri Perusahaan harus diisi'),
  company_type: StringType().isRequired('Jenis Perusahaan harus diisi'),
  desc: StringType().isRequired('Deskripsi harus diisi'),
  total_employee: StringType().isRequired('Total Pegawai harus diisi'),
  company_address: StringType().isRequired('Alamat harus diisi'),
  location: StringType().isRequired('Lokasi harus diisi'),
  phone: StringType().isRequired('nomor telepon harus diisi'),
  website_url: StringType()
    .isRequired('website url harus diisi')
    .isURL('Harap Masukkan URL yang valid'),
  facebook_url: StringType()
    .isRequired('website url harus diisi')
    .isURL('Harap Masukkan URL yang valid'),
  instagram_url: StringType()
    .isRequired('website url harus diisi')
    .isURL('Harap Masukkan URL yang valid'),
  twitter_url: StringType()
    .isRequired('website url harus diisi')
    .isURL('Harap Masukkan URL yang valid'),
  linked_in_url: StringType()
    .isRequired('website url harus diisi')
    .isURL('Harap Masukkan URL yang valid'),
});

const INIT_CITY = [
  'Jakarta Selatan, Jakarta',
  'Tangerang Selatan, Banten',
  'Bogor, Jawa Barat',
  'Malang, Jawa Timur',
];

const INITIAL_FORM = {
  company_name: '',
  company_industry: '',
  company_type: '',
  desc: '',
  total_employee: '',
  company_address: '',
  location: '',
  phone: '',
  linked_in_url: '',
  instagram_url: '',
  twitter_url: '',
  facebook_url: '',
  website_url: '',
  nib: '',
};
const KelolaProfilePerusahaan = () => {
  const { profile, updateProfile } = useProfile(null);

  const [isLoading, setIsLoading] = useState(false);
  const [initForm, setInitForm] = useState(INITIAL_FORM);
  const [formValue, setFormValue] = useState(INITIAL_FORM);
  const [location, setLocation] = useState(INIT_CITY);
  const [active, setActive] = useState(1);

  const formRef = useRef();

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  useEffect(() => {
    setInitForm({
      company_name: profile.company.company_name || '',
      company_industry: profile.company.company_industry || '',
      company_type: profile.company.company_type || '',
      desc: profile.company.desc || '',
      total_employee: profile.company.total_employee || '',
      company_address: profile.company.company_address || '',
      location: `${profile.company.company_city || ''}, ${
        profile.company.company_province || ''
      }`,
      phone: profile.company.phone || '',
      linked_in_url: profile.company.linked_in_url || '',
      instagram_url: profile.company.instagram_url || '',
      twitter_url: profile.company.twitter_url || '',
      facebook_url: profile.company.facebook_url || '',
      website_url: profile.company.website_url || '',
      nib: profile.company.nib || '',
    });
    setFormValue({
      company_name: profile.company.company_name || '',
      company_industry: profile.company.company_industry || '',
      company_type: profile.company.company_type || '',
      desc: profile.company.desc || '',
      total_employee: profile.company.total_employee || '',
      company_address: profile.company.company_address || '',
      location: `${profile.company.company_city || ''}, ${
        profile.company.company_province || ''
      } `,
      phone: profile.company.phone || '',
      linked_in_url: profile.company.linked_in_url || '',
      instagram_url: profile.company.instagram_url || '',
      twitter_url: profile.company.twitter_url || '',
      facebook_url: profile.company.facebook_url || '',
      website_url: profile.company.website_url || '',
      nib: profile.company.nib || '',
    });

    const tempLocation = [];
    apiGetWithURL(
      'https://jabbar-hafizh.github.io/api-wilayah-indonesia/api/provinces.json'
    ).then(result => {
      const provinsi = result.data;

      provinsi.forEach(p => {
        apiGetWithURL(
          `https://jabbar-hafizh.github.io/api-wilayah-indonesia/api/regencies/${p.id}.json`
        ).then(r => {
          r.data.forEach(kota => {
            tempLocation.push(`${kota.name}, ${p.name}`);
          });
        });
      });
      setLocation(tempLocation);
    });
  }, [profile]);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    const city = formValue.location.split(',')[0];
    const prov = formValue.location.split(',')[1];

    const dataUpdated = { ...formValue };

    delete dataUpdated.location;
    dataUpdated.company_city = city ? city.trim() : '';
    dataUpdated.company_province = prov ? prov.trim() : '';

    try {
      const result = await apiPostWithAuth(`users/update-me`, {
        company: dataUpdated,
      });
      const { data } = result.data;
      updateProfile(data);

      Alert.success(`berhasil menyimpan`);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
    }
  };

  return (
    <div className="container kpp">
      <Grid>
        <Row className="top">
          <Col md={24}>
            <UploadBanner />
          </Col>
        </Row>
        <Row>
          <Col
            xsHidden
            smHidden
            md={7}
            style={{ position: 'sticky', top: 100 }}
          >
            <div className="filter-side mb-3">
              <ul>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="informasi"
                  spy
                  smooth
                  offset={-100}
                  duration={500}
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setActive(1);
                  }}
                >
                  <li className={active === 1 ? 'active' : ''}>
                    <img src={users} alt="" width="24" height="24" />
                    Informasi Dasar
                  </li>
                </Link>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  to="alamat"
                  spy
                  smooth
                  offset={-100}
                  duration={500}
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setActive(2);
                  }}
                >
                  <li className={active === 2 ? 'active' : ''}>
                    <img src={map} alt="" width="24" height="24" />
                    Alamat dan Lokasi
                  </li>
                </Link>
                <Link
                  to="link"
                  spy
                  smooth
                  offset={-100}
                  duration={500}
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setActive(3);
                  }}
                >
                  <li className={active === 3 ? 'active' : ''}>
                    <img src={link} alt="" width="24" height="24" />
                    Link dan Kontak
                  </li>
                </Link>
                <Link
                  to="file"
                  spy
                  smooth
                  offset={-100}
                  duration={500}
                  style={{ textDecoration: 'none' }}
                  onClick={() => {
                    setActive(4);
                  }}
                >
                  <li className={active === 4 ? 'active' : ''}>
                    <img src={fileText} alt="" width="24" height="24" />
                    Kelengkapan Berkas
                  </li>
                </Link>
              </ul>
            </div>
          </Col>
          <Col md={17}>
            <div className="kanan">
              <Form
                onChange={onFormChange}
                formValue={formValue}
                model={model}
                ref={formRef}
                onSubmit={onSubmit}
                fluid
              >
                <Element name="informasi" className="mb-3">
                  <UploadFoto />
                  <FormGroup>
                    <ControlLabel>Nama Perusahaan</ControlLabel>
                    <FormControl
                      name="company_name"
                      type="text"
                      placeholder="nama perusahaan"
                    />
                  </FormGroup>
                </Element>

                <FormGroup>
                  <ControlLabel>Bidang Industri</ControlLabel>
                  <FormControl
                    name="company_industry"
                    type="text"
                    placeholder="bidang industri perusahaan"
                  />
                </FormGroup>

                <FormGroup>
                  <ControlLabel>Jenis Perusahaan</ControlLabel>
                  <FormControl
                    name="company_type"
                    placeholder="Jenis perusahaan"
                    type="text"
                  />
                </FormGroup>

                <FormGroup>
                  <ControlLabel>Deskripsi Perusahaan</ControlLabel>
                  <FormControl
                    name="desc"
                    rows={5}
                    placeholder="Deskripsi Perusahaan"
                    componentClass="textarea"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Jumlah Pegawai</ControlLabel>
                  <FormControl
                    name="total_employee"
                    type="text"
                    placeholder="50 - 100 Pegawai"
                  />
                </FormGroup>
                <Element name="alamat" className="mb-3">
                  <FormGroup>
                    <ControlLabel>Alamat Kantor</ControlLabel>
                    <FormControl
                      name="company_address"
                      placeholder="alamat kantor"
                      rows={3}
                      componentClass="textarea"
                    />
                  </FormGroup>
                </Element>
                <FormGroup>
                  <ControlLabel>Lokasi</ControlLabel>
                  <FormControl
                    name="location"
                    type="text"
                    accepter={AutoComplete}
                    data={location}
                    placeholder="kota, provinsi"
                    autoComplete="off"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Nomor telepon</ControlLabel>
                  <FormControl
                    name="phone"
                    type="text"
                    placeholder="nomor telepon"
                  />
                </FormGroup>
                <Element name="link" className="mb-3">
                  <FormGroup>
                    <ControlLabel>Link linked in</ControlLabel>
                    <FormControl
                      name="linked_in_url"
                      type="text"
                      placeholder="https://www.linkedin.com/company/xxx"
                    />
                  </FormGroup>
                </Element>
                <FormGroup>
                  <ControlLabel>Link instagram</ControlLabel>
                  <FormControl
                    name="instagram_url"
                    type="text"
                    placeholder="https://www.instagram.com/xxx"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Link twitter</ControlLabel>
                  <FormControl
                    name="twitter_url"
                    type="text"
                    placeholder="https://www.twitter.com/xxx"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Link facebook</ControlLabel>
                  <FormControl
                    name="facebook_url"
                    type="text"
                    placeholder="https://www.facebook.com/xxx"
                  />
                </FormGroup>

                <FormGroup>
                  <ControlLabel>Website Perusahaan</ControlLabel>
                  <FormControl
                    name="website_url"
                    type="text"
                    placeholder="website url"
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Nomor Induk Berusaha</ControlLabel>
                  <FormControl
                    name="nib"
                    type="text"
                    placeholder="Nomor Induk Berusaha"
                  />
                </FormGroup>
                <Element name="file">
                  <UploadLegality
                  // legality={legality}
                  // setLegality={setLegality}
                  />
                </Element>

                <FormGroup>
                  <ButtonToolbar
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 20,
                    }}
                  >
                    <Button
                      appearance="default"
                      onClick={() => {
                        setFormValue(initForm);
                      }}
                    >
                      batal
                    </Button>
                    <Button
                      appearance="primary"
                      type="submit"
                      loading={isLoading}
                    >
                      Simpan
                    </Button>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default KelolaProfilePerusahaan;
