import React from 'react';

import kosong from '../../assets/img/kosong.png';

const PencarianKosong = () => {
  return (
    <div style={{ maxWidth: 385, textAlign: 'center', margin: '40px auto' }}>
      <img src={kosong} alt="" />
      <h3 style={{ fontWeight: 800, fontSize: 18 }}>
        Kata kunci yang dicari tidak ada
      </h3>
      <h5 style={{ fontSize: 16, fontWeight: 600 }}>
        Gunakan kata kunci yang lain untuk mendapatkan informasi yang Anda cari
      </h5>
    </div>
  );
};

export default PencarianKosong;
