import React, { useEffect, useState } from 'react';
import { Grid, Row, Col, Alert, Icon } from 'rsuite';
import moment from 'moment';

import { useParams } from 'react-router-dom';
import './pelamar_detail.style.scss';
import CardExp from '../../../components/card-exp/CardExp';
import { apiGetWithAuth } from '../../../misc/config';

const getAge = dateString => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const KelolaPelamarDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    let mounted = true;
    apiGetWithAuth(`users/${id}`)
      .then(r => {
        if (mounted) {
          setProfile(r.data.data);
          setIsLoading(false);
        }
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [id]);

  return (
    <div className="container pelamar-detail">
      {isLoading && 'loading...'}
      {!isLoading && profile && (
        <Grid>
          <Row>
            <Col className="left">
              <div className="profile">
                <div
                  style={{
                    width: 130,
                    height: 130,
                    border: 'none',
                    borderRadius: '50%',
                  }}
                >
                  <img
                    src={profile.job_seeker.profile_picture_url}
                    className="img-wrapper"
                    style={{ borderRadius: '50%' }}
                    width="100%"
                    height="100%"
                    alt=""
                  />
                </div>
                <div className="second">
                  <h6 className="title">
                    {profile.fullname ? profile.fullname.toUpperCase() : ''}
                  </h6>
                  <p>
                    {profile.job_seeker.job
                      ? profile.job_seeker.job.toUpperCase()
                      : 'JOB SEEKER'}
                  </p>
                  <h6>Nomor telepon</h6>
                  <p>{profile.job_seeker.phone}</p>
                  <h6>Email</h6>
                  <p>{profile.email}</p>
                  <h6>Alamat domisili</h6>
                  <p>{profile.job_seeker.city}</p>
                </div>
                <div className="third">
                  <h6>Usia</h6>
                  <p>{getAge(profile.job_seeker.date_of_birth)} tahun</p>
                  <h6>Jenis Kelamin</h6>
                  <p>
                    {profile.job_seeker.is_male ? 'Laki-laki' : 'Perempuan'}
                  </p>
                  <h6>Institusi Pendidikan</h6>
                  <p>{profile.job_seeker.institution}</p>
                </div>
              </div>
              <div className="detail">
                <div className="card-detail">
                  <h2 className="title">Tentang Saya</h2>
                  <p className="aboutme">{profile.job_seeker.about}</p>
                </div>
                <div className="card-detail">
                  <div className="title">Pengalaman Kerja</div>

                  {profile.experiences
                    .filter(obj => {
                      return obj.type === 'Pengalaman Kerja';
                    })
                    .map((dpk, i) => (
                      <CardExp
                        exp={dpk}
                        key={i}
                        editable={false}
                        // updateProfile={updateProfile}
                        one={dpk.title}
                        two={dpk.sub_title}
                        three={`${moment(dpk.date_in).format(
                          'MMMM YYYY'
                        )} - ${moment(dpk.date_out).format('MMMM YYYY')} (${
                          dpk.total_months
                        } bulan)`}
                        data={{
                          title: 'Posisi - Status',
                          sub_title: 'Perusahaan',
                          date_in: 'Tanggal Mulai',
                          date_out: 'Tanggal Berakhir',
                        }}
                        className="mb-4"
                      />
                    ))}
                </div>

                <div className="card-detail">
                  <div className="title">Pendidikan</div>
                  {profile.experiences
                    .filter(obj => {
                      return obj.type === 'Pendidikan';
                    })
                    .map((dpk, i) => (
                      <CardExp
                        key={i}
                        exp={dpk}
                        editable={false}
                        // updateProfile={updateProfile}
                        one={dpk.title}
                        two={dpk.sub_title}
                        three={`${moment(dpk.date_in).format(
                          'MMMM YYYY'
                        )} - ${moment(dpk.date_out).format('MMMM YYYY')} (${(
                          dpk.total_months / 12
                        ).toFixed(1)} tahun)`}
                        data={{
                          title: 'Nama Institusi',
                          sub_title: 'Bidang Studi - gelar',
                          date_in: 'Tanggal Mulai',
                          date_out: 'Tanggal Berakhir',
                        }}
                        className="mb-4"
                      />
                    ))}
                </div>

                <div className="card-detail">
                  <h2 className="title">Skills</h2>
                  {profile.job_seeker.skills
                    ? profile.job_seeker.skills.map((d, i) => (
                        <div className="badge" key={i}>
                          {d}
                        </div>
                      ))
                    : ''}
                </div>
                <div className="card-detail">
                  <h2 className="title">CV dan Portfolio</h2>
                  <div className="cv">
                    <span>CV</span>
                    {profile.job_seeker.cv_url ? (
                      <a
                        href={profile.job_seeker.cv_url}
                        style={{ fontSize: 13 }}
                        target="blank"
                      >
                        <Icon
                          icon="file-text-o"
                          size="lg"
                          style={{ color: 'blue', marginRight: 10 }}
                        />
                        {profile.job_seeker.cv_name}
                      </a>
                    ) : (
                      'Tidak ada'
                    )}
                  </div>

                  <div className="cv">
                    <span>Portfolio</span>
                    {profile.job_seeker.portfolio_url ? (
                      <a
                        href={profile.job_seeker.portfolio_url}
                        target="blank"
                        style={{ fontSize: 13 }}
                      >
                        <Icon
                          icon="file-text-o"
                          size="lg"
                          style={{ color: 'blue', marginRight: 10 }}
                        />
                        {profile.job_seeker.portfolio_name}
                      </a>
                    ) : (
                      'Tidak ada'
                    )}
                  </div>
                </div>
                <div className="card-detail">
                  <h2 className="title">Pengalaman Organsisasi</h2>
                  {profile.experiences
                    .filter(obj => {
                      return obj.type === 'Pengalaman Organisasi';
                    })
                    .map((dpk, i) => (
                      <CardExp
                        key={i}
                        exp={dpk}
                        editable={false}
                        // updateProfile={updateProfile}
                        one={dpk.title}
                        two={dpk.sub_title}
                        three={`${moment(dpk.date_in).format(
                          'MMMM YYYY'
                        )} - ${moment(dpk.date_out).format('MMMM YYYY')} (${
                          dpk.total_months
                        } bulan)`}
                        data={{
                          title: 'Nama Organisasi / Relawan',
                          sub_title: 'Jabatan Organisasi',
                          date_in: 'Tanggal Mulai',
                          date_out: 'Tanggal Berakhir',
                        }}
                        className="mb-4"
                      />
                    ))}
                </div>

                <div className="card-detail">
                  <h2 className="title">Prestasi</h2>
                  {profile.experiences
                    .filter(obj => {
                      return obj.type === 'Prestasi';
                    })
                    .map((dpk, i) => (
                      <CardExp
                        key={i}
                        exp={dpk}
                        editable={false}
                        // updateProfile={updateProfile}
                        one={dpk.title}
                        two={dpk.sub_title}
                        three={`${moment(dpk.date_in).format('YYYY')}`}
                        data={{
                          title: 'Nama Penghargaan',
                          sub_title: 'Penyelenggara',
                          date_in: 'Tahun',
                        }}
                        className="mb-4"
                      />
                    ))}
                </div>
                <div className="card-detail">
                  <h2 className="title">Pengalaman Pelatihan</h2>
                  {profile.experiences
                    .filter(obj => {
                      return obj.type === 'Pengalaman Pelatihan';
                    })
                    .map((dpk, i) => (
                      <CardExp
                        key={i}
                        exp={dpk}
                        editable={false}
                        // updateProfile={updateProfile}
                        one={dpk.title}
                        two={dpk.sub_title}
                        three={`${moment(dpk.date_in).format('YYYY')}`}
                        data={{
                          title: 'Nama Pelatihan',
                          sub_title: 'Penyelenggara',
                          date_in: 'Tahun',
                        }}
                        className="mb-4"
                      />
                    ))}
                </div>
              </div>
            </Col>
            {/* <Col md={8} className="right">
              <div className="status">
                <h2 className="title">Pilih aksi untuk pelamar ini</h2>
                <Button
                  className="red"
                  onClick={async () => {
                    // if (checkedKeys.length === 0) return;
                    // setisTolakLoading(true);
                    // await handleTolak(checkedKeys);
                    // setisTolakLoading(false);
                  }}
                  // loading={isTolakLoading}
                  // disabled={isAccLoading || isTolakLoading}
                >
                  Tolak
                </Button>
                <Button
                  className="green"
                  // onClick={async () => {
                  //   if (checkedKeys.length === 0) return;
                  //   setIsAccLoading(true);
                  //   await handleAcc(checkedKeys);
                  //   setIsAccLoading(false);
                  // }}
                  // loading={isAccLoading}
                  // disabled={isAccLoading || isTolakLoading}
                >
                  Terima
                </Button>
                <h2 className="title">Unduh CV Pelamar</h2>
                <Button
                  className="red"
                  onClick={async () => {
                    // if (checkedKeys.length === 0) return;
                    // setisTolakLoading(true);
                    // await handleTolak(checkedKeys);
                    // setisTolakLoading(false);
                  }}
                  // loading={isTolakLoading}
                  // disabled={isAccLoading || isTolakLoading}
                >
                  Tolak
                </Button>
              </div>
            </Col> */}
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default KelolaPelamarDetail;
