import React from 'react';
import { Button, Modal } from 'rsuite';
import alertImg from '../../assets/img/event.png';

const ModalDaftar = ({ isOpen, close, onClick, isLoading }) => {
  return (
    <Modal
      className="modalalert"
      backdrop="static"
      show={isOpen}
      onHide={close}
      size="xs"
      // full
    >
      <Modal.Body className="body">
        <img src={alertImg} alt="" />
        <h5>Apakah anda yakin ingin mendaftar event ini?</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} appearance="subtle">
          Batal
        </Button>
        <Button
          onClick={onClick}
          appearance="primary"
          className="btn-primary2"
          loading={isLoading}
        >
          Ya
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDaftar;
