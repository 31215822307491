import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  Icon,
  Modal,
  Schema,
} from 'rsuite';
import { apiPostWithAuth } from '../../misc/config';
import { useModalState } from '../../misc/custom-hooks';
import './cardExp.style.scss';
import ModalDelete from './ModalDelete';

const { StringType, DateType } = Schema.Types;

const model = Schema.Model({
  title: StringType().isRequired('required'),
  sub_title: StringType().isRequired('required'),
  date_in: DateType().isRequired('required'),
});

const CardExp = ({
  one,
  two,
  three,
  className,
  exp,
  updateProfile,
  data,
  editable = true,
  ...props
}) => {
  const { isOpen, open, close } = useModalState();
  const del = useModalState();
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(exp);

  const formRef = useRef();
  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    const x = moment(new Date(formValue.date_in)).diff(
      moment(new Date(formValue.date_out)),
      'month',
      true
    );

    try {
      setIsLoading(true);

      const updated = await apiPostWithAuth('users/update-me', {
        experiences: [
          {
            id: exp.id,
            type: formValue.type,
            title: formValue.title,
            sub_title: formValue.sub_title,
            date_in: moment(formValue.date_in).format('YYYY-MM-DD'),
            date_out: formValue.date_out
              ? moment(formValue.date_out).format('YYYY-MM-DD')
              : null,
            total_months: formValue.date_out
              ? Math.abs(Math.round(x)) + 1
              : null,
          },
        ],
      });
      updateProfile({ ...updated.data.data });

      Alert.success('berhasil');

      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={`cardexp ${className}`} {...props}>
        <h3>
          {one}{' '}
          {editable && (
            <>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  open();
                }}
              >
                <Icon
                  icon="edit"
                  style={{ color: 'darkblue', marginRight: 10 }}
                />
              </a>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  del.open();
                }}
              >
                <Icon icon="trash" style={{ color: 'red' }} />
              </a>
            </>
          )}
        </h3>
        <h4>{two}</h4>
        <h5>{three}</h5>
      </div>
      <Modal
        show={isOpen}
        onHide={() => {
          setFormValue(exp);
          close();
        }}
        size="sm"
      >
        <Form
          fluid
          onChange={onFormChange}
          formValue={formValue}
          model={model}
          ref={formRef}
          onSubmit={onSubmit}
          style={{ paddingRight: 10 }}
        >
          <Modal.Header>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel htmlFor="title">{data.title}</ControlLabel>
              <FormControl id="title" name="title" type="text" />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="sub_title">{data.sub_title}</ControlLabel>
              <FormControl id="sub_title" name="sub_title" type="text" />
            </FormGroup>
            <div style={{ display: 'flex' }}>
              <FormGroup style={{ marginRight: 20 }}>
                <ControlLabel htmlFor="date_in">{data.date_in}</ControlLabel>
                <FormControl
                  id="date_in"
                  name="date_in"
                  accepter={DatePicker}
                  format="MMMM-YYYY"
                  placement="topStart"
                />
              </FormGroup>
              {data.date_out && (
                <FormGroup>
                  <ControlLabel htmlFor="date_out">
                    {data.date_out}
                  </ControlLabel>
                  <FormControl
                    id="date_out"
                    name="date_out"
                    accepter={DatePicker}
                    format="MMMM-YYYY"
                    placement="topStart"
                  />
                </FormGroup>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              block
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalDelete del={del} i={exp.id} />
    </>
  );
};

export default CardExp;
