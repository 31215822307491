import React, { useEffect, useState } from 'react';
import { Alert, Col, Grid, Icon, Loader, Row } from 'rsuite';
import ShowMoreText from 'react-show-more-text';
import './lowongan-detail.style.scss';
import { Link, useParams } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import SpanLabelIcon from '../../components/label-with-icon/SpanLabelIcon';
import mapPin from '../../assets/icon/map-pin-dark.svg';
import dollarSign from '../../assets/icon/dollar-sign.svg';
import userCheck from '../../assets/icon/user-check.svg';
import watch from '../../assets/icon/watch.svg';
import briefcase from '../../assets/icon/briefcase.svg';
import clock from '../../assets/icon/clock-gray.svg';
import users from '../../assets/icon/users.svg';
import settings from '../../assets/icon/settings.svg';
import bookOpen from '../../assets/icon/book-open.svg';
import award from '../../assets/icon/award.svg';
import { apiGet } from '../../misc/config';
import Lamar from './Lamar';

const formatter = new Intl.NumberFormat('id-ID', {
  style: 'decimal',
});

const LowonganKerjaDetail = () => {
  const { lowonganId } = useParams();

  const [lowongan, setLowongan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    apiGet(`vacancies/${lowonganId}`)
      .then(r => {
        setLowongan(r.data.data);
        document.title = `${r.data.data.title} | Puskarir UIN Jakarta`;
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [lowonganId]);

  return (
    <div className="container lowongan-detail">
      <Grid>
        {isLoading && (
          <div style={{ textAlign: 'center', lineHeight: '80vh' }} key={0}>
            <Loader content="Loading" speed="slow" size="md" />
          </div>
        )}
        {!isLoading && lowongan && (
          <Row>
            <Col md={16}>
              <div className="main">
                <Link
                  className="img-profile"
                  to={`/perusahaan/${lowongan.user.company.id}`}
                >
                  <img src={lowongan.user.company.profile_picture_url} alt="" />
                </Link>
                <div className="content">
                  <h2 className="title">{lowongan.title}</h2>
                  <p className="subtitle">
                    <Link to={`/perusahaan/${lowongan.user.company.id}`}>
                      <span>{lowongan.user.company.company_name}</span>
                    </Link>
                    <span>
                      <img src={mapPin} alt="" /> {lowongan.city}, Indonesia
                    </span>
                  </p>
                  <div className="list">
                    <SpanLabelIcon own={watch} name={lowongan.vacancy_type} />
                    <SpanLabelIcon
                      own={settings}
                      name={lowongan.job_industry.name}
                    />
                    <SpanLabelIcon
                      own={briefcase}
                      name={lowongan.job_role.name}
                    />
                    <SpanLabelIcon
                      own={userCheck}
                      name={`${lowongan.slot} Pelamar`}
                    />
                    <SpanLabelIcon
                      own={dollarSign}
                      name={`Rp ${formatter.format(
                        lowongan.min_salary
                      )} - ${formatter.format(lowongan.max_salary)}`}
                    />
                    <SpanLabelIcon
                      own={briefcase}
                      name="2 - 3 Tahun Pengalaman"
                    />
                    <SpanLabelIcon
                      own={bookOpen}
                      name={lowongan.latest_education}
                    />
                  </div>
                  <div className="ket-tambahan">
                    <span>
                      <img src={clock} alt="" />
                      Ditutup{' '}
                      {new Date(lowongan.status_end_date).toLocaleDateString()}
                    </span>
                    <span style={{ color: '#EDEEEE' }}>|</span>
                    <span>
                      <img src={users} alt="" />
                      100xx orang melamar lowongan lni
                    </span>
                  </div>
                </div>
              </div>
              <div className="deskripsi">
                <h2>Deskripsi Pekerjaan</h2>
                <p className="deskripsi-detail">{lowongan.desc}</p>
                <h2>Skill Wajib</h2>
                <div className="badges">
                  {lowongan.primary_skills.map((e, i) => {
                    return (
                      <div className="badge" key={i}>
                        {e}
                      </div>
                    );
                  })}
                </div>
                <h2>Kompensasi dan Benefit</h2>
                <div className="keuntungan">
                  {lowongan.benefits.map((d, i) => (
                    <div className="poin" key={i}>
                      <img src={award} width="18px" alt="" />
                      <span>{d}</span>
                    </div>
                  ))}
                </div>
              </div>
              <hr style={{ borderColor: '#737B7D' }} />
              <div className="tentang">
                <h2>Tentang Perusahaan</h2>
                <div className="top">
                  <Link
                    className="img-profile"
                    to={`/perusahaan/${lowongan.user.company.id}`}
                  >
                    <img
                      src={lowongan.user.company.profile_picture_url}
                      alt=""
                    />
                  </Link>
                  <div className="about-title">
                    <Link
                      to={`/perusahaan/${lowongan.user.company.id}`}
                      className="title"
                      style={{ display: 'block' }}
                    >
                      {lowongan.user.company.company_name}
                    </Link>
                    <p className="subtitle">
                      <span>
                        <img
                          src={briefcase}
                          alt=""
                          style={{ marginRight: 5 }}
                        />
                        {lowongan.user.company.company_industry
                          ? lowongan.user.company.company_industry
                          : 'undefined'}
                      </span>
                      <span>
                        <img src={mapPin} alt="" />{' '}
                        {lowongan.user.company.company_city}, Indonesia
                      </span>
                    </p>
                  </div>
                </div>
                <div className="content">
                  <ShowMoreText
                    more="Lihat Lebih Banyak"
                    less="Lihat Lebih Sedikit"
                  >
                    {lowongan.user.company.desc}
                  </ShowMoreText>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <Lamar lowonganId={lowonganId} />
              <div className="share">
                Share{' '}
                <FacebookShareButton url={window.location.href}>
                  <div className="icon-share">
                    <Icon size="2x" icon="facebook" />
                  </div>
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <div className="icon-share">
                    <Icon size="2x" icon="twitter" />
                  </div>
                </TwitterShareButton>
                <a
                  href="/"
                  className="icon-share"
                  onClick={e => {
                    e.preventDefault();
                    navigator.clipboard.writeText(window.location.href);
                    Alert.success('copied');
                  }}
                >
                  <Icon size="2x" icon="link" />
                </a>
              </div>
            </Col>
          </Row>
        )}
      </Grid>
    </div>
  );
};

export default LowonganKerjaDetail;
