import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  TagPicker,
  InputPicker,
  Radio,
  RadioGroup,
  Schema,
  Alert,
  AutoComplete,
} from 'rsuite';
import moment from 'moment';

import { apiGet, apiGetWithURL, apiPutWithAuth } from '../../../misc/config';

const dataW = [
  {
    label: 'Kurang dari setahun',
    value: 'Kurang dari setahun',
  },
  {
    label: '1-3 tahun',
    value: '1-3 tahun',
  },
  {
    label: '3-5 tahun',
    value: '3-5 tahun',
  },
  {
    label: '5-9 tahun',
    value: '5-9 tahun',
  },
  {
    label: 'Lebih dari 10 tahun',
    value: 'Lebih dari 10 tahun',
  },
];

const dataEdu = [
  {
    value: 'SMA Sederajat',
    label: 'SMA Sederajat',
  },
  {
    value: 'S1',
    label: 'S1',
  },
  {
    value: 'S2',
    label: 'S2',
  },
  {
    value: 'S3',
    label: 'S3',
  },
];

const vacType = [
  {
    value: 'Fulltime',
    label: 'Fulltime',
  },
  {
    value: 'Parttime',
    label: 'Parttime',
  },
  {
    value: 'Internship',
    label: 'Internship',
  },
  {
    value: 'Freelance',
    label: 'Freelance',
  },
];

const { StringType, NumberType, DateType, ArrayType } = Schema.Types;

const model = Schema.Model({
  title: StringType().isRequired('required'),
  lokasi: StringType().isRequired('required'),
  vacancy_type: StringType().isRequired('required'),
  job_industry_id: NumberType().isRequired('required'),
  job_role_id: NumberType().isRequired('required'),
  status_end_date: DateType().isRequired('required'),
  slot: NumberType().isRequired('required'),
  work_experience: StringType().isRequired('required'),
  latest_education: StringType().isRequired('required'),
  primary_skills: ArrayType().isRequired('required'),
  benefits: ArrayType().isRequired('required'),
  min_salary: NumberType().isRequired('required'),
  max_salary: NumberType().isRequired('required'),
  is_remote: NumberType().isRequired('required'),
  desc: StringType().isRequired('required'),
  is_active: NumberType().isRequired('required'),
});

const INITIAL_FORM = {};
const UpdateLowongan = ({ setAction, refresh, di }) => {
  const dataSkill = di.primary_skills.map(d => {
    return { label: d, value: d };
  });
  const dataBenefit = di.benefits.map(b => {
    return { label: b, value: b };
  });
  const [formValue, setFormValue] = useState({
    title: di.title,
    vacancy_type: di.vacancy_type,
    job_industry_id: di.job_industry_id,
    job_role_id: di.job_role_id,
    status_end_date: new Date(di.status_end_date),
    primary_skills: di.primary_skills,
    benefits: di.benefits,
    slot: di.slot,
    work_experience: di.work_experience,
    latest_education: di.latest_education,
    lokasi: `${di.city}, ${di.province}`,
    min_salary: di.min_salary,
    max_salary: di.max_salary,
    desc: di.desc,
    is_remote: di.is_remote,
    is_active: di.is_active,
  });

  const [industries, setIndustries] = useState([]);
  const [role, setRole] = useState([]);
  const [loadRole, setLoadRole] = useState(false);
  const [location, setLocation] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);

  const formRef = useRef();
  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  useEffect(() => {
    setRoleLoading(true);
    apiGet('job-industries').then(r => {
      setIndustries(
        r.data.data.data.map(e => {
          return { label: e.name, value: e.id };
        })
      );
    });
    const tempLocation = [];
    apiGetWithURL(
      'https://dev.farizdotid.com/api/daerahindonesia/provinsi'
    ).then(result => {
      const { provinsi } = result.data;

      provinsi.forEach(p => {
        apiGetWithURL(
          `https://dev.farizdotid.com/api/daerahindonesia/kota?id_provinsi=${p.id}`
        ).then(r => {
          r.data.kota_kabupaten.forEach(kota => {
            tempLocation.push(`${kota.nama}, ${p.nama}`);
          });
        });
      });
      setLocation(tempLocation);
    });
    apiGet(`job-roles?job_industry_id=${di.job_industry_id}`)
      .then(r => {
        setRole(
          r.data.data.data.map(m => {
            return { label: m.name, value: m.id };
          })
        );
      })
      .catch(e => {
        Alert.error(e.message);
      });
    setRoleLoading(false);
  }, [di.job_industry_id]);

  const onIndustriChange = async e => {
    setLoadRole(true);
    try {
      const r = await apiGet(`job-roles?job_industry_id=${e}`);
      setRole(
        r.data.data.data.map(m => {
          return { label: m.name, value: m.id };
        })
      );
      setLoadRole(false);
    } catch (err) {
      Alert.error(err.mesage);
      setLoadRole(false);
    }
  };

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    const city = formValue.lokasi.split(',')[0];
    const province = formValue.lokasi.split(',')[1];
    const d = {
      ...formValue,
      city: city ? city.trim() : '',
      province: province ? province.trim() : '',
      country: 'Indonesia',
    };
    delete d.lokasi;
    d.status_end_date = moment(d.status_end_date).format('YYYY-MM-DD');

    try {
      await apiPutWithAuth(`vacancies/${di.id}/update-my-vacancies`, d);
      Alert.success('data berhasil diubah', 4000);
      setAction('get');
      refresh();
      setFormValue(INITIAL_FORM);
      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
      setIsLoading(false);
    }
  };

  return (
    <div className="create-lowongan">
      {roleLoading && <div className="">loading...</div>}

      {!roleLoading && (
        <>
          <h2 className="title">Edit Lowongan</h2>
          <Form
            fluid
            onChange={onFormChange}
            formValue={formValue}
            model={model}
            ref={formRef}
            onSubmit={onSubmit}
          >
            <FormGroup>
              <ControlLabel htmlFor="title">Nama Pekerjaan</ControlLabel>
              <FormControl
                id="title"
                placeholder="Nama Pekerjaan"
                name="title"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="jenis">Jenis Lowongan</ControlLabel>
              <FormControl
                id="jenis"
                block
                placeholder="Pilih Jenis Lowongan"
                name="vacancy_type"
                accepter={InputPicker}
                data={vacType}
                cleanable={false}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="job_industry_id">
                Industri Pekerjaan
              </ControlLabel>
              <FormControl
                block
                placeholder="Pilih Industri Pekerjaan"
                name="job_industry_id"
                accepter={InputPicker}
                data={industries}
                cleanable={false}
                onChange={onIndustriChange}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="job_role_id">Peran Pekerjaan</ControlLabel>
              <FormControl
                block
                loading={loadRole}
                placeholder="Pilih Peran Pekerjaan"
                id="job_role_id"
                name="job_role_id"
                accepter={InputPicker}
                data={role}
                cleanable={false}
                disabled={loadRole}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="status_end_date">
                Tanggal Tutup
              </ControlLabel>
              <FormControl
                block
                id="status_end_date"
                name="status_end_date"
                accepter={DatePicker}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="slot">Batas Pelamar</ControlLabel>
              <FormControl
                id="slot"
                name="slot"
                type="number"
                placeholder="Batas Pelamar"
                max="100000"
                min="0"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Minimal Pengalaman Kerja</ControlLabel>
              <FormControl
                block
                placeholder="Pilih minimal pengalaman kerja"
                name="work_experience"
                accepter={InputPicker}
                data={dataW}
                cleanable={false}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Pendidikan Terakhir</ControlLabel>
              <FormControl
                block
                placeholder="Pilih pendidikan terakhir"
                name="latest_education"
                accepter={InputPicker}
                data={dataEdu}
                cleanable={false}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Skill yang dibutuhkan</ControlLabel>
              <FormControl
                creatable
                name="primary_skills"
                accepter={TagPicker}
                data={dataSkill}
                block
                // disabled={disabled}
                // readOnly={readOnly}
                // plaintext={plaintext}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Benefits</ControlLabel>
              <FormControl
                creatable
                name="benefits"
                accepter={TagPicker}
                data={dataBenefit}
                block
                // disabled={disabled}
                // readOnly={readOnly}
                // plaintext={plaintext}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="min_salary">Minimum Gaji</ControlLabel>
              <InputGroup>
                <InputGroup.Addon>RP </InputGroup.Addon>
                <FormControl
                  id="min_salary"
                  placeholder="masukkan gaji"
                  name="min_salary"
                  min={0}
                  step={100000}
                  type="number"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="max_salary">Maksimum Gaji</ControlLabel>
              <InputGroup>
                <InputGroup.Addon>RP </InputGroup.Addon>
                <FormControl
                  id="max_salary"
                  placeholder="masukkan gaji"
                  step={100000}
                  name="max_salary"
                  type="number"
                  min={0}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="lokasi">Lokasi Lowongan</ControlLabel>
              <InputGroup>
                <FormControl
                  id="lokasi"
                  accepter={AutoComplete}
                  data={location}
                  placeholder="kota, provinsi"
                  name="lokasi"
                  type="text"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Pekerjaan Remote</ControlLabel>
              <FormControl
                name="is_remote"
                accepter={RadioGroup}
                inline
                // disabled={disabled}
                // readOnly={readOnly}
                // plaintext={plaintext}
              >
                <Radio value={0}>Tidak</Radio>
                <Radio value={1}>Ya, Remote</Radio>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Deskripsi</ControlLabel>
              <FormControl rows={5} name="desc" componentClass="textarea" />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Aktif/Non Aktif Publikasi</ControlLabel>
              <FormControl name="is_active" accepter={RadioGroup} inline>
                <Radio value={1}>Aktif</Radio>
                <Radio value={0}>Tidak Aktif</Radio>
              </FormControl>
            </FormGroup>
            <FormGroup>
              <Button
                block
                type="submit"
                loading={isLoading}
                style={{ height: 45, fontSize: 16, fontWeight: 'bold' }}
              >
                {!isLoading && 'Simpan'}
              </Button>
            </FormGroup>
          </Form>
        </>
      )}
    </div>
  );
};

export default UpdateLowongan;
