import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from 'rsuite';

import ButtonEdit from '../../components/button-edit/ButtonEdit';
import edit from '../../assets/icon/edit.svg';
import { useMediaQuery, useModalState } from '../../misc/custom-hooks';
import { apiPostWithAuth } from '../../misc/config';

const EditTentangSaya = ({ profile, updateProfile }) => {
  const { isOpen, open, close } = useModalState();
  const [about, setAbout] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const mobile = useMediaQuery('(max-width: 992px)');

  useEffect(() => {
    if (profile.job_seeker.about) {
      setAbout(profile.job_seeker.about);
    }
  }, [profile.job_seeker.about]);

  const onUploadClick = async () => {
    try {
      setIsLoading(true);

      const updated = await apiPostWithAuth('users/update-me', {
        job_seeker: {
          about,
        },
      });
      updateProfile({ ...updated.data.data });

      Alert.success('berhasil');

      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
    }
  };

  return (
    <>
      <h2 className="title">
        Tentang Saya <ButtonEdit own={edit} text="Edit" onClick={open} />
      </h2>
      <Modal show={isOpen} onHide={close} size={mobile ? 'xs' : 'lg'}>
        <Modal.Header>
          <Modal.Title>Tentang saya</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            fluid
            // onChange={onFormChange}
            // formValue={formValue}
            // model={model}
            // ref={formRef}
            // onSubmit={onSubmit}
            style={{ paddingRight: 10 }}
          >
            <FormGroup>
              <ControlLabel htmlFor="desc">
                Beritahu tentang dirimu sehingga perusahaan lebih mudah
                memahamimu.
              </ControlLabel>
              <FormControl
                id="desc"
                placeholder="Tentang Dirimu"
                name="desc"
                rows={5}
                componentClass="textarea"
                value={about}
                onChange={e => {
                  setAbout(e);
                }}
              />
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            onClick={onUploadClick}
            disabled={isLoading}
            loading={isLoading}
          >
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditTentangSaya;
