import React from 'react';
import { Button, Modal } from 'rsuite';
import alertImg from '../../assets/img/alert-img.png';
import './modalalert.style.scss';

const ModalAlert = ({ isOpen, close, onClick, isLoading }) => {
  return (
    <Modal
      className="modalalert"
      backdrop="static"
      show={isOpen}
      onHide={close}
      size="xs"
    >
      <Modal.Body className="body">
        <img src={alertImg} alt="" />
        <h4>Apakah Anda yakin ingin menghapus lowongan kerja?</h4>
        <p>
          Data yang dihapus tidak dapat dikembalikan. Anda dapat menonaktifkan
          publikasi tanpa harus menghapus lowongan kerja
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close} appearance="subtle">
          Batal
        </Button>
        <Button
          onClick={onClick}
          appearance="primary"
          className="btn-primary2"
          loading={isLoading}
        >
          Ya
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAlert;
