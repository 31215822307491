import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiGetWithAuth } from '../misc/config';

const profileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [alumni, setAlumni] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [token, setToken] = useState({
  //   token: null,
  //   logout: false,
  // });

  const setLocalStorage = (token, user) => {
    localStorage.setItem('token', token);
    setProfile(user);
  };

  const updateProfile = newT => {
    setProfile({ ...profile, ...newT });
  };
  const updateAlumni = a => {
    setAlumni(a);
  };

  const removeToken = () => {
    localStorage.setItem('token', null);
    setProfile(null);
    // setToken({ token: null, logout: true });
  };

  const getme = () => {
    let mounted = true;
    setIsLoading(true);
    apiGetWithAuth('getMe')
      .then(result => {
        if (mounted) {
          if (result.data.data.is_active) {
            setProfile(result.data.data);
          }
          setIsLoading(false);
        }
      })
      .catch(() => {
        setProfile(null);
        setIsLoading(false);
      });
    return () => {
      mounted = false;
    };
  };

  useEffect(getme, []);

  return (
    // jika useContext(profileContext) hasilnya adlaah yg didalam value
    <profileContext.Provider
      value={{
        isLoading,
        profile,
        updateProfile,
        alumni,
        updateAlumni,
        setLocalStorage,
        removeToken,
        getme,
      }}
    >
      {children}
    </profileContext.Provider>
  );
};

export const useProfile = () => useContext(profileContext);
