import React from 'react';
import { Link } from 'react-router-dom';
import './linkAlt.style.scss';

const LinkAlt = ({ text, to, className, ...linkProps }) => {
  return (
    <Link to={to} className={`link-alt ${className}`} {...linkProps}>
      {text}
    </Link>
  );
};

export default LinkAlt;
