import React, { useState } from 'react';
import { Alert } from 'rsuite';
import { useProfile } from '../../../context/profile.context';
import { apiPostWithAuth } from '../../../misc/config';

const UploadBanner = () => {
  const { profile, updateProfile } = useProfile();
  const [isLoading, setIsLoading] = useState(false);

  const onUploadClick = async e => {
    // const canvas = avatarEditorRef.current.getImageScaledToCanvas();
    setIsLoading(true);

    try {
      // const blob = await getBlob(canvas);
      const formData = new FormData();
      formData.append('banner_name', e.target.files[0]);

      const result = await apiPostWithAuth('users/update-me', formData);

      setIsLoading(false);
      updateProfile(result.data.data);
      Alert.info('Avatar has been uploaded', 4000);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
    }
  };

  return (
    <div className="banner">
      <img className="img-cover" src={profile.company.banner_url} alt="" />
      <label htmlFor="avatar-upload" className="btn-upload-banner">
        {isLoading ? 'loading...' : 'Upload Banner'}
        <input
          type="file"
          id="avatar-upload"
          className="d-none"
          accept=".png, .jpg, .jpeg"
          // onChange={onFileInputChange}
          onChange={onUploadClick}
        />
      </label>
    </div>
  );
};

export default UploadBanner;
