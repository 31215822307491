/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Alert } from 'rsuite';
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import './card.style.scss';

import bookmark from '../../assets/icon/bookmark.svg';
import bookmarked from '../../assets/icon/bookmark2.svg';
import dollarSign from '../../assets/icon/dollar-sign.svg';
import userCheck from '../../assets/icon/user-check.svg';
import clock from '../../assets/icon/clock.svg';
import users from '../../assets/icon/users.svg';
import { apiDeleteWithAuth, apiPostWithAuth } from '../../misc/config';
import { useProfile } from '../../context/profile.context';

const Card = ({
  id,
  title,
  company,
  logoImg,
  minSalary,
  maxSalary,
  slot,
  statusEndDate,
  totalApplier,
  tagged,
  handleAddTag,
  handleRemoveTag,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useProfile();

  const handleClickBookmark = async () => {
    if (!profile) return;
    if (profile.role !== 'Pencari Kerja') return;
    try {
      setIsLoading(true);
      if (tagged.includes(id)) {
        await apiDeleteWithAuth('job-tagged/untag', {
          vacancy_ids: [id],
        });
        handleRemoveTag(id);
      } else {
        const r = await apiPostWithAuth('job-tagged/tag', { vacancy_id: id });
        handleAddTag(r.data.data.vacancy_id);
      }
      setIsLoading(false);
      Alert.success('berhasil');
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
    }
  };

  return (
    // <Link to="lowongan-kerja/id" style={{ textDecoration: 'none' }}>
    <div className="card">
      <div className="bookmark">
        <img
          className={isLoading ? 'booking' : ''}
          src={tagged.includes(id) ? bookmarked : bookmark}
          alt=""
          onClick={handleClickBookmark}
        />
      </div>
      <Link
        to={`/lowongan-kerja/${id}`}
        style={{ textDecoration: 'none' }}
        {...props}
      >
        <div className="header-card">
          <img src={logoImg} alt="wkwk" className="img-card" />
          <div className="title">
            <h3>{title}</h3>
            <h4>{company}</h4>
          </div>
        </div>
        <div className="content-card">
          <ul>
            <li>
              <img src={dollarSign} alt="" />{' '}
              <span>
                IDR {minSalary} - {maxSalary}
              </span>
            </li>
            <li>
              <img src={userCheck} alt="" /> <span>Posisi tersedia {slot}</span>
            </li>
            <li>
              <img src={clock} alt="" />{' '}
              <span>Ditutup {moment(statusEndDate).format('DD MMM YYYY')}</span>
            </li>
          </ul>
        </div>
        <hr />
        <div className="footer-card">
          <img src={users} alt="" className="users-svg" />
          <span>{totalApplier || 0} orang melamar lowongan lni</span>
        </div>
      </Link>
    </div>
    // </Link>
  );
};

export default Card;
