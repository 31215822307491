/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Col,
  Grid,
  Progress,
  Row,
  Alert,
  RadioGroup,
  Radio,
  Loader,
  ControlLabel,
  SelectPicker,
} from 'rsuite';
import { Prompt } from 'react-router';
import { useParams, useHistory, Redirect } from 'react-router-dom';

import './survey.style.scss';
import xCircle from '../../assets/icon/x-circle.svg';
import xCircle2 from '../../assets/icon/x-circle2.svg';
import arrowRight from '../../assets/icon/arrow-right.svg';
import arrowLeft from '../../assets/icon/arrow-left.svg';
import clipboard from '../../assets/icon/clipboard.svg';
import { apiGet, apiGetWithURL, apiPost } from '../../misc/config';
import PageProlog from './PageProlog';
import { useProfile } from '../../context/profile.context';

const { Line } = Progress;

const TracerStudySurvey = () => {
  const { alumni } = useProfile(null);

  const [isChanged, setIsChanged] = useState(false);

  const [page, setPage] = useState('prolog');
  const [dataCategory, setDataCategory] = useState(null);
  const [dataSection, setDataSection] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [current, setCurrent] = useState({
    q: 0,
    next: 1,
    prev: null,
    value: '',
    alumni_grids: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [prevLoading, setPrevLoading] = useState(false);

  const history = useHistory();
  const { alias } = useParams();

  const [dataUniv, setDataUniv] = useState([]);
  const [dataFac, setDataFac] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataCity, setDataCity] = useState([]);

  const [bolehKeluar, setBolehKeluar] = useState(false);

  useEffect(() => {
    apiGet('institutions').then(r => {
      setDataUniv(
        r.data.data.data.map(d => {
          return { label: d.name, value: d.name };
        })
      );
    });
    apiGet('studies').then(r => {
      setDataFac(
        r.data.data.data.map(d => {
          return { label: d.name, value: d.name };
        })
      );
    });
    apiGetWithURL(
      'https://jabbar-hafizh.github.io/api-wilayah-indonesia/api/provinces.json'
    ).then(r => {
      setDataProvince(
        r.data.map(d => {
          return { label: d.name, value: d.name };
        })
      );
    });
    apiGetWithURL(
      'https://jabbar-hafizh.github.io/api-wilayah-indonesia/api/regencies.json'
    ).then(r => {
      setDataCity(
        r.data.map(d => {
          return { label: d.name, value: d.name };
        })
      );
    });
    // apiGet('institutions').then(r => {
    //   setDataUniv(
    //     r.data.data.data.map(d => {
    //       return { label: d.name, value: d.name };
    //     })
    //   );
    // });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current.q]);

  useEffect(() => {
    setIsLoading(true);
    let mounted = true;
    apiGet(`questioner-categories/${alias}/alias-url`)
      .then(async r => {
        try {
          if (mounted) {
            setDataCategory(r.data.data);
            const rs = await apiGet(
              `question-sections?questioner_category_id=${r.data.data.id}`
            );
            setDataSection(
              rs.data.data.data.map(d => {
                return { label: d.short_desc, value: d.id };
              })
            );

            const rsd = await apiGet(
              `questions?questioner_category_id=${r.data.data.id}&is_published=1`
            );
            setQuestions(rsd.data.data.data);
            setIsLoading(false);
          }
        } catch (err) {
          Alert.error(err.response ? err.response.data.message : err.name);
        }
      })
      .catch(err => {
        setIsLoading(false);
        Alert.error(err.response ? err.response.data.message : err.name);
      });
    return function cleanup() {
      mounted = false;
    };
  }, [alias, alumni]);

  if (!alumni) {
    return <Redirect to={{ pathname: '/tracer-study', state: { alias } }} />;
  }

  const handleNext = async () => {
    setNextLoading(true);
    try {
      if (isChanged) {
        await apiPost('alumnies-questions', {
          answer: current.value,
          question_id: questions[current.q].id,
          alumni_id: alumni.id,
          alumnies_grids: current.alumni_grids.map(d => {
            return {
              grid_id: d.id,
              alumni_id: alumni.id,
              answer: d.answer,
            };
          }),
        });
      }

      const r = await apiGet(
        `alumnies-questions/${questions[current.next].id}/${
          alumni.id
        }/get-by-question-id-alumni-id`
      );

      const arr = questions[current.next].grids.map(g => {
        const temp = r.data.data
          ? r.data.data.alumnies_grids.find(v => {
              return v.grid_id === g.id;
            })
          : '';

        return { ...g, answer: temp ? temp.answer : '' };
      });

      setCurrent({
        q: current.next,
        next: current.next === questions.length - 1 ? null : current.next + 1,
        prev: current.prev || current.prev === 0 ? current.prev + 1 : 0,
        value: r.data.data ? r.data.data.answer : '',
        alumni_grids: arr,
      });
      setNextLoading(false);
      setIsChanged(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setNextLoading(false);
      setIsChanged(false);
    }
  };

  const handlePrev = async () => {
    try {
      setPrevLoading(true);
      const r = await apiGet(
        `alumnies-questions/${questions[current.prev].id}/${
          alumni.id
        }/get-by-question-id-alumni-id`
      );
      const arr = questions[current.prev].grids.map(g => {
        const temp = r.data.data.alumnies_grids
          ? r.data.data.alumnies_grids.find(v => {
              return v.grid_id === g.id;
            })
          : null;

        return { ...g, answer: temp ? temp.answer : '' };
      });

      setCurrent({
        q: current.prev,
        next: current.next ? current.next - 1 : questions.length - 1,
        prev: current.prev === 0 ? null : current.prev - 1,
        value: r.data.data ? r.data.data.answer : '',
        alumni_grids: arr,
      });
      setPrevLoading(false);
      setIsChanged(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setPrevLoading(false);
      setIsChanged(false);
    }
  };

  const handleIsi = async () => {
    if (questions.length === 0) {
      setPage('logout');
      return;
    }
    setNextLoading(true);
    try {
      const r = await apiGet(
        `alumnies-questions/${questions[0].id}/${alumni.id}/get-by-question-id-alumni-id`
      );

      setPage('isi');
      if (questions.length === 1) {
        setCurrent({
          ...current,
          value: r.data.data ? r.data.data.answer : '',
          next: null,
        });
      } else {
        setCurrent({
          ...current,
          value: r.data.data ? r.data.data.answer : '',
        });
      }
      setNextLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setNextLoading(false);
    }
  };

  const submitToPrologLogout = async () => {
    setNextLoading(true);
    try {
      if (isChanged) {
        await apiPost('alumnies-questions', {
          answer: current.value,
          question_id: questions[current.q].id,
          alumni_id: alumni.id,
        });
      }

      setCurrent({ q: 0, next: 1, prev: null, value: '' });
      setNextLoading(false);
      setPage('logout');
      setBolehKeluar(true);
      Alert.success('data berhasil disimpan', 4000);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setNextLoading(false);
    }
  };

  const handleMultipleClick = d => {
    const abc = current.value.split(';');
    const i = abc.indexOf(d.choice);
    if (i === -1) {
      setCurrent({
        ...current,
        value: abc.concat([d.choice]).join(';'),
      });
    } else {
      abc.splice(abc.indexOf(d.choice), 1);
      setCurrent({
        ...current,
        value: abc.join(';'),
      });
    }
  };

  const handleGridChange = (v, i) => {
    const newArr = [...current.alumni_grids];
    newArr[i].answer = v;

    setCurrent({ ...current, alumni_grids: newArr });
  };

  return (
    <div className="container survey">
      <Prompt
        when={!bolehKeluar}
        message="Kamu Belum menyelesaikan pertanyaan, apakah kamu yakin ingin keluar?"
      />
      {isLoading ? (
        <div style={{ textAlign: 'center', lineHeight: '70vh' }} key={0}>
          <Loader content="Loading" speed="slow" size="md" />
        </div>
      ) : (
        <Grid>
          <Row>
            {page === 'logout' && dataCategory && (
              <PageProlog
                prolog={dataCategory.prolog_logout}
                title={`Terimakasih, ${alumni.nama}`}
                handleClick={() => {
                  history.push('/tracer-study');
                }}
              />
            )}
            {page === 'prolog' && dataCategory && (
              <PageProlog
                desc={dataCategory.desc}
                title={`Selamat Datang, ${alumni.nama}`}
                prolog={dataCategory.prolog_login}
                handleClick={handleIsi}
                nextLoading={nextLoading}
              />
            )}
            {page === 'isi' && (
              <>
                <Col md={6} style={{ marginBottom: 20 }}>
                  <div className="list-pertanyaan">
                    <h3 className="title">Daftar Pertanyaan</h3>
                    <hr />
                    <ul className="cont-list">
                      {dataSection.map(d => {
                        return (
                          <li
                            key={d.value}
                            className={
                              questions[current.q].question_section_id ===
                              d.value
                                ? `active`
                                : ''
                            }
                          >
                            {d.label}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={18}>
                  {!isLoading && questions.length > 0 && (
                    <div className="detail-pertanyaan">
                      <Line
                        percent={((current.q + 1) * 100) / questions.length}
                        showInfo={false}
                        status="active"
                        strokeColor="#3DD0C0"
                      />
                      <h5 className="status-info">
                        {current.q + 1} dari {questions.length} pertanyaan
                      </h5>

                      {questions[current.q].note && (
                        <div className="note">{questions[current.q].note}</div>
                      )}
                      <h4 className="pertanyaan">
                        {questions[current.q].question}
                      </h4>
                      {questions[current.q].answer_types === 'Short Text' && (
                        <Input
                          style={{
                            outline: 0,
                            borderWidth: '0 0 2px',
                            borderColor: '#6fcf97',
                            borderRadius: 0,
                            boxShadow: 'none',
                            background: 'none',
                            fontSize: 18,
                            padding: 5,
                          }}
                          value={current.value}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({ ...current, value: v });
                          }}
                          placeholder="masukkan jawaban"
                        />
                      )}
                      {questions[current.q].answer_types === 'Long Text' && (
                        <Input
                          style={{ fontSize: 18 }}
                          componentClass="textarea"
                          value={current.value}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({ ...current, value: v });
                          }}
                          rows={3}
                          placeholder="masukkan jawaban"
                        />
                      )}
                      {questions[current.q].answer_types === 'Date' && (
                        <Input
                          type="date"
                          value={current.value}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({
                              ...current,
                              value: v,
                            });
                          }}
                          style={{ width: 300 }}
                        />
                      )}
                      {questions[current.q].answer_types === 'Date Time' && (
                        <Input
                          type="datetime-local"
                          value={current.value}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({
                              ...current,
                              value: v,
                            });
                          }}
                          ranges={[
                            {
                              label: 'Now',
                              value: new Date(),
                            },
                          ]}
                        />
                      )}
                      {questions[current.q].answer_types ===
                        'Grid Column Answer' &&
                        current.alumni_grids.map((g, i) => (
                          <div key={g.id} style={{ marginBottom: 40 }}>
                            <ControlLabel className="d-block">
                              {g.question_grid}
                            </ControlLabel>
                            <RadioGroup
                              inline
                              value={g.answer}
                              onChange={v => {
                                setIsChanged(true);
                                handleGridChange(v, i);
                              }}
                            >
                              {questions[current.q].choices.map(d => (
                                <Radio key={d.id} inline value={d.choice}>
                                  {d.choice}
                                </Radio>
                              ))}
                            </RadioGroup>
                          </div>
                        ))}
                      {questions[current.q].answer_types ===
                        'Single Choice' && (
                        <div className="list-jawaban mt-3">
                          {questions[current.q].choices.map(d => (
                            <div
                              key={d.id}
                              className={`jawaban ${
                                d.choice === current.value ? 'active' : ''
                              }`}
                              onClick={() => {
                                setIsChanged(true);
                                setCurrent({ ...current, value: d.choice });
                              }}
                            >
                              <img
                                src={
                                  d.choice === current.value
                                    ? xCircle2
                                    : xCircle
                                }
                                alt=""
                              />
                              <span>{d.choice}</span>
                            </div>
                          ))}
                        </div>
                      )}
                      {questions[current.q].answer_types ===
                        'Multiple Choice' && (
                        <div className="list-jawaban mt-3">
                          {questions[current.q].choices.map(d => (
                            <div
                              key={d.id}
                              className={`jawaban ${
                                current.value.split(';').includes(d.choice)
                                  ? 'active'
                                  : ''
                              }`}
                              onClick={() => {
                                setIsChanged(true);
                                handleMultipleClick(d);
                              }}
                            >
                              <img
                                src={
                                  current.value.split(';').includes(d.choice)
                                    ? xCircle2
                                    : xCircle
                                }
                                alt=""
                              />
                              <span>{d.choice}</span>
                            </div>
                          ))}
                        </div>
                      )}
                      {questions[current.q].answer_types === 'University' && (
                        <SelectPicker
                          value={current.value}
                          cleanable={false}
                          data={dataUniv}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({
                              ...current,
                              value: v,
                            });
                          }}
                        />
                      )}
                      {questions[current.q].answer_types === 'Major' && (
                        <SelectPicker
                          value={current.value}
                          cleanable={false}
                          data={dataFac}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({
                              ...current,
                              value: v,
                            });
                          }}
                        />
                      )}
                      {questions[current.q].answer_types === 'Province' && (
                        <SelectPicker
                          value={current.value}
                          cleanable={false}
                          data={dataProvince}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({
                              ...current,
                              value: v,
                            });
                          }}
                        />
                      )}
                      {questions[current.q].answer_types === 'City' && (
                        <SelectPicker
                          value={current.value}
                          cleanable={false}
                          data={dataCity}
                          onChange={v => {
                            setIsChanged(true);
                            setCurrent({
                              ...current,
                              value: v,
                            });
                          }}
                        />
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {current.prev || current.prev === 0 ? (
                          <Button
                            appearance="primary"
                            className="btn-bwh"
                            onClick={handlePrev}
                            disabled={prevLoading}
                            loading={prevLoading}
                          >
                            <img src={arrowLeft} alt="" /> Sebelumnya
                          </Button>
                        ) : (
                          <Button
                            appearance="primary"
                            className="btn-bwh"
                            onClick={() => {
                              setPage('prolog');
                            }}
                          >
                            <img src={arrowLeft} alt="" /> Sebelumnya
                          </Button>
                        )}
                        {current.next ? (
                          <Button
                            appearance="primary"
                            className="btn-bwh"
                            loading={nextLoading}
                            disabled={nextLoading}
                            onClick={handleNext}
                          >
                            Selanjutnya <img src={arrowRight} alt="" />
                          </Button>
                        ) : (
                          <Button
                            appearance="primary"
                            className="btn-bwh"
                            loading={nextLoading}
                            disabled={nextLoading}
                            onClick={submitToPrologLogout}
                          >
                            Kumpulkan <img src={clipboard} alt="" />
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default TracerStudySurvey;
