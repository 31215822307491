import React from 'react';
import { Button, Divider } from 'rsuite';

const PageProlog = ({ prolog, handleClick, title, nextLoading, desc }) => {
  return (
    <div className="prolog">
      <h1 className="title">{title}</h1>
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: prolog }} />
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: desc }} />
      <Divider />
      <Button
        appearance="primary"
        style={{
          padding: '14px 25px',
          borderRadius: 10,
          marginTop: 40,
          fontWeight: 'bold',
          fontSize: 16,
          color: 'white',
        }}
        onClick={handleClick}
        disabled={nextLoading}
        loading={nextLoading}
      >
        Berikutnya
      </Button>
    </div>
  );
};

export default PageProlog;
