import React, { useCallback, useRef, useState } from 'react';
import {
  Alert,
  ButtonToolbar,
  Button,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Schema,
} from 'rsuite';
import { apiPutWithAuth } from '../../../misc/config';

const { StringType } = Schema.Types;

const model = Schema.Model({
  old_password: StringType().isRequired('password lama harus diiisi'),
  password: StringType()
    .isRequired('Password harus diisi')
    .minLength(8, 'min 8 karakter'),
  password_confirmation: StringType()
    .addRule((val, data) => {
      if (val !== data.password) {
        return false;
      }
      return true;
    }, 'Password tidak sama')
    .isRequired('password konfirmasi harus diiisi'),
});

const KelolaPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [formValue, setFormValue] = useState({
    old_password: '',
    password: '',
    password_confirmation: '',
  });

  const formRef = useRef();

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await apiPutWithAuth('update-my-password', formValue);
      Alert.success(`berhasil Ubah Password`);
      setFormValue({
        old_password: '',
        password: '',
        password_confirmation: '',
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name);
    }
  };

  return (
    <Form
      fluid
      onChange={onFormChange}
      formValue={formValue}
      model={model}
      ref={formRef}
      onSubmit={onSubmit}
    >
      <FormGroup>
        <ControlLabel htmlFor="old_password">Old Password</ControlLabel>
        <FormControl
          id="old_password"
          placeholder="Old Password"
          name="old_password"
          type="password"
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel htmlFor="password">New Password</ControlLabel>
        <FormControl
          placeholder="min. 8 karakter"
          name="password"
          type="password"
          id="password"
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel htmlFor="password">Confirm New Password</ControlLabel>
        <FormControl
          placeholder="min. 8 karakter"
          name="password_confirmation"
          type="password"
          id="password_confirmation"
        />
      </FormGroup>
      <FormGroup>
        <ButtonToolbar
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          <Button
            appearance="primary"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Simpan
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
};

export default KelolaPassword;
