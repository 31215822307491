import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Alert, Col, Grid, Icon, Row } from 'rsuite';
import Headline from '../../components/headline/Headline';
import SkeletonDataArtikel from '../../components/loader-skeleton/SkeletonDataArtikel';
import { apiGet, apiGetWithURL } from '../../misc/config';
import './artikel.style.scss';

const Artikel = () => {
  const [category, setCategory] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [next, setNext] = useState(null);
  const [isNextLoading, setIsNextLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    apiGet('article-categories').then(r => {
      setCategory(r.data.data.data);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    apiGet(`articles${location.search || '?'}&limit=6&is_published=1`)
      .then(r => {
        setData(r.data.data.data);
        setNext(r.data.data.next_page_url);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [location]);

  const handleHref = e => {
    if (isLoading) {
      e.preventDefault();
    }
  };

  const handleNext = async e => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsNextLoading(true);
    try {
      const r = await apiGetWithURL(next);
      setIsNextLoading(false);
      setData([...data, ...r.data.data.data]);
      setNext(r.data.data.next_page_url);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsNextLoading(false);
    }
  };

  return (
    <div className="container artikel">
      <Grid>
        <Headline />
        <Row className="list-artikels">
          <Col md={8}>
            <div className="kategori-filter">
              <h2>Kategori</h2>
              <hr />
              <ul>
                <Link
                  className={location.search === '' ? 'active' : ''}
                  to="?"
                  onClick={handleHref}
                >
                  <li>Semua Kategori</li>
                </Link>
                {category &&
                  category.map(d => (
                    <Link
                      key={d.id}
                      onClick={handleHref}
                      className={
                        location.search === `?article_category_id=${d.id}`
                          ? 'active'
                          : ''
                      }
                      to={`?article_category_id=${d.id}`}
                    >
                      <li>{d.name}</li>
                    </Link>
                  ))}
              </ul>
            </div>
          </Col>
          <Col md={16} className="rightt2">
            {isLoading && <SkeletonDataArtikel />}
            {data && !isLoading && (
              <>
                {data.map(d => (
                  <Link to={`/artikel/${d.alias_url}`} key={d.id}>
                    <Row className="artikel-home">
                      <Col xs={12}>
                        <img className="img-right" src={d.image_url} alt="" />
                      </Col>
                      <Col xs={12} className="right-right">
                        <div className="badge">{d.article_category.name}</div>
                        <div className="artikel-title">{d.title}</div>
                        <div className="timeline">
                          <Icon icon="clock-o" />
                          <span>
                            {moment(new Date(d.created_at)).format(
                              'DD MMMM YYYY hh:mm [WIB]'
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Link>
                ))}
                {isNextLoading ? (
                  <SkeletonDataArtikel />
                ) : (
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    {next ? (
                      <a onClick={handleNext} href="https://arzky.me">
                        Lihat Artikel Lainnya
                      </a>
                    ) : (
                      'tidak ada lagi data'
                    )}
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Artikel;
