import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Col, Icon, Row } from 'rsuite';
import { apiGet } from '../../misc/config';
import SkeletonHideline from '../loader-skeleton/SkeletonHideline';

import './headline.style.scss';

const Headline = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    apiGet('articles?limit=4&is_published=1')
      .then(r => {
        setData(r.data.data.data);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, []);

  return (
    <Row>
      {isLoading && <SkeletonHideline />}
      {!isLoading && data.length > 0 && (
        <>
          <Link to={`/artikel/${data[0].alias_url}`}>
            <Col md={12} className="leftt artikel-home">
              <img src={data[0].image_url} alt="" />

              <div className="badge">{data[0].article_category.name}</div>
              <div className="artikel-title">{data[0].title}</div>

              <div className="timeline">
                <Icon icon="clock-o" />
                <span>
                  {moment(new Date(data[0].created_at)).format(
                    'DD MMMM YYYY hh:mm [WIB]'
                  )}
                </span>
              </div>
            </Col>
          </Link>
          <Col md={12} className="rightt">
            {data.slice(1, 4).map(d => (
              <Link key={d.id} to={`/artikel/${d.alias_url}`}>
                <Row className="artikel-home">
                  <Col sm={12}>
                    <img className="img-right" src={d.image_url} alt="" />
                  </Col>
                  <Col sm={12} className="right-right">
                    <div className="badge">{d.article_category.name}</div>
                    <div className="artikel-title">{d.title}</div>
                    <div className="timeline">
                      <Icon icon="clock-o" />
                      <span>
                        {moment(new Date(d.created_at)).format(
                          'DD MMMM YYYY hh:mm [WIB]'
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Link>
            ))}
          </Col>
        </>
      )}
    </Row>
  );
};

export default Headline;
