import React from 'react';
import './footer.style.scss';

const FooterAlt = () => {
  return (
    <div className="footer">
      &copy; 2021 Pusat Karier UIN Syarif Hidayatullah Jakarta. All Rights
      Reserved
    </div>
  );
};

export default FooterAlt;
