import React, { useEffect, useState } from 'react';
import { Alert, ControlLabel, FormGroup, Icon, Uploader } from 'rsuite';
import { useProfile } from '../../../context/profile.context';
import { apiPostWithAuth } from '../../../misc/config';

const UploadLegality = () => {
  const { profile } = useProfile(null);
  const [isLoading, setIsLoading] = useState(false);
  const [legality, setLegality] = useState({
    url: '',
    name: '',
  });
  const [changeLegality, setChangeLegality] = useState(false);

  useEffect(() => {
    if (!profile.company.legality_name) {
      setChangeLegality(true);
    } else {
      setLegality({
        name: profile.company.legality_name,
        url: profile.company.legality_url,
      });
      setChangeLegality(false);
    }
  }, [profile]);

  const handleSubmit = async e => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('legality_name', e.blobFile);
    try {
      const r = await apiPostWithAuth('users/update-me', formData);
      setIsLoading(false);
      setLegality({
        name: r.data.data.company.legality_name,
        url: r.data.data.company.legality_url,
      });
      setChangeLegality(false);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name);
    }
  };

  return (
    <FormGroup>
      <ControlLabel>Unggah Bukti Legalitas</ControlLabel>
      {legality && !changeLegality && (
        <>
          <a href={legality.url} target="blank">
            {legality.name}
          </a>
          <a
            href="/"
            style={{ color: 'red', marginBottom: 30, display: 'block' }}
            onClick={e => {
              e.preventDefault();
              setChangeLegality(!changeLegality);
            }}
          >
            <Icon icon="trash" style={{ color: 'red', marginRight: 5 }} />
            hapus
          </a>
        </>
      )}

      {changeLegality && (
        <>
          {legality.url && (
            <a
              href="/"
              onClick={e => {
                e.preventDefault();
                setChangeLegality(!changeLegality);
              }}
            >
              Reset
            </a>
          )}
          <Uploader
            action=""
            accept="application/pdf"
            draggable
            multiple={false}
            fileListVisible={false}
            onUpload={handleSubmit}
          >
            <div style={{ lineHeight: '50px' }}>
              {isLoading
                ? 'loading...'
                : 'Click or Drag files to this area to upload'}
            </div>
          </Uploader>
        </>
      )}
    </FormGroup>
  );
};

export default UploadLegality;
