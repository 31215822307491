import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Grid, Row } from 'rsuite';
import SkeletonAbout from '../../components/loader-skeleton/SkeletonAbout';
import { apiGet } from '../../misc/config';

import './tentang.style.scss';

const TentangKami = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    apiGet('about')
      .then(r => {
        setData(r.data.data);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="container tentang-kami">
      <Grid>
        <Row>
          {isLoading && <SkeletonAbout />}
          {!isLoading && data && (
            <div className="content">
              <p className="judul">Tentang Pusat Karir</p>
              <p className="subjudul">UIN Syarif Hidayatullah Jakarta</p>
              <img className="cover" src={data.picture_url} alt="" />
              <div
                className="card-tentang"
                dangerouslySetInnerHTML={{ __html: data.desc }}
              />
              <Link to="/tentang/terms">Terms and Condition Puskarier</Link>
            </div>
          )}
        </Row>
      </Grid>
    </div>
  );
};

export default TentangKami;
