import React, { useCallback, useRef, useState } from 'react';
import {
  Alert,
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Loader,
} from 'rsuite';
import { useProfile } from '../../../context/profile.context';
import { apiPostWithAuth } from '../../../misc/config';

const KelolaEmail = () => {
  const { profile, updateProfile } = useProfile(null);
  const [formValue, setFormValue] = useState({ email: profile.email });
  const [isLoading, setIsLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const formRef = useRef();

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await apiPostWithAuth('users/update-me', {
        email: formValue.email,
      });
      const { data } = result.data;
      Alert.success('berhasil');
      setIsLoading(false);
      updateProfile(data);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response.data.message, 4000);
    }
  };

  return (
    <Form
      fluid
      onChange={onFormChange}
      formValue={formValue}
      ref={formRef}
      onSubmit={onSubmit}
    >
      <FormGroup>
        <ControlLabel>Email</ControlLabel>
        <FormControl
          name="email"
          type="email"
          required
          placeholder="Email Penanggung Jawab"
        />
      </FormGroup>
      {profile.email_verified_at ? (
        <p>Email telah terverifikasi</p>
      ) : (
        <>
          <p>Email anda belum diverifikasi</p>
          <a
            href="/"
            onClick={async e => {
              e.preventDefault();
              setEmailLoading(true);
              try {
                await apiPostWithAuth('email/verification-notification');
                Alert.success('email verifikasi terkirim', 4000);
                setEmailLoading(false);
              } catch (err) {
                Alert.error(
                  err.response ? err.response.data.message : err.name
                );
                setEmailLoading(false);
              }
            }}
          >
            {emailLoading ? (
              <Loader style={{ marginTop: 5 }} content="mengirim..." />
            ) : (
              'verifikasi sekarang'
            )}
          </a>
        </>
      )}
      <FormGroup>
        <ButtonToolbar
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          <Button
            appearance="primary"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Simpan
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
};

export default KelolaEmail;
