import axios from 'axios';

// const API_BASE_URL = 'http://c134-180-252-86-237.ngrok.io/api/v1/';
// const API_BASE_URL = 'http://localhost:8001/api/v1/';
const API_BASE_URL = 'https://api-karir.citsm.id/api/v1/';
// const API_BASE_URL = 'http://103.229.202.23:8030/api/v1/';
// const API_BASE_URL = 'http://localhost:8000/api/v1/';

export async function apiGetWithAuth(queryString) {
  const token = localStorage.getItem('token');
  const configBearer = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.get(
    `${API_BASE_URL}${queryString}`,
    configBearer
  );
  return response;
}
export async function apiPostWithAuth(queryString, dataPost, headers) {
  const token = localStorage.getItem('token');
  const configBearer = {
    headers: { Authorization: `Bearer ${token}`, ...headers },
  };

  const response = await axios.post(
    `${API_BASE_URL}${queryString}`,
    dataPost,
    configBearer
  );
  return response;
}

export async function apiPutWithAuth(queryString, dataPut) {
  const token = localStorage.getItem('token');
  const configBearer = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const response = await axios.put(
    `${API_BASE_URL}${queryString}`,
    dataPut,
    configBearer
  );
  return response;
}

export async function apiDeleteWithAuth(queryString, dataDelete) {
  const token = localStorage.getItem('token');
  const configBearer = {
    headers: { Authorization: `Bearer ${token}` },
    data: dataDelete,
  };

  const response = await axios.delete(
    `${API_BASE_URL}${queryString}`,
    configBearer
  );
  return response;
}

export async function apiGet(queryString) {
  const response = await axios.get(`${API_BASE_URL}${queryString}`);
  return response;
}

export async function apiPost(queryString, dataPost) {
  const response = await axios.post(`${API_BASE_URL}${queryString}`, dataPost);
  return response;
}

export async function apiGetWithURL(queryString) {
  const response = await axios.get(queryString);
  return response;
}

export async function apiGetWithURLAndAuth(queryString) {
  const token = localStorage.getItem('token');
  const configBearer = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await axios.get(queryString, configBearer);
  return response;
}
