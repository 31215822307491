import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  ControlLabel,
  DatePicker,
  Form,
  FormControl,
  FormGroup,
  Modal,
  HelpBlock,
  Loader,
  Alert,
  RadioGroup,
  Radio,
  SelectPicker,
  InputPicker,
} from 'rsuite';
import moment from 'moment';

import edit from '../../assets/icon/edit.svg';
import { useMediaQuery, useModalState } from '../../misc/custom-hooks';

import checkGreen from '../../assets/icon/check-green.svg';
import { apiGet, apiGetWithURL, apiPostWithAuth } from '../../misc/config';

const EditInfoDasar = ({ profile, updateProfile }) => {
  const [formValue, setFormValue] = useState({
    name: profile.fullname,
    job: profile.job_seeker.job ? profile.job_seeker.job : '',
    email: profile.email,
    phone: profile.job_seeker.phone ? profile.job_seeker.phone : '',
    place_of_birth: profile.job_seeker.place_of_birth
      ? profile.job_seeker.place_of_birth
      : '',
    date_of_birth: new Date(profile.job_seeker.date_of_birth),
    city: profile.job_seeker.city ? profile.job_seeker.city : '',
    is_male: profile.job_seeker.is_male ? profile.job_seeker.is_male : 0,
    institution: profile.job_seeker.institution
      ? profile.job_seeker.institution
      : '',
  });

  const [dataCity, setDataCity] = useState([]);
  const [dataUniversity, setDataUniversity] = useState([]);

  useEffect(() => {
    apiGet('institutions').then(r => {
      setDataUniversity(
        r.data.data.data.map(d => {
          return { label: d.name, value: d.name };
        })
      );
    });

    apiGetWithURL(
      'https://jabbar-hafizh.github.io/api-wilayah-indonesia/api/regencies.json'
    ).then(r => {
      setDataCity(
        r.data.map(d => {
          return { label: d.name, value: d.name };
        })
      );
    });
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);

  const mobile = useMediaQuery('(max-width: 992px)');

  const formRef = useRef();
  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);
  const [changeEmail, setChangeEmail] = useState(false);
  const { isOpen, open, close } = useModalState();
  // console.log(profile);

  useEffect(() => {}, [profile]);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const d = {
        fullname: formValue.name,
        job_seeker: {
          phone: formValue.phone,
          job: formValue.job,
          place_of_birth: formValue.place_of_birth,
          date_of_birth: moment(formValue.date_of_birth).format('YYYY-MM-DD'),
          city: formValue.city,
          is_male: formValue.is_male,
          institution: formValue.institution,
        },
      };
      if (changeEmail) {
        d.email = formValue.email;
      }
      const result = await apiPostWithAuth('users/update-me', d);
      // const { data } = result.data;
      updateProfile(result.data.data);
      Alert.success(`berhasil`);
      setIsLoading(false);
      close();
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response ? err.response.data.message : err.name, 4000);
    }
  };

  return (
    <>
      <span
        onClick={open}
        aria-hidden="true"
        style={{ cursor: 'pointer', fontWeight: 700 }}
      >
        <img src={edit} alt="" style={{ marginRight: '10px' }} />
        Edit Info Dasar
      </span>
      <Modal
        show={isOpen}
        onHide={close}
        size={mobile ? 'xs' : 'sm'}
        backdrop="static"
      >
        <Form
          fluid
          onChange={onFormChange}
          formValue={formValue}
          // model={model}
          ref={formRef}
          onSubmit={onSubmit}
          style={{ paddingRight: 10 }}
        >
          <Modal.Header>
            <Modal.Title>Identitas Dasar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel htmlFor="name">Nama Lengkap</ControlLabel>
              <FormControl
                id="name"
                placeholder="Nama Lengkap"
                name="name"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="job">Posisi Pekerjaan</ControlLabel>
              <FormControl
                id="job"
                placeholder="Posisi Pekerjaan"
                name="job"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel htmlFor="email">Email</ControlLabel>
              <FormControl
                id="email"
                placeholder="Email"
                name="email"
                type="email"
                disabled={!changeEmail}
              />
              <HelpBlock>
                <a
                  href="/"
                  onClick={e => {
                    e.preventDefault();
                    setFormValue({ ...formValue, email: profile.email });
                    setChangeEmail(!changeEmail);
                  }}
                >
                  {changeEmail ? 'reset' : 'ganti email?'}
                </a>
              </HelpBlock>
              {!profile.email_verified_at && !changeEmail && (
                <HelpBlock>
                  Email belum terverifikasi
                  <a
                    style={{ display: 'block' }}
                    href="/"
                    onClick={async e => {
                      e.preventDefault();
                      setEmailLoading(true);
                      try {
                        await apiPostWithAuth(
                          'email/verification-notification'
                        );
                        Alert.success('email verifikasi terkirim', 4000);
                        setEmailLoading(false);
                      } catch (err) {
                        Alert.error(
                          err.response ? err.response.data.message : err.name
                        );
                        setEmailLoading(false);
                      }
                    }}
                  >
                    {emailLoading ? (
                      <Loader content="mengirim..." />
                    ) : (
                      'verifikasi sekarang'
                    )}
                  </a>
                </HelpBlock>
              )}
              {profile.email_verified_at && (
                <HelpBlock>
                  <img className="mr-2" src={checkGreen} alt="" />
                  Alamat email telah diverifikasi
                </HelpBlock>
              )}
            </FormGroup>

            <FormGroup>
              <ControlLabel htmlFor="phone">Nomor Telepon</ControlLabel>
              <FormControl
                id="phone"
                placeholder="Nomor telepon"
                name="phone"
                type="text"
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel htmlFor="place_of_birth">Tempat Lahir</ControlLabel>
              <FormControl
                id="place_of_birth"
                placeholder="Tempat Lahir"
                name="place_of_birth"
                type="text"
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel htmlFor="date_of_birth">Tanggal Lahir</ControlLabel>
              <FormControl
                block
                id="date_of_birth"
                name="date_of_birth"
                accepter={DatePicker}
                placement="auto"
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel htmlFor="city">Kota Domisili</ControlLabel>
              <FormControl
                accepter={SelectPicker}
                data={dataCity}
                id="city"
                placeholder="Kota"
                name="city"
                block
              />
            </FormGroup>

            <FormGroup>
              <ControlLabel>Jenis Kelamin</ControlLabel>
              <FormControl name="is_male" accepter={RadioGroup}>
                <Radio value={1}>Laki-laki</Radio>
                <Radio value={0}>Perempuan</Radio>
              </FormControl>
            </FormGroup>

            <FormGroup>
              <ControlLabel htmlFor="institution">Institusi</ControlLabel>
              <FormControl
                id="institution"
                creatable
                accepter={InputPicker}
                data={dataUniversity}
                placeholder="cari atau tambah baru"
                name="institution"
                block
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              block
              type="submit"
              // onClick={onUploadClick}
              // disabled={isLoading}
              loading={isLoading}
              disabled={isLoading}
            >
              Simpan
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditInfoDasar;
