/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ContentLoader from 'react-content-loader';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Grid, Row } from 'rsuite';
import moment from 'moment';

import './eventku.style.scss';
import clock from '../../assets/icon/clock.svg';
import checkSquare from '../../assets/icon/check-square.svg';
import xCircle from '../../assets/icon/x-circle3.svg';
import list from '../../assets/icon/list.svg';
import CardEventku from '../../components/card-eventku/CardEventku';
import { apiGetWithAuth } from '../../misc/config';
import { useMediaQuery } from '../../misc/custom-hooks';
import PencarianKosong from './PencarianKosong';

const MyLoader = props => {
  const mobile = useMediaQuery('(max-width : 992px)');

  return (
    <ContentLoader
      height={mobile ? 600 : 1200}
      width="100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="103" y="12" rx="3" ry="3" width="123" height="7" />
      <rect x="102" y="152" rx="3" ry="3" width="171" height="6" />
      <circle cx="44" cy="42" r="38" />
      <circle cx="44" cy="147" r="38" />
      <circle cx="44" cy="251" r="38" />
      <rect x="105" y="117" rx="3" ry="3" width="123" height="7" />
      <rect x="104" y="222" rx="3" ry="3" width="123" height="7" />
      <rect x="105" y="48" rx="3" ry="3" width="171" height="6" />
      <rect x="104" y="257" rx="3" ry="3" width="171" height="6" />
    </ContentLoader>
  );
};
const Eventku = () => {
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [status, setStatus] = useState('All');

  useEffect(() => {
    setStatus('All');
    setIsLoading(true);
    apiGetWithAuth('users-events/my-events')
      .then(r => {
        setData(r.data.data.data);
        setDataFilter(r.data.data.data);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [mounted]);

  const onClickFilter = s => {
    if (isLoading) return;
    setStatus(s);
    if (s === 'All') {
      setMounted(!mounted);
      return;
    }
    if (s === 'Mendatang') {
      setDataFilter(
        data.filter(
          f =>
            moment(new Date()).isSameOrBefore(f.event.date) &&
            !f.event.is_cancel
        )
      );
    }
    if (s === 'Selesai') {
      setDataFilter(data.filter(f => moment(new Date()).isAfter(f.event.date)));
    }
    if (s === 'Dibatalkan') {
      setDataFilter(data.filter(f => f.event.is_cancel));
    }
  };

  return (
    <div className="container eventku">
      <Grid>
        <Row>
          <h2 className="text-center" style={{ marginBottom: 30 }}>
            Eventku
          </h2>
        </Row>
        <Row>
          <Col md={6}>
            <div className="filter-side">
              <ul>
                <li
                  className={status === 'All' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('All');
                  }}
                >
                  <img src={list} alt="" width="24" height="24" />
                  All
                </li>
                <li
                  className={status === 'Mendatang' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Mendatang');
                  }}
                >
                  <img src={clock} alt="" width="24" height="24" />
                  Mendatang
                </li>
                <li
                  className={status === 'Selesai' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Selesai');
                  }}
                >
                  <img src={checkSquare} alt="" width="24" height="24" />
                  Selesai
                </li>
                <li
                  className={status === 'Dibatalkan' ? 'active' : ''}
                  onClick={() => {
                    onClickFilter('Dibatalkan');
                  }}
                >
                  <img src={xCircle} alt="" width="24" height="24" />
                  Dibatalkan
                </li>
              </ul>
            </div>
          </Col>
          <Col md={18} className="kanan">
            {isLoading && <MyLoader />}

            {!isLoading && dataFilter.length > 0 && (
              <div className="list-eventku">
                {dataFilter.map(d => (
                  <CardEventku
                    data={d}
                    key={d.id}
                    onClickFilter={onClickFilter}
                  />
                ))}
              </div>
            )}
            {!isLoading && dataFilter.length === 0 && <PencarianKosong />}
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Eventku;
