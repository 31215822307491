import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Carousel,
  Col,
  Grid,
  Icon,
  Input,
  InputGroup,
  Panel,
  Row,
} from 'rsuite';
import { Link, useHistory } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';

import Card from '../../components/card/Card';
import CardEvent from '../../components/card/CardEvent';
import './home.style.scss';
import { useProfile } from '../../context/profile.context';
import { useMediaQuery } from '../../misc/custom-hooks';
import { apiGet, apiGetWithAuth } from '../../misc/config';

import faqImage from '../../assets/img/FAQ.svg';
import Headline from '../../components/headline/Headline';
import SkeletonCardLowongan from '../../components/loader-skeleton/SkeletonCardLowongan';
import jumbotron from '../../assets/img/Jumbotron.png';

const Home = () => {
  const mobile = useMediaQuery('(max-width : 992px)');
  const [lowongan, setLowongan] = useState([]);
  const [faq, setFaq] = useState([]);
  const [events, setEvents] = useState([]);
  const [banner, setBanner] = useState([]);

  const [querySearch, setQuerySearch] = useState({ title: '', city: '' });

  const [tagged, setTagged] = useState([]);

  const { profile } = useProfile(null);

  const history = useHistory();

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: 'ease-out',
    });

    if (profile) {
      if (profile.role === 'Pencari Kerja') {
        apiGetWithAuth('job-tagged/my-job-tagged')
          .then(r => {
            setTagged(r.data.data.data.map(d => d.vacancy_id));
          })
          .catch(err => {
            Alert.error(err.response ? err.response.data.message : err.name);
          });
      }
    }

    apiGet('vacancies?is_active=1&limit=6')
      .then(r => {
        setLowongan(r.data.data.data);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
      });

    // faq
    apiGet('faqs')
      .then(r => {
        setFaq(r.data.data.data);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
      });

    apiGet('events?limit=6')
      .then(r => {
        setEvents(r.data.data.data);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
      });

    apiGet('ss')
      .then(r => {
        setBanner(r.data.data.data);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
      });
  }, [profile]);

  const onSearch = () => {
    history.push('/lowongan-kerja', querySearch);
  };

  const handleAddTag = d => {
    setTagged([...tagged, d]);
  };
  const handleRemoveTag = d => {
    setTagged(tagged.filter(f => f !== d));
  };

  return (
    <div className="container home">
      <Grid>
        <Row>
          <Col>
            <Carousel shape="bar" autoplay>
              {banner.length ? (
                banner.map(d => <img key={d.id} src={d.ss_url} alt="img" />)
              ) : (
                <img src={jumbotron} alt="img" />
              )}
            </Carousel>
          </Col>
          <Col>
            <h2 className="title text-center">
              Cari Pekerjaan
              <br /> Impian Kamu Sekarang!{' '}
            </h2>
            <h3 className="subtitle">
              Temukan pekerjaan impian kamu sekarang! dengan fitur unggulan
              Sistem Informasi Pusar Karier.
              <br />
              Cari informasi pekerjaan kamu dibawah ini!
            </h3>
          </Col>
          <Col>
            {!mobile && (
              <div className="search-input">
                <Input
                  className="posisi"
                  placeholder="Posisi Lowongan/Perusahaan"
                  value={querySearch.title}
                  onChange={e => {
                    setQuerySearch({ ...querySearch, title: e });
                  }}
                  onPressEnter={onSearch}
                />
                <Input
                  className="daerah"
                  placeholder="Daerah/Kota"
                  value={querySearch.city}
                  onChange={e => {
                    setQuerySearch({ ...querySearch, city: e });
                  }}
                  onPressEnter={onSearch}
                />
                <Button className="btn-cari" onClick={onSearch}>
                  Cari
                </Button>
              </div>
            )}
            {mobile && (
              <InputGroup className="mb-3" inside>
                <Input
                  placeholder="Search"
                  value={querySearch.title}
                  onChange={e => {
                    setQuerySearch({ ...querySearch, title: e });
                  }}
                  onPressEnter={onSearch}
                />
                <InputGroup.Button onClick={onSearch}>
                  <Icon icon="search" />
                </InputGroup.Button>
              </InputGroup>
            )}
          </Col>

          <Col data-aos="fade-up" className="lowongan-terbaru">
            <h4 className="lowongan-terbaru-title">Lowongan Terbaru</h4>
            <div className="cards-container">
              {lowongan.length === 0 && (
                <>
                  <SkeletonCardLowongan />
                  <SkeletonCardLowongan />
                  <SkeletonCardLowongan />
                  <SkeletonCardLowongan />
                  <SkeletonCardLowongan />
                  <SkeletonCardLowongan />
                </>
              )}

              {lowongan &&
                lowongan.map(d => (
                  <Card
                    key={d.id}
                    tagged={tagged}
                    handleAddTag={handleAddTag}
                    handleRemoveTag={handleRemoveTag}
                    id={d.id}
                    title={d.title}
                    logoImg={d.user.company.profile_picture_url}
                    minSalary={d.min_salary}
                    maxSalary={d.max_salary}
                    slot={d.slot}
                    company={d.user.company.company_name}
                    statusEndDate={d.status_end_date}
                    totalApplier={d.total_applier}
                  />
                ))}
            </div>
          </Col>

          <Col data-aos="fade-up" className="artikel-terbaru">
            <h4>Artikel Terbaru</h4>
            <Headline />
          </Col>

          <div data-aos="fade-up" className="event">
            <h4>Event</h4>
            <div className="cards-event-container">
              {events && events.map(d => <CardEvent key={d.id} data={d} />)}
            </div>
          </div>

          <div data-aos="fade-up" className={`${profile ? 'd-none' : 'papan'}`}>
            <div className="kiri">
              <h2>Siap untuk menentukan karir?</h2>
              <p>Mulai daftar sekarang!</p>
            </div>

            <Button componentClass={Link} to="/signup" className="btn-papan">
              Registrasi
            </Button>
          </div>

          <div data-aos="fade-up" className="faq">
            <h4>Frequently Asked Question</h4>
            <Row>
              <Col md={12}>
                {faq
                  ? faq.map(f => (
                      <Panel key={f.id} header={f.question} collapsible>
                        <p>{f.answer}</p>
                      </Panel>
                    ))
                  : null}
              </Col>
              <Col md={12} smHidden xsHidden>
                <img className="img-faq" src={faqImage} alt="" />
              </Col>
            </Row>
          </div>
        </Row>
      </Grid>
    </div>
  );
};

export default Home;
