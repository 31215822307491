import React, { useState, useEffect } from 'react';
import {
  Alert,
  AutoComplete,
  Checkbox,
  CheckboxGroup,
  Col,
  Grid,
  InputGroup,
  InputNumber,
  Loader,
  Panel,
  RangeSlider,
  Row,
} from 'rsuite';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import ContentLoader from 'react-content-loader';
import { apiGet, apiGetWithAuth, apiGetWithURL } from '../../misc/config';
import Card from '../../components/card/Card';
import './lowongan.style.scss';
import Search from './Search';
import Filter from './Filter';
import { useMediaQuery } from '../../misc/custom-hooks';
import PencarianKosong from './PencarianKosong';
import { useProfile } from '../../context/profile.context';

const INIT_FILTER = {
  salary: [0, 50000000],
  latest_education: [],
  vacancy_type: [],
  work_experience: [],
  is_remote: [],
  title_company: '',
  city: '',
};

const MyLoader = props => (
  <ContentLoader
    speed={2}
    width={300}
    height={300}
    viewBox="0 0 400 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="100" y="8" rx="3" ry="3" width="200" height="16" />
    <rect x="100" y="45" rx="3" ry="3" width="300" height="12" />
    <rect x="0" y="100" rx="3" ry="3" width="410" height="10" />
    <rect x="0" y="140" rx="3" ry="3" width="380" height="10" />
    <rect x="0" y="180" rx="3" ry="3" width="178" height="10" />
    <circle cx="40" cy="40" r="40" />
  </ContentLoader>
);

const LowonganKerja = () => {
  const loc = useLocation();

  const [formFilter, setFormFilter] = useState({
    ...INIT_FILTER,
    title_company: loc.state ? loc.state.title : '',
    city: loc.state ? loc.state.city : '',
  });
  const [formAtas, setFormAtas] = useState({
    title_company: loc.state ? loc.state.title : '',
    city: loc.state ? loc.state.city : '',
  });

  const { profile } = useProfile(null);

  const miniLaptop = useMediaQuery('(max-width : 1200px)');

  const [loadMore, setLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [location, setLocation] = useState([]);

  const [data, setData] = useState([]);
  const [resp, setResp] = useState({});
  const [tagged, setTagged] = useState([]);

  const fetchData = async () => {
    // 20 more records in 1.5 secs
    try {
      if (resp.next_page_url) {
        const r = await apiGetWithURL(resp.next_page_url);
        setData([...data, ...r.data.data.data]);
        setResp(r.data.data);
      } else {
        setLoadMore(false);
      }
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
    }
  };

  const searchHandler = () => {
    setFormFilter({
      ...formFilter,
      title_company: formAtas.title_company,
      city: formAtas.city,
    });
  };

  useEffect(() => {
    const tempLocation = [];
    // if (loc.state) {
    //   setFormFilter({ ...formFilter, title_company: loc.state.test });
    // }
    apiGetWithURL(
      'https://dev.farizdotid.com/api/daerahindonesia/provinsi'
    ).then(result => {
      const { provinsi } = result.data;

      provinsi.forEach(p => {
        apiGetWithURL(
          `https://dev.farizdotid.com/api/daerahindonesia/kota?id_provinsi=${p.id}`
        ).then(r => {
          r.data.kota_kabupaten.forEach(kota => {
            tempLocation.push(`${kota.nama}, ${p.nama}`);
          });
        });
      });
      setLocation(tempLocation);
    });
  }, []);

  useEffect(() => {
    if (profile) {
      if (profile.role === 'Pencari Kerja') {
        apiGetWithAuth('job-tagged/my-job-tagged')
          .then(r => {
            setTagged(r.data.data.data.map(d => d.vacancy_id));
          })
          .catch(err => {
            Alert.error(err.response ? err.response.data.message : err.name);
          });
      }
    }
    const to = setTimeout(() => {
      setIsLoading(true);
      let str = '';
      str += `min_salary=${formFilter.salary[0]}&max_salary=${formFilter.salary[1]}`;
      if (formFilter.latest_education.length > 0) {
        str += `&latest_education=${formFilter.latest_education.join()}`;
      }

      if (formFilter.vacancy_type.length > 0) {
        str += `&vacancy_type=${formFilter.vacancy_type.join()}`;
      }

      if (formFilter.work_experience.length > 0) {
        str += `&work_experience=${formFilter.work_experience.join()}`;
      }
      str += `&title_company=${formFilter.title_company}`;
      str += `&city=${formFilter.city.split(',')[0].trim()}`;
      if (formFilter.is_remote.length > 0) {
        str += `&is_remote=${formFilter.is_remote.join()}`;
      }
      str += '&limit=10';

      apiGet(`vacancies?is_open=1&is_active=1&${str}`)
        .then(r => {
          setData(r.data.data.data);
          setResp(r.data.data);
          if (r.data.data.next_page_url) {
            setLoadMore(true);
          }
          setIsLoading(false);
        })
        .catch(err => {
          Alert.error(err.response ? err.response.data.message : err.name);
          setIsLoading(false);
        });
    }, 500);

    return () => clearTimeout(to);
  }, [formFilter, profile]);

  const handleAddTag = d => {
    setTagged([...tagged, d]);
  };
  const handleRemoveTag = d => {
    setTagged(tagged.filter(f => f !== d));
  };

  return (
    <div className="container lowongan">
      <Grid>
        <Row>
          <Col>
            <Search
              formAtas={formAtas}
              setFormAtas={setFormAtas}
              searchHandler={searchHandler}
              location={location}
            />
            <Filter
              formFilter={formFilter}
              setFormFilter={setFormFilter}
              location={location}
            />

            <div className="content">
              <Row>
                <Col
                  lg={8}
                  mdHidden
                  xsHidden
                  smHidden
                  style={{ height: '100%', position: 'sticky', top: 100 }}
                >
                  <div className="filter">
                    <h5>Filter Pencarian</h5>
                    <hr />
                    <div className="filter-field">
                      <Panel header="Pendidikan Terakhir" collapsible>
                        <CheckboxGroup
                          value={formFilter.latest_education}
                          name="latest_education"
                          onChange={val => {
                            setFormFilter({
                              ...formFilter,
                              latest_education: val,
                            });
                          }}
                        >
                          <Checkbox value="SMA Sederajat">
                            {' '}
                            SMA Sederajat
                          </Checkbox>
                          <Checkbox value="S1"> S1</Checkbox>
                          <Checkbox value="S2"> S2</Checkbox>
                          <Checkbox value="S3"> S3</Checkbox>
                          <Checkbox value="Lainnya"> Lainnya</Checkbox>
                        </CheckboxGroup>
                      </Panel>
                      <Panel header="Gaji" collapsible>
                        <RangeSlider
                          min={0}
                          max={50000000}
                          step={100000}
                          progress
                          style={{ marginTop: 16, marginBottom: 16 }}
                          value={[
                            parseInt(formFilter.salary[0], 10),
                            parseInt(formFilter.salary[1], 10),
                          ]}
                          onChange={v => {
                            setFormFilter({ ...formFilter, salary: v });
                            // onChange({ min_salary: v[0], max_salary: v[1] });
                          }}
                        />
                        <InputGroup style={{ height: '38px', padding: 0 }}>
                          <InputGroup.Addon>Rp</InputGroup.Addon>
                          <InputNumber
                            step={100000}
                            min={0}
                            max={50000000}
                            value={formFilter.salary[0]}
                            onChange={nextValue => {
                              // eslint-disable-next-line no-unused-vars
                              const [start, end] = formFilter.salary;
                              if (nextValue > end) {
                                return;
                              }
                              setFormFilter({
                                ...formFilter,
                                salary: [nextValue, end],
                              });
                            }}
                          />
                          <InputGroup.Addon>Rp</InputGroup.Addon>
                          <InputNumber
                            min={0}
                            max={50000000}
                            value={formFilter.salary[1]}
                            step={100000}
                            onChange={nextValue => {
                              // eslint-disable-next-line no-unused-vars
                              const [start, end] = formFilter.salary;
                              if (start > nextValue) {
                                return;
                              }
                              setFormFilter({
                                ...formFilter,
                                salary: [start, nextValue],
                              });
                              // onChange({
                              //   min_salary: start,
                              //   max_salary: nextValue,
                              // });
                            }}
                          />
                        </InputGroup>
                      </Panel>
                      <Panel header="Tipe Pekerjaan" collapsible>
                        <CheckboxGroup
                          value={formFilter.vacancy_type}
                          name="vacancy_type"
                          onChange={val => {
                            setFormFilter({
                              ...formFilter,
                              vacancy_type: val,
                            });
                          }}
                        >
                          <Checkbox value="Internship"> Internship</Checkbox>
                          <Checkbox value="Parttime"> Part-time</Checkbox>
                          <Checkbox value="Fulltime"> Full-time</Checkbox>
                          <Checkbox value="Freelance"> Freelance</Checkbox>
                        </CheckboxGroup>
                      </Panel>
                      <Panel header="Pengalaman Kerja" collapsible>
                        <CheckboxGroup
                          value={formFilter.work_experience}
                          name="work_experience"
                          onChange={val => {
                            setFormFilter({
                              ...formFilter,
                              work_experience: val,
                            });
                          }}
                        >
                          <Checkbox value="Kurang dari setahun">
                            {' '}
                            Kurang dari setahun
                          </Checkbox>
                          <Checkbox value="1-3 tahun"> 1-3 tahun</Checkbox>
                          <Checkbox value="3-5 tahun"> 3-5 tahun</Checkbox>
                          <Checkbox value="5-9 tahun"> 5-9 tahun</Checkbox>
                          <Checkbox value="Lebih dari 10 tahun">
                            {' '}
                            Lebih dari 10 tahun
                          </Checkbox>
                        </CheckboxGroup>
                      </Panel>

                      <Panel header="Kota Perusahaan" collapsible>
                        <AutoComplete
                          className="daerah"
                          placeholder="Daerah/Kota"
                          data={location}
                          value={formFilter.city}
                          onChange={val => {
                            setFormFilter({
                              ...formFilter,
                              city: val,
                            });
                          }}
                        />
                      </Panel>

                      <Panel header="Pekerjaan Remote" collapsible>
                        <CheckboxGroup
                          value={formFilter.is_remote}
                          name="is_remote"
                          onChange={val => {
                            setFormFilter({
                              ...formFilter,
                              is_remote: val,
                            });
                          }}
                        >
                          <Checkbox value="1"> Ya, Remote</Checkbox>
                          <Checkbox value="0"> Tidak</Checkbox>
                        </CheckboxGroup>
                      </Panel>
                    </div>
                  </div>
                </Col>
                <Col
                  lg={16}
                  style={!miniLaptop ? { paddingLeft: '20px' } : null}
                >
                  {!isLoading && (
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={fetchData}
                      hasMore={loadMore}
                      loader={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            flexWrap: 'wrap',
                          }}
                          key={0}
                        >
                          <MyLoader />
                          <MyLoader />
                        </div>
                      }
                    >
                      <div className="cards">
                        {data.map(d => (
                          <Card
                            tagged={tagged}
                            handleAddTag={handleAddTag}
                            handleRemoveTag={handleRemoveTag}
                            key={d.id}
                            id={d.id}
                            title={d.title}
                            logoImg={d.user.company.profile_picture_url}
                            minSalary={d.min_salary}
                            maxSalary={d.max_salary}
                            slot={d.slot}
                            statusEndDate={d.status_end_date}
                            company={d.user.company.company_name}
                            totalApplier={d.total_applier}
                          />
                        ))}
                      </div>
                    </InfiniteScroll>
                  )}
                  {isLoading && (
                    <div
                      style={{ textAlign: 'center', lineHeight: '50vh' }}
                      key={0}
                    >
                      <Loader content="Loading" speed="slow" size="md" />
                    </div>
                  )}
                  {data.length > 0 && !loadMore && !isLoading && (
                    <div style={{ textAlign: 'center' }}>
                      there is no more data
                    </div>
                  )}

                  {data.length === 0 && !isLoading && <PencarianKosong />}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default LowonganKerja;
