import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Alert, Col, Grid, Icon, Loader, Row } from 'rsuite';
import LabelWithIcon from '../../components/label-with-icon/LabelWithIcon';
import { apiGet } from '../../misc/config';
import './perusahaan.style.scss';

const PerusahaanDetail = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    apiGet(`companies/${id}`)
      .then(r => {
        if (mounted) {
          setData(r.data.data);
          setIsLoading(false);
        }
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
    return () => {
      mounted = false;
    };
  }, [id]);

  return (
    <div className="container perusahaan-detail">
      {isLoading && (
        <div style={{ textAlign: 'center', lineHeight: '50vh' }} key={0}>
          <Loader content="Loading" speed="slow" size="md" />
        </div>
      )}
      {!isLoading && data && (
        <Grid>
          <Row className="header">
            <Col>
              <div
                className="img-wrapper"
                style={{
                  background: `url(${data.banner_url}) center`,
                  backgroundSize: 'cover',
                }}
              />
              <div className="share">
                <a href={data.facebook_url} className="share-icon">
                  <Icon icon="facebook" size="2x" />
                </a>
                <a href={data.twitter_url} className="share-icon">
                  <Icon icon="twitter" size="2x" />
                </a>
                <a href={data.instagram_url} className="share-icon">
                  <Icon icon="instagram" size="2x" />
                </a>
                <a href={data.linked_in_url} className="share-icon">
                  <Icon icon="linkedin" size="2x" />
                </a>
              </div>
              <div className="header-title">
                <img
                  src={data.profile_picture_url}
                  alt=""
                  className="img-logo"
                />
                <div className="kanan">
                  <h2>{data.company_name}</h2>
                  <ul>
                    <li>
                      <Icon icon="map-marker" /> {data.company_city},{' '}
                      {data.company_country || 'Indonesia'}
                    </li>
                    <li>
                      <Icon icon="globe2" /> {data.website_url}
                    </li>
                    <li>
                      <Icon
                        icon="volume-control-phone"
                        style={{ transform: 'rotate(-40deg)' }}
                      />{' '}
                      {data.phone}
                    </li>
                    <li>
                      <Icon icon="gear2" /> {data.company_industry}
                    </li>
                    <li>
                      <Icon icon="suitcase" /> {data.company_type}
                    </li>
                    <li>
                      <Icon icon="peoples-map" /> {data.total_employee}
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="tentang">
            <Col>
              <h2 className="title">Tentang Perusahaan</h2>
              <p>{data.desc}</p>
            </Col>
          </Row>
          <Row className="list-pekerjaan">
            <Col>
              <h2 className="title">Pekerjaan</h2>
              <hr />
              <ul className="cards-lowonganku">
                {data.user.vacancies &&
                  data.user.vacancies.map(d => (
                    <Link
                      key={d.id}
                      style={{ textDecoration: 'none' }}
                      to={`/lowongan-kerja/${d.id}`}
                    >
                      <li>
                        <h3 className="subtitle">{d.title}</h3>
                        <LabelWithIcon
                          link="/assets/img/dollar-sign.svg"
                          name={`IDR ${d.min_salary} - ${d.max_salary}`}
                          className="mb-2"
                        />
                        <LabelWithIcon
                          link="/assets/img/user-check.svg"
                          name={`Batas ${d.slot} Pelamar`}
                          className="mb-2"
                        />
                        <LabelWithIcon
                          link="/assets/img/clock.svg"
                          name={`Ditutup ${moment(d.status_end_date).format(
                            'DD/MM/YYYY'
                          )}`}
                          className="mb-2"
                        />
                        <div
                          className={`badge-jadwal ${
                            moment() > moment(d.status_end_date) ? 'tutup' : ''
                          }`}
                        >
                          <LabelWithIcon
                            link="/assets/img/clock.svg"
                            name={`Ditutup ${moment(d.status_end_date).format(
                              'DD/MM/YYYY'
                            )}`}
                          />
                        </div>
                        <hr />
                      </li>
                    </Link>
                  ))}
              </ul>
            </Col>
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default PerusahaanDetail;
