import React, { useState } from 'react';
import { Alert, Button, HelpBlock, Input, Modal } from 'rsuite';
import { apiPost } from '../../misc/config';
import { useModalState } from '../../misc/custom-hooks';

const ForgotPassword = () => {
  const { isOpen, open, close } = useModalState();

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await apiPost('forgot-password', { email: value });
      Alert.success('email reset password berhasil terkirim, cek email anda');
      setIsLoading(false);
    } catch (err) {
      Alert.error(err.response ? err.response.data.message : err.name);
      setIsLoading(false);
    }
  };

  return (
    <>
      <button type="button" className="fw-600 btn-transparan" onClick={open}>
        Forgot your password ?
      </button>

      <Modal className="modal-forgot" show={isOpen} onHide={close}>
        <Modal.Header>
          <Modal.Title>Lupa Kata Sandi?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            name="email"
            placeholder="Masukkan alamat email"
            value={value}
            onChange={v => setValue(v)}
            onPressEnter={onSubmit}
          />
          <HelpBlock>
            Masukkan alamat emailmu dan kami akan mengirimkan tautan untuk
            mereset kata sandi.
          </HelpBlock>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block
            appearance="primary"
            onClick={onSubmit}
            disabled={isLoading}
            loading={isLoading}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ForgotPassword;
