import React from 'react';
import {
  AutoComplete,
  Button,
  Checkbox,
  CheckboxGroup,
  Drawer,
  Icon,
  Input,
  InputGroup,
  InputNumber,
  Panel,
  RangeSlider,
} from 'rsuite';
import { useMediaQuery, useModalState } from '../../misc/custom-hooks';

const Filter = ({ formFilter, setFormFilter, location }) => {
  const { isOpen, open, close } = useModalState();
  const miniLaptop = useMediaQuery('(max-width : 1200px)');
  return (
    <>
      {miniLaptop && (
        <>
          <InputGroup inside onClick={open} className="mt-3">
            <Input placeholder="Filter" disabled />
            <InputGroup.Addon>
              <Icon icon="filter" />
            </InputGroup.Addon>
          </InputGroup>
          <Drawer full style={{ width: '100%' }} show={isOpen} onHide={close}>
            <Drawer.Header>
              <Drawer.Title>
                <h5>Filter Pencarian</h5>
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <div className="filter">
                <div className="filter-field">
                  <Panel header="Pendidikan Terakhir" collapsible>
                    <CheckboxGroup
                      value={formFilter.latest_education}
                      name="latest_education"
                      onChange={val => {
                        setFormFilter({
                          ...formFilter,
                          latest_education: val,
                        });
                      }}
                    >
                      <Checkbox value="SMA Sederajat"> SMA Sederajat</Checkbox>
                      <Checkbox value="S1"> S1</Checkbox>
                      <Checkbox value="S2"> S2</Checkbox>
                      <Checkbox value="S3"> S3</Checkbox>
                      <Checkbox value="Lainnya"> Lainnya</Checkbox>
                    </CheckboxGroup>
                  </Panel>
                  <Panel header="Gaji" collapsible>
                    <RangeSlider
                      min={0}
                      max={50000000}
                      step={100000}
                      progress
                      style={{ marginTop: 16, marginBottom: 16 }}
                      value={[
                        parseInt(formFilter.salary[0], 10),
                        parseInt(formFilter.salary[1], 10),
                      ]}
                      onChange={v => {
                        setFormFilter({ ...formFilter, salary: v });
                        // onChange({ min_salary: v[0], max_salary: v[1] });
                      }}
                    />
                    <InputGroup style={{ height: '38px', padding: 0 }}>
                      <InputGroup.Addon>Rp</InputGroup.Addon>
                      <InputNumber
                        step={100000}
                        min={0}
                        max={50000000}
                        value={formFilter.salary[0]}
                        onChange={nextValue => {
                          // eslint-disable-next-line no-unused-vars
                          const [start, end] = formFilter.salary;
                          if (nextValue > end) {
                            return;
                          }
                          setFormFilter({
                            ...formFilter,
                            salary: [nextValue, end],
                          });
                        }}
                      />
                      <InputGroup.Addon>Rp</InputGroup.Addon>
                      <InputNumber
                        min={0}
                        max={50000000}
                        value={formFilter.salary[1]}
                        step={100000}
                        onChange={nextValue => {
                          // eslint-disable-next-line no-unused-vars
                          const [start, end] = formFilter.salary;
                          if (start > nextValue) {
                            return;
                          }
                          setFormFilter({
                            ...formFilter,
                            salary: [start, nextValue],
                          });
                          // onChange({
                          //   min_salary: start,
                          //   max_salary: nextValue,
                          // });
                        }}
                      />
                    </InputGroup>
                  </Panel>
                  <Panel header="Tipe Pekerjaan" collapsible>
                    <CheckboxGroup
                      value={formFilter.vacancy_type}
                      name="vacancy_type"
                      onChange={val => {
                        setFormFilter({
                          ...formFilter,
                          vacancy_type: val,
                        });
                      }}
                    >
                      <Checkbox value="Internship"> Internship</Checkbox>
                      <Checkbox value="Parttime"> Part-time</Checkbox>
                      <Checkbox value="Fulltime"> Full-time</Checkbox>
                      <Checkbox value="Freelance"> Freelance</Checkbox>
                    </CheckboxGroup>
                  </Panel>
                  <Panel header="Pengalaman Kerja" collapsible>
                    <CheckboxGroup
                      value={formFilter.work_experience}
                      name="work_experience"
                      onChange={val => {
                        setFormFilter({
                          ...formFilter,
                          work_experience: val,
                        });
                      }}
                    >
                      <Checkbox value="Kurang dari setahun">
                        {' '}
                        Kurang dari setahun
                      </Checkbox>
                      <Checkbox value="1-3 tahun"> 1-3 tahun</Checkbox>
                      <Checkbox value="3-5 tahun"> 3-5 tahun</Checkbox>
                      <Checkbox value="5-9 tahun"> 5-9 tahun</Checkbox>
                      <Checkbox value="Lebih dari 10 tahun">
                        {' '}
                        Lebih dari 10 tahun
                      </Checkbox>
                    </CheckboxGroup>
                  </Panel>

                  <Panel header="Kota Perusahaan" collapsible>
                    <AutoComplete
                      className="daerah"
                      placeholder="Daerah/Kota"
                      data={location}
                      value={formFilter.city}
                      onChange={val => {
                        setFormFilter({
                          ...formFilter,
                          city: val,
                        });
                      }}
                    />
                  </Panel>

                  <Panel header="Pekerjaan Remote" collapsible>
                    <CheckboxGroup
                      value={formFilter.is_remote}
                      name="is_remote"
                      onChange={val => {
                        setFormFilter({
                          ...formFilter,
                          is_remote: val,
                        });
                      }}
                    >
                      <Checkbox value="1"> Ya, Remote</Checkbox>
                      <Checkbox value="0"> Tidak</Checkbox>
                    </CheckboxGroup>
                  </Panel>
                </div>
                <Button block className="btn-cari" onClick={close}>
                  Cari
                </Button>
              </div>
            </Drawer.Body>
          </Drawer>
        </>
      )}
    </>
  );
};

export default Filter;
