import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonCardLowongan = props => {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={300}
      viewBox="0 0 400 300"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="100" y="8" rx="3" ry="3" width="200" height="16" />
      <rect x="100" y="45" rx="3" ry="3" width="300" height="12" />
      <rect x="0" y="100" rx="3" ry="3" width="410" height="10" />
      <rect x="0" y="140" rx="3" ry="3" width="380" height="10" />
      <rect x="0" y="180" rx="3" ry="3" width="178" height="10" />
      <circle cx="40" cy="40" r="40" />
    </ContentLoader>
  );
};

export default SkeletonCardLowongan;
