import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  ButtonToolbar,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Schema,
} from 'rsuite';
import { useProfile } from '../../../context/profile.context';
import { apiPostWithAuth } from '../../../misc/config';

const { StringType } = Schema.Types;

const model = Schema.Model({
  fullname: StringType().isRequired('nama harus diiisi'),
  pic_address: StringType().isRequired('Password harus diisi'),
  pic_phone: StringType().isRequired('Password harus diisi'),
});

const InformasiDasar = () => {
  const formRef = useRef();
  const { profile, updateProfile } = useProfile(null);
  const [formValue, setFormValue] = useState({
    fullname: '',
    pic_address: '',
    pic_phone: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const onFormChange = useCallback(value => {
    setFormValue(value);
  }, []);

  const onSubmit = async () => {
    if (!formRef.current.check() || isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const result = await apiPostWithAuth('users/update-me', {
        fullname: formValue.fullname,
        company: {
          pic_phone: formValue.pic_phone,
          pic_address: formValue.pic_address,
        },
      });
      const { data } = result.data;
      Alert.success('berhasil');
      setIsLoading(false);
      updateProfile(data);
    } catch (err) {
      setIsLoading(false);
      Alert.error(err.response.data.message, 4000);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setFormValue({
      fullname: profile.fullname,
      pic_address: profile.company.pic_address,
      pic_phone: profile.company.pic_phone,
    });
    setIsLoading(false);
  }, [profile]);

  return (
    <Form
      fluid
      ref={formRef}
      onChange={onFormChange}
      formValue={formValue}
      model={model}
      onSubmit={onSubmit}
    >
      <FormGroup>
        <ControlLabel>Nama</ControlLabel>
        <FormControl
          name="fullname"
          type="text"
          placeholder="Nama Penanggung Jawab"
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Alamat</ControlLabel>
        <FormControl
          name="pic_address"
          placeholder="alamat"
          rows={2}
          componentClass="textarea"
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Nomor Telepon</ControlLabel>
        <FormControl name="pic_phone" type="text" placeholder="nomor telepon" />
      </FormGroup>
      <FormGroup>
        <ButtonToolbar
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          <Button
            appearance="default"
            onClick={() => {
              // setFormValue(initForm);
            }}
          >
            batal
          </Button>
          <Button
            appearance="primary"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          >
            Simpan
          </Button>
        </ButtonToolbar>
      </FormGroup>
    </Form>
  );
};

export default InformasiDasar;
