import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumb, Col, Grid, Icon, Loader, Row } from 'rsuite';
import moment from 'moment';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import './artikelDetail.style.scss';
import { Link, useParams } from 'react-router-dom';
import { apiGet } from '../../misc/config';

const ArtikelDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [lainnya, setLainnya] = useState([]);

  const { alias } = useParams();

  useEffect(() => {
    setIsLoading(true);
    apiGet(`articles/${alias}/alias-url`)
      .then(r => {
        setData(r.data.data);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });

    apiGet('articles?limit=3')
      .then(r => {
        setLainnya(r.data.data.data);
        setIsLoading(false);
      })
      .catch(err => {
        Alert.error(err.response ? err.response.data.message : err.name);
        setIsLoading(false);
      });
  }, [alias]);

  return (
    <div className="container detail-artikel">
      {isLoading && (
        <div style={{ textAlign: 'center', lineHeight: '80vh' }} key={0}>
          <Loader content="Loading" speed="slow" size="md" />
        </div>
      )}
      {!isLoading && data && (
        <Grid>
          <Row className="main">
            <Col>
              <img src={data.image_url} alt="" className="img-cover-artikel" />
              <Breadcrumb separator=">" className="breads">
                <Breadcrumb.Item componentClass={Link} to="/artikel">
                  Artikel
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {data.article_category.name}
                </Breadcrumb.Item>
              </Breadcrumb>
              <h2 className="title-artikel">{data.title}</h2>
              <p className="subtitle">
                {data.article_category.name} <br />
                {moment(data.created_at).format(
                  'dddd, DD MMM YYYY HH:mm [WIB]'
                )}
              </p>
              <hr />
              <div className="content">
                <div>
                  <span style={{ fontWeight: 'bold' }}>{data.opener_text}</span>
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: data.desc }} />
                </div>
              </div>
              <div className="share">
                Share{' '}
                <FacebookShareButton url={window.location.href}>
                  <div className="icon-share">
                    <Icon size="2x" icon="facebook" />
                  </div>
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <div className="icon-share">
                    <Icon size="2x" icon="twitter" />
                  </div>
                </TwitterShareButton>
                <a
                  href="/"
                  className="icon-share"
                  onClick={e => {
                    e.preventDefault();
                    navigator.clipboard.writeText(window.location.href);
                    Alert.success('copied');
                  }}
                >
                  <Icon size="2x" icon="link" />
                </a>
              </div>
            </Col>
          </Row>
          <Row className="artikel-lainnya">
            <Col>
              <h2 className="title">Artikel Lainnya</h2>
              <Row>
                {lainnya &&
                  lainnya.map((d, i) => (
                    <Link key={i} to={`/artikel/${d.alias_url}`}>
                      <Col sm={8}>
                        <img height={200} src={d.image_url} alt="" />
                        <p className="subtitle">{d.title}</p>
                      </Col>
                    </Link>
                  ))}
              </Row>
            </Col>
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default ArtikelDetail;
